import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ArrowLeft } from 'lucide-react'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import './Index.css'
import Modal from '../../../SharedComponents/Modal/Modal'
import useBackendService from '../../../../../config/services/backend-service'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'

const roles = [
  { value: 'administrator', label: 'Administrator' },
  { value: 'approver', label: 'Approver' }
]

function AddNewUsers () {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    reset
  } = useForm({ mode: 'onChange' })

  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })
  const { mutate: createUser, isLoading } = useBackendService('/users', 'post', {
    onSuccess: () => {
      setModal({
        title: 'User added',
        desc: 'User can input their details to sign into their account',
        type: 'success',
        link: '/admin-users',
        visible: true
      })
      setModalOpen(true)
      reset()
    },
    onError: error => {
      console.error('Failed to create user:', error)
      setModal({
        type: 'error',
        title: 'Submission failed',
        desc: 'You are unable to create a user',
        visible: true
      })
      setModalOpen(true)
    }
  })

  const onSubmit = data => {
    const userData = {
      isAdmin: true,
      plan: 'Admin',
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      role: data.roleInput,
      password: data.password
    }
    createUser(userData)
  }

  return (
    <section className='newUserCtn'>
      <div className='mb-[15px] lg:mb-[30px]'>
        <a href='/admin-users'>
          <ArrowLeft size={24} />
        </a>
      </div>
      <div className='space-y-0 lg:space-y-2'>
        <h1 className='lg:text-3xl text-xl text-black'>Add new user</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>Enter user details</p>
      </div>
      <form className='newuserform' onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full lg:w-[70%] flex items-center flex-col lg:flex-row gap-5 lg:gap-[60px]'>
          <TextInput
            name='firstName'
            register={register}
            errors={errors}
            text='First Name'
          />
          <TextInput
            name='lastName'
            register={register}
            errors={errors}
            text='Last Name'
          />
        </div>
        <div className='w-full lg:w-[70%] flex items-center flex-col lg:flex-row gap-5 lg:gap-[60px]'>
          <TextInput
            name='email'
            register={register}
            errors={errors}
            text='Email'
          />
          <TextInput
            name='phoneNumber'
            register={register}
            errors={errors}
            text='Phone Number'
          />
        </div>
        <div className='w-full lg:w-[70%] flex items-center flex-col lg:flex-row gap-5 lg:gap-[60px]'>
          <SelectInput
            control={control}
            Controller={Controller}
            data={roles}
            title='Role'
            name='roleInput'
            placeholder='Assign role'
          />
          <TextInput
            name='password'
            register={register}
            errors={errors}
            text='Password'
          />
        </div>

        <div className='w-full lg:w-[408px]'>
          <GlobalButton
            title='Create new user'
            isLoading={isLoading}
            isDirty={isDirty}
            isValid={isValid}
          />
        </div>
      </form>

      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </section>
  )
}

export default AddNewUsers
