import { createSlice } from '@reduxjs/toolkit'

const stepsSlice = createSlice({
  name: 'signUp',
  initialState: {
    currentStep: 0,
    isFirstNavigation: true,
    selectedAnswer: '',
    enterpriseSelectedAnswer: '',
    basicUser: {
      cardNumber: '',
      email: '',
      firstName: '',
      isAdmin: false,
      lastName: '',
      location: '',
      password: '',
      phoneNumber: '',
      plan: '',
      role: 'administrator'
    },
    proUser: {
      cardNumber: '',
      email: '',
      firstName: '',
      isAdmin: false,
      lastName: '',
      location: '',
      password: '',
      phoneNumber: '',
      plan: '',
      role: 'administrator'
    },
    enterpriseUser: {
      email: '',
      phoneNumber: ''
    },
    businessDetails: {
      businesstype: '',
      venture: '',
      locationchoice: '',
      budget: ''
    },
    farmDetails: {
      farmname: '',
      year: '',
      location: '',
      staffs: 0,
      branch: 0
    },
    ceoDetails: {
      ceoname: '',
      ceoemail: '',
      cacnumber: '',
      ceobusinesstype: ''
    },
    landDetails: {
      question: '',
      landsize: '',
      agricresources: '',
      resourceslist: '',
      resourcesquestion: '',
      info: '',
      information: ''
    }
  },
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setIsFirstNavigation: (state, action) => {
      state.isFirstNavigation = action.payload
    },
    setSelectedAnswer: (state, action) => {
      state.selectedAnswer = action.payload
    },
    setEnterpriseSelectedAnswer: (state, action) => {
      state.enterpriseSelectedAnswer = action.payload
    },
    setUser: (state, action) => {
      state.enterpriseUser = { ...state.enterpriseUser, ...action.payload }
    },
    setBasicUser: (state, action) => {
      state.basicUser = { ...state.basicUser, ...action.payload }
    },
    setProUser: (state, action) => {
      state.proUser = { ...state.proUser, ...action.payload }
    },
    setFarmDetails: (state, action) => {
      state.farmDetails = action.payload
    },
    setCeoDetails: (state, action) => {
      state.ceoDetails = action.payload
    },
    setBusinessDetails: (state, action) => {
      state.businessDetails = { ...state.businessDetails, ...action.payload }
    },
    setLandDetails: (state, action) => {
      state.landDetails = { ...state.landDetails, ...action.payload }
    },
    // Clear functions
    clearCurrentStep: (state) => {
      state.currentStep = 0
    },
    clearIsFirstNavigation: (state) => {
      state.isFirstNavigation = true
    },
    clearSelectedAnswer: (state) => {
      state.selectedAnswer = ''
    },
    clearEnterpriseSelectedAnswer: (state) => {
      state.enterpriseSelectedAnswer = ''
    },
    clearUser: (state) => {
      state.enterpriseUser = {
        email: '',
        phoneNumber: ''
      }
    },
    clearFarmDetails: (state) => {
      state.farmDetails = {
        farmname: '',
        year: '',
        location: '',
        staffs: 0,
        branch: 0
      }
    },
    clearCeoDetails: (state) => {
      state.ceoDetails = {
        ceoname: '',
        ceoemail: '',
        cacnumber: '',
        ceobusinesstype: ''
      }
    },
    clearBusinessDetails: (state) => {
      state.businessDetails = {
        businesstype: '',
        venture: '',
        locationchoice: '',
        budget: ''
      }
    },
    clearLandDetails: (state) => {
      state.landDetails = {
        question: '',
        landsize: '',
        agricresources: '',
        resourceslist: '',
        resourcesquestion: '',
        info: '',
        information: ''
      }
    },
    resetBasicUser: (state) => {
      state.basicUser = {
        cardNumber: '',
        email: '',
        firstName: '',
        isAdmin: false,
        lastName: '',
        location: '',
        password: '',
        phoneNumber: '',
        plan: '',
        role: 'administrator'
      }
    },
    resetProUser: (state) => {
      state.proUser = {
        cardNumber: '',
        email: '',
        firstName: '',
        isAdmin: false,
        lastName: '',
        location: '',
        password: '',
        phoneNumber: '',
        plan: '',
        role: 'administrator'
      }
    },
    clearAllState: (state) => {
      Object.assign(state, stepsSlice.getInitialState())
    }
  }
})

export const {
  setCurrentStep,
  setFarmDetails,
  setIsFirstNavigation,
  setSelectedAnswer,
  setBusinessDetails,
  setEnterpriseSelectedAnswer,
  setCeoDetails,
  setLandDetails,
  setBasicUser,
  setProUser,
  setUser,
  clearCurrentStep,
  clearIsFirstNavigation,
  clearSelectedAnswer,
  clearEnterpriseSelectedAnswer,
  clearUser,
  clearFarmDetails,
  clearCeoDetails,
  clearBusinessDetails,
  clearLandDetails,
  resetBasicUser,
  resetProUser,
  clearAllState
} = stepsSlice.actions

export default stepsSlice.reducer
