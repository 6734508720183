import React from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { clearAllState } from '../store/reducers/signUpReducer'
const ProtectedRoute = ({ isAuthenticated }) => {
  const location = useLocation()
  const dispatch = useDispatch()

  if (!isAuthenticated) {
    const previousPage = location.pathname
    return <Navigate to='/' state={{ from: previousPage }} />
  }
  dispatch(clearAllState())

  return <Outlet />
}

export default ProtectedRoute
