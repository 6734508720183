import React, { useState } from 'react'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import { useForm } from 'react-hook-form'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import { toast } from 'react-toastify'
import useBackendService from '../../../../../config/services/backend-service'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'
import { useAuth } from '../../../../../config/contexts/authProvider'
import Modal from '../../../SharedComponents/Modal/Modal'

const ProfileHelpCenter = () => {
  const { user } = useAuth()
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false
  })
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm({
    defaultValues: {
      name: user?.firstName,
      email: user?.email
    }
  })
  const { mutate: helpCenter, isLoading } = useBackendService(
    '/auth/send_message',
    'post',
    {
      onSuccess: data => {
        setModal({
          type: 'success',
          title: 'Message successfully sent',
          desc: 'Well done , your message was successfully submitted',
          visible: true
        })
        setModalOpen(true)
      },
      onError: err => {
        setModal({
          type: 'error',
          title: 'Unable to send a message',
          desc: 'You are unable to send a message',
          visible: true

        })
        setModalOpen(true)
      }
    }
  )

  const onSubmit = async data => {
    if (!data.subject || !data.message) {
      toast.error('All fields are required!')
      return
    }

    helpCenter(data)
  }

  return (
    <div className='border border-gray-300 rounded-md w-full h-full'>
      <h1 className='font-medium p-3 text-[14px] lg:text-[16px]'>Get in touch with us</h1>
      <hr />
      <form
        className='lg:w-[80%] p-3 space-y-3'
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextInput
          text='Subject'
          name='subject'
          register={register}
          errors={errors}
          placeholder='Input here'
        />
        <div className='w-full'>
          <TextArea
            name='message'
            register={register}
            errors={errors}
            text='Message'
            placeholder='Input here'
          />
        </div>

        <div className='w-full mt-[15%]'>
          <GlobalButton
            isDirty={isDirty}
            isValid={isValid}
            isLoading={isLoading}
            title='Submit message'
          />
        </div>

        <h1 className='text-[14px] lg:text-[16px]'>Connect with us:</h1>
        <div className='w-full flex justify-between items-start ts'>
          <h1 className=' font-[500] text-black'>Address:</h1>
          <h1 className='text-[#666666] w-[70%] text-end'>Unit 2 Wetland estate phase 3, Aguwan Anaekwe street, Wuye, Abuja, Nigeria</h1>
        </div>
        <div className='w-full flex justify-between items-center ts'>
          <h1 className=' font-[500] text-black'>Phone:</h1>
          <h1 className='text-[#666666]'>+234 812 3456 789</h1>
        </div>
        <div className='w-full flex justify-between items-center ts'>
          <h1 className=' font-[500] text-black'>Email:</h1>
          <h1 className='text-[#666666]'>support@tgaims.com

          </h1>
        </div>
      </form>
      {/* Conditionally render the modal */}
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default ProfileHelpCenter
