import React from 'react'

const ComprehensiveToolSection = () => {
  const ComprehensiveToolSectionData = [
    {
      title: 'Real-time data monitoring',
      description: 'Track your animal and crop health, soil moisture, and weather conditions with precision sensors and satellite imageries.',
      image: require('../../../assets/catalogimg.png')
    },
    {
      title: 'Efficient resources management',
      description: 'Optimize use of inputs like water, fertilizers and pesticides with precise tool.',
      image: require('../../../assets/stock.png')
    },
    {
      title: 'Customizable dashboard',
      description: 'Tailor dashboard  to display relevant data for operations. Use intuitive controls to visualize agri business metrics.',
      image: require('../../../assets/overview.png')
    },
    {
      title: 'Investment platform management',
      description: 'Plan, manage and track your agricultural investment portfolio and multiply your margins using our robust Agri investment management tool.',
      image: require('../../../assets/investimg.png')
    }
  ]
  return (
    <section className=' px-[5%] py-10 lg:py-[64px] gap-10 flex flex-col h-full w-full'>
      <div className='w-full'>
        <p className='text-primary font-[600] lg:w-[50%] w-[90%]  text-[20px]  lg:text-[30px]  text-start '>
          Comprehensive tools designed to streamline your operations
        </p>
        <p className='font-[400] text-[#666666] text-[13px] my-2 lg:text-[16px] w-[90%] lg:w-[60%] lg:my-5 text-start leading-[20px] lg:leading-[24px]'>
          Whether you're overseeing agriculture investment portfolios, businesses
          (large, medium or small scale entreprise), our features ensure you
          have everything you need to succeed
        </p>
      </div>

      <div className='w-full flex flex-wrap flex-col lg:flex-row justify-between items-center gap-4'>
        {/*
   cards */}
        {ComprehensiveToolSectionData.map((item, index) => (
          <div className='w-full lg:w-[49%] rounded-lg flex flex-col justify-start items-center' key={index}>
            {/* top container */}
            <div className='w-full bg-gray-50 px-[5%] pt-[5%] rounded-t-lg'>
              <img src={item.image} alt='cards' className='w-full h-full ' />
            </div>
            {/* bottom container */}
            <div className='w-full border-l border-r border-b border-[#EEEEEE] rounded-b-lg  space-y-1 h-[100px] px-[5%] py-[3%]'>
              <h1 className='font-[500] text-[14px] lg:text-[18px]'>{item.title}</h1>
              <p className='text-[#666666] font-[400] text-[13px] lg:text-[14px]'>{item.description}</p>
            </div>

          </div>
        ))}

      </div>

    </section>
  )
}

export default ComprehensiveToolSection
