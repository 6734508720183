import React, { useEffect, useState } from 'react'
import DashBoardFilter from '../DashBoardFilter/DashBoardFilter'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
import EmptyDataImage from '../../../../resources/emptydataimage.png'
import Chart from '../../Chart/Chart'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'

const DashBoardChart = () => {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filterType, setFilterType] = useState('all')
  const { user } = useAuth()

  const { mutate, isLoading } = useBackendService(
    `/stock_records/dashrecord/${user.farmId}`,
    'get',
    {
      onSuccess: data => {
        setData(data.data)
        setFilteredData(data.data)
      },
      onError: error => {
        console.log(error)
      }
    }
  )

  useEffect(() => {
    mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFilter = (filterType, filterValue) => {
    setFilterType(filterType)
    if (filterType === 'farm') {
      setFilteredData(data.filter(farm => farm.farm_id === filterValue))
    } else if (filterType === 'product') {
      const productMap = {}

      data.forEach(farm => {
        farm.products.forEach(product => {
          if (product.name === filterValue) {
            if (!productMap[product.name]) {
              productMap[product.name] = []
            }
            productMap[product.name].push({
              farm_name: farm.farm_name,
              quantity: product.quantity
            })
          }
        })
      })

      const filteredProductMap = Object.fromEntries(
        Object.entries(productMap).filter(
          ([productName, farms]) => farms.length > 0
        )
      )

      setFilteredData(filteredProductMap)
    } else {
      setFilteredData(data)
    }
  }

  return (
    <div className='chart-cover'>
      <div className='seperator'>
        <h1 className='text-sm lg:text-lg w-[500px] font-medium'>
          {data.length > 0 ? 'Branches' : ' General overview'}
        </h1>
        <DashBoardFilter data={data} onFilter={handleFilter} />
      </div>
      <div className='w-full h-full'>
        {isLoading
          ? (
            <div className='w-full h-[400px] center flex-col gap-1 text-black'>
              <div className='relative'>
                <div class='w-20 h-20 border-[#adee68] border-2 rounded-full' />
                <div className='w-20 h-20 border-[#247e2b] border-t-2 animate-spin rounded-full absolute left-0 top-0' />
              </div>

              <div className='relative'>
                <div className='w-10 h-10 border-[#adee68] border-2 rounded-full' />
                <div className='w-10 h-10 border-[#247e2b] border-t-2 animate-spin rounded-full absolute left-0 top-0' />
              </div>

              <div className='relative'>
                <div className='w-5 h-5 border-[#adee68] border-2 rounded-full' />
                <div className='w-5 h-5 border-[#247e2b] border-t-2 animate-spin rounded-full absolute left-0 top-0' />
              </div>
            </div>
            )
          : filteredData.length === 0
            ? (
              <EmptyDataContent
                image={EmptyDataImage}
                text='No overview yet'
                desc='Add branches, products, stock, e.t.c to start seeing an overview.'
              />
              )
            : (
              <Chart data={filteredData} filterType={filterType} />
              )}
      </div>
    </div>
  )
}

export default DashBoardChart
