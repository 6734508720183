import { Plus } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../config/contexts/authProvider'
import Hurry from '../../../resources/hurry.png'
import Modal from '../../SharedComponents/Modal/Modal'
import DashBoardChart from './DashBoardChart/DashBoardChart'
import DashBoardHeader from './DashBoardHeader/DashBoardHeader'

function DashBoard () {
  const { firstTime, isParentFarm, user } = useAuth()
  const [isModalOpen, setModalOpen] = useState(false)
  const showModal = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    if (firstTime) {
      showModal()
    }
  }, [firstTime])

  return (
    <div className='w-full h-full  bg-white p-[3%] lg:p-[2%] space-y-5'>
      <div className='w-full flex lg:flex-col flex-col-reverse gap-2 items-center justify-center'>
        <DashBoardHeader
          title='Welcome'
          desc='Manage your organizations activities with ease and take charge'
          btntext='Add new branch'
          link='/farms/new-farm'
          icon={<Plus size={20} />}
          parent={isParentFarm}
          role={user.role}
        />
      </div>
      <DashBoardChart />
      <Modal
        title='Welcome to T-Gaims'
        desc='Create your first calendar now and get personalized guidance tailored to your success in your field.'
        welcomeicon={Hurry}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </div>
  )
}

export default DashBoard
