import { ChevronRight } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'
import './Index.css'

function InvestContainer ({ item, unlock }) {
  return (
    <div className='basic-investment-content-ctn w-full'>
      <div
        className='w-full h-[130px] lg:h-[200px] p-5 flex justify-end rounded-lg overflow-hidden bg-cover bg-center'
        style={{ backgroundImage: `url(${item.image_url})` }}
      >
        <div className='investment'>
          <p>investment: {item.user_investment_count}</p>
        </div>
      </div>
      <div className='flex flex-row justify-between items-center mt-3'>
        <div className='flex flex-col-reverse lg:flex-col lg:w-[70%]'>
          <p className='text-black font-[600] ts'>{item.title}</p>
          <div className='font-[500] text-grey ts'>
            <p>{item.about}</p>
          </div>
        </div>
        {unlock
          ? (
            <Link
              to={`/market/investment/${item.id}`}
              className='hidden lg:flex cursor-pointer items-center flex-row gap-[10px] text-primary w-[30%]'
            >
              <p className='ts font-[700] w-full'>Check out</p>
              <ChevronRight size={22} />
            </Link>
            )
          : (
            <Link
              to={`/investment/${item.id}`}
              className='hidden lg:flex cursor-pointer items-center flex-row gap-[10px] text-primary'
            >
              <p className='ts font-[700]'>Check out</p>
              <ChevronRight size={22} />
            </Link>
            )}
      </div>
    </div>
  )
}

export default InvestContainer
