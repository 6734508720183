import { X } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import TextInput from '../../../SharedComponents/TextInput/TextInput'

function ModalForUserInvestAccount ({ isOpen, onClose, units, price, id }) {
  const { user } = useAuth()
  const paystackPublicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  const [cards, setCards] = useState([])
  const [selectedCard, setSelectedCard] = useState(null)
  const [showCardFields, setShowCardFields] = useState(false)
  const { mutate: fetchCards } = useBackendService('/cards', 'get', {
    onSuccess: (response) => {
      setCards(response.data)
    },
    onError: (error) => {}
  })

  const { mutate: processInvestment } = useBackendService(
    '/investments/users_investment',
    'post'
  )

  useEffect(() => {
    if (user?.id) {
      fetchCards({ user_id: user.id })
    }
  }, [user, fetchCards])

  useEffect(() => {
    if (showCardFields) {
      reset({ cardnumber: '', cvv: '', expirydate: '' })
      setValue('cardnumber', selectedCard.card_number)
      setValue('cvv', selectedCard.cvv)
      setValue('expirydate', selectedCard.expiry_date)
    } else {
      reset({ cardnumber: '', cvv: '', expirydate: '' })
    }
    // eslint-disable-next-line
  }, [showCardFields])

  const PRICE_PER_SHARE = price

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })

  const unit = watch('unit', 0) // Default to 0 if undefined
  const totalPrice = parseInt(unit || 0, 10) * PRICE_PER_SHARE

  const onSubmit = (data) => {
    if (selectedCard || (data.cardnumber && data.expirydate && data.cvv)) {
      const [month, year] = data.expirydate.split('/')
      const paystack = window.PaystackPop.setup({
        key: paystackPublicKey,
        email: user?.email,
        amount: totalPrice * 100,
        channels: ['card'],
        card: {
          number: data.cardnumber,
          cvv: data.cvv,
          expiry_month: month,
          expiry_year: year
        },
        callback: (response) => {
          const investmentData = {
            investment_id: id,
            user_id: user.id,
            units: unit,
            amount: totalPrice,
            status: 'success',
            payment_reference: response.reference,
            card: selectedCard ? selectedCard.card_id : null
          }
          processInvestment(investmentData)
          onClose()
          toast.success('Payment successful!')
        },
        onClose: () => {
          toast.error('Payment process was closed.')
        }
      })

      paystack.openIframe()
    } else {
      toast.error('Please select a card or enter complete card details.')
    }
  }

  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='bg-white w-[90%] max-w-[500px] rounded-lg text-[#444444] shadow-lg p-4 relative'>
        <h1 className='text-black ts w-full center text-center font-[500]'>
          Invest
        </h1>
        <button
          onClick={onClose}
          className='absolute top-4 right-2 hover:text-gray-700'
        >
          <X size={20} />
        </button>

        <form className='w-full mt-4' onSubmit={handleSubmit(onSubmit)}>
          <div className='flex items-center justify-between'>
            <label htmlFor='unit'>How many units would you like to buy?</label>
            <span className='text-sm text-gray-500'>
              {units} units available
            </span>
          </div>

          <TextInput
            register={register}
            text=''
            placeholder='Input here'
            name='unit'
            errors={errors}
            validation={{
              required: 'This field is required',
              min: { value: 1, message: 'Enter at least 1 unit' },
              max: {
                value: units,
                message: `You cannot buy more than ${units} units`
              },
              pattern: { value: /^\d+$/, message: 'Enter a valid number' }
            }}
          />
          <div className='w-full flex justify-between items-center ts mt-3'>
            <h1>Price per share</h1>
            <h1>₦{PRICE_PER_SHARE.toLocaleString()}</h1>
          </div>
          {totalPrice > 0 && (
            <>
              <div className='w-full flex justify-between items-center ts mt-3'>
                <h1>Estimated amount</h1>
                <h1>₦{totalPrice.toLocaleString()}</h1>
              </div>
              <h1 className='ts mt-3'>Select payment option</h1>

              <div className='mt-3 w-full flex justify-start flex-wrap items-center gap-3 '>
                {/* card */}
                {cards.map((card, index) => (
                  <div
                    className={`w-[48%] bg-darkGreen rounded-md p-3 relative h-[110px] ${
                      selectedCard === card ? 'border-2 border-white' : ''
                    }`}
                    key={index}
                  >
                    <img
                      src={require('../../../../../website/assets/circlegetintouch.png')}
                      className='absolute right-0 bottom-0 w-[35px]'
                      alt='circle'
                    />
                    <div className='w-full flex justify-between items-center'>
                      <img
                        alt='img'
                        src={require('../../../../resources/mastercard.png')}
                        className='w-[37px] h-[25px]'
                      />
                      <input
                        onClick={() => setSelectedCard(card)}
                        type='checkbox'
                        id='toggleCardFields'
                        checked={selectedCard === card}
                        onChange={() => {
                          if (selectedCard === card) {
                            setSelectedCard(null)
                            setShowCardFields(false)
                          } else {
                            setSelectedCard(card)
                            setShowCardFields(true)
                          }
                        }}
                        className='text-white w-[15px] h-[15px]'
                      />

                      <h1 className='absolute left-3 bottom-4 text-white ts'>
                        {' '}
                        **** **** **** {card.card_number.slice(-4)}
                      </h1>
                    </div>
                  </div>
                ))}
              </div>

              {!showCardFields && (
                <>
                  <div className='w-full center gap-3 text-[#888888] mt-3 ts'>
                    <div className='w-[35%] h-[1px] bg-[#888888]' />
                    <h1 className='font-[400]'>Or pay with</h1>
                    <div className='w-[35%] h-[1px] bg-[#888888]' />
                  </div>
                  <div className='grid w-full items-center gap-1.5 ts mt-3'>
                    <label htmlFor='email'>Card number</label>
                    <input
                      type='number'
                      name='cardnumber'
                      placeholder='0000 0000 0000 0000'
                      {...register('cardnumber', {
                        required: 'Card number is required',
                        minLength: {
                          value: 16,
                          message: 'Card number must be at least 16 digits'
                        },
                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'Card number must be a number'
                        }
                      })}
                      className='email-input ts'
                      onWheel={(e) => e.target.blur()}
                    />

                    {errors.cardnumber && (
                      <p className='text-red-500 text-xs'>
                        {errors.cardnumber.message}
                      </p>
                    )}
                  </div>
                  <div className='w-full center gap-4 mt-3'>
                    <div className='grid w-[50%] items-center gap-1.5 ts'>
                      <label htmlFor='email'>Expiry Date</label>
                      <input
                        type='text'
                        name='expirydate'
                        placeholder='MM/YY'
                        {...register('expirydate', {
                          required: 'Expiry date is required',
                          pattern: {
                            value: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                            message: 'Invalid expiry date format (MM/YY)'
                          }
                        })}
                        className='email-input ts'
                      />
                      {errors.expirydate && (
                        <p className='text-red-500 text-xs'>
                          {errors.expirydate.message}
                        </p>
                      )}
                    </div>
                    <div className='grid w-[50%] items-center gap-1.5 ts'>
                      <label htmlFor='email'>CVV</label>
                      <input
                        type='text'
                        name='cvv'
                        placeholder='123'
                        maxLength={3}
                        {...register('cvv', {
                          required: 'CVV is required',
                          pattern: {
                            value: /^[0-9]{3}$/,
                            message: 'Invalid CVV format (3 digits)'
                          }
                        })}
                        className='email-input ts'
                      />
                      {errors.cvv && (
                        <p className='text-red-500 text-xs'>
                          {errors.cvv.message}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {/* Conditionally render Proceed button */}
          {unit > 0 && (
            <div className='w-full mt-3'>
              <button
                type='submit'
                className={`ts ${
                  isDirty && isValid
                    ? 'activebutton'
                    : 'h-[50px] w-full rounded-[8px] bg-darkash text-black'
                }`}
                disabled={!(isDirty && isValid)}
              >
                Invest
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default ModalForUserInvestAccount
