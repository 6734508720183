import { Check, ChevronRight, Landmark, Loader2 } from 'lucide-react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import useBackendService from '../../../../config/services/backend-service'
import Modal from '../../SharedComponents/Modal/Modal'

const EnterPrisePlanPayment = ({ onComplete }) => {
  const { enterpriseUser } = useSelector((state) => state.signUp || {})
  const [isLoading, setIsLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const paystackPublicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false
  })

  const { mutate } = useBackendService('/ipn/paystack/save_recurring', 'post', {
    onSuccess: (data) => {
      setIsCompleted(true)
      setIsLoading(false)
    },
    onError: (error) => {}
  })
  const planType = 'yearly'
  let amount = 1200000

  // This way we can test with demo banks
  if (process.env.REACT_APP_NODE_ENV !== 'production') {
    amount = 12000
  }

  const onSubmit = async () => {
    setIsLoading(true)

    try {
      const paystack = window.PaystackPop.setup({
        key: paystackPublicKey,
        email: enterpriseUser?.email,
        amount: amount * 100,
        channels: planType === 'yearly' ? ['bank_transfer'] : ['card'],
        callback: (response) => {
          toast.success('Payment successful!')
          mutate({
            reference: response.reference,
            plan: 'Enterprise',
            type: planType
          })
        },
        onClose: () => {
          setIsLoading(false)
          setModal({
            type: 'error',
            title: 'Payment process error',
            desc: 'Payment window was closed.',
            visible: true
          })
          setModalOpen(true)
        }
      })
      paystack.openIframe()
    } catch (error) {
      setIsLoading(false)
      setModal({
        type: 'error',
        title: 'Unable to initiate Payment process',
        desc: 'You are unable to initiate Payment process at the moment.',
        visible: true
      })
      setModalOpen(true)
    }
  }

  return (
    <>
      {!isLoading && !isCompleted ? (
        <div className='w-full h-full'>
          <h1 className='text-darkGreen font-semibold lg:text-lg'>
            Enterprise Plan Payment
          </h1>
          <p className='ts text-red-600 mt-1'>
            You are about to purchase the <b>₦1,200,000 Enterprise Plan</b>{' '}
            using your email address <b>{enterpriseUser?.email}</b>. Please
            ensure this email is accurate, as verification will be required
            later.
          </p>

          <div className='mt-[5%] space-y-5 w-full'>
            <div className='flex gap-2'>
              <div className='w-full flex gap-2 justify-start items-center'>
                <h1 className='text-black ts font-[500]'>
                  {' '}
                  Yearly Payment : ₦1,200,000{' '}
                </h1>
              </div>
            </div>

            <div
              onClick={onSubmit}
              className='w-full bg-darkGreen rounded-lg cursor-pointer text-white font-[400] ts p-6 flex justify-between items-center'
            >
              <div className='w-[70%] flex gap-3'>
                <Landmark size={20} />
                <h1>Pay via Bank Transfer</h1>
              </div>

              <ChevronRight size={20} />
            </div>
          </div>
          {/* note ui */}
          <div className='w-full rounded-lg bg-[#1446180D] border border-darkGreen mt-[10%] p-[4%] ts text-darkGreen font-[500] space-y-1'>
            <h1>Notice</h1>
            <h1 className='leading-[16px]'>
              The T-Gaims enterprise plan is only available as a yearly plan at
              this time. Please proceed with the yearly option to get started.
            </h1>
          </div>
        </div>
      ) : isLoading ? (
        <div className='w-full h-[70vh] lg:h-screen flex items-center justify-center flex-col gap-4 text-center '>
          <Loader2 size={40} className='animate-spin' />
          <h1 className='font-semibold text-xl'>Payment Processing</h1>
          <p className='text-[#666666] ts'>
            Please be patient and bear with us while we process your plan
            payment.
          </p>
          <button className='w-full lg:w-[80%] mx-auto h-[50px] bg-darkGreen text-white ts rounded-[8px] center'>
            Continue
          </button>
        </div>
      ) : (
        <div className='w-full h-[70vh] lg:h-screen flex items-center justify-center flex-col gap-4 text-center '>
          <div className='border-2 rounded-full p-2 border-solid border-darkgreen'>
            <Check size={40} className='text-darkGreen' />
          </div>
          <h1 className='font-semibold text-xl'>
            Well-done, Payment Successful
          </h1>
          <p className='text-[#666666] ts'>
            Your payment of ₦1,200,000 for an Enterprise account was successful.
            Kindly continue to setup your account.
          </p>
          {/* the oncomplete function moves to the next steps only if the payment is successful */}
          <div
            onClick={() => onComplete()}
            className='cursor-pointer w-full lg:w-[80%] mx-auto h-[50px] bg-darkGreen text-white ts rounded-[8px] center'
          >
            Continue
          </div>
        </div>
      )}
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  )
}

export default EnterPrisePlanPayment
