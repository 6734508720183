import { Check } from 'lucide-react'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import {
  agribusinessProductTypes,
  productInputStatus,
  productUnitInput
} from '../../../../data/static'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import Modal from '../../../SharedComponents/Modal/Modal'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import './index.css'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'

function AddNewProduct () {
  const [isChecked, setIsChecked] = useState(true)
  const { user } = useAuth()
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty }
  } = useForm()

  const { mutate: createProducts, isLoading } = useBackendService('/products', 'post', {
    onSuccess: (response) => {
      setModal({
        title: 'Product added',
        desc: 'Well done , you have successfully created a new product',
        type: 'success',
        link: '/farm-products',
        visible: true
      })
      setModalOpen(true)
    },
    onError: (error) => {
      setModal({
        type: 'error',
        title: 'Submission failed',
        desc: 'You are unable to create a product',
        visible: true
      })
      setModalOpen(true)
    }
  })

  const onSubmit = async (data) => {
    const unitValue = parseFloat(data.unitValue)
    const thresholdValue = parseFloat(data.thresholdValue)

    let isValidInput = true

    if (isNaN(unitValue)) {
      toast.error('Unit Value must be a valid number.')
      isValidInput = false
    }

    if (isNaN(thresholdValue)) {
      toast.error('Minimum Threshold must be a valid number.')
      isValidInput = false
    }

    if (!isValidInput) return
    createProducts({
      name: data.nameInput,
      type: data.typeInput,
      unitType: data.unitInput,
      unitValue: data.unitValue,
      active: data.statusInput,
      minimumThreshold: data.thresholdValue,
      manufacturer: data.manufacturerName,
      farmId: user.farmId
    })
  }
  return (
    <section className='newProductwrapper'>
      <div className=' mb-[30px] '>
        <BackButton />
      </div>
      <div className='space-y-0 lg:space-y-2 '>
        <h1 className='lg:text-3xl text-xl text-black'>Add new product</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Fill in the details of your new products
        </p>
      </div>
      <form className='productform' onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full flex items-center flex-col md:flex-row gap-[60px] '>
          <TextInput
            name='nameInput'
            register={register}
            errors={errors}
            text='Name'
            subtext='Enter the name of your product.'
          />
          <SelectInput
            /*  register={register} */
            control={control}
            Controller={Controller}
            data={agribusinessProductTypes}
            title='Type'
            name='typeInput'
            subtext='Select the category or type of the product.'
            placeholder='livestock'
          />
        </div>
        <div className='w-full flex items-center flex-col md:flex-row gap-[60px] '>
          <SelectInput
            control={control}
            Controller={Controller}
            data={productUnitInput}
            title='Unit'
            name='unitInput'
            subtext='Select the unit of measurement for this product'
            placeholder='kg'
          />
          <TextInput
            name='unitValue'
            register={register}
            errors={errors}
            text='Unit Value'
            subtext='Enter the value per unit of the product.'
          />
        </div>
        <div className='w-full flex items-center flex-col md:flex-row gap-[60px] '>
          <SelectInput
            control={control}
            Controller={Controller}
            subtext='Select the current status of the product. This helps in keeping track of product availability.'
            title='Status'
            placeholder='Select a product name'
            data={productInputStatus}
            name='statusInput'
          />
          <TextInput
            name='thresholdValue'
            register={register}
            errors={errors}
            text='Minimum threshold'
            subtext='Set the minimum stock level for this product. Alerts will be generated when it falls below this level.'
          />
        </div>
        <div className=' flex flex-col  gap-[30px] '>
          <div>
            <p className='md:text-[18px] text-[12px] text-black'>
              Manufacturer
            </p>
            <p className='text-[#888888] text-[12px] md:text-[16px'>
              Do you manufacture this product yourself
            </p>
          </div>
          <div className=' flex items-center gap-[30px] '>
            <div className='flex gap-2'>
              <div
                className={
                  isChecked === false
                    ? 'w-6 h-6 border rounded-lg'
                    : 'w-6 h-6 border rounded-lg bg-darkGreen flex items-center justify-center '
                }
                onClick={() => setIsChecked(true)}
              >
                {isChecked === true && <Check color='#fff' size={15} />}
              </div>
              <label htmlFor='yes'>Yes</label>
            </div>
            <div
              className='flex items-center gap-2'
              onClick={() => setIsChecked(false)}
            >
              <div
                className={
                  isChecked === true
                    ? 'w-6 h-6 border rounded-lg'
                    : 'w-6 h-6 border rounded-lg bg-darkGreen flex items-center justify-center '
                }
              >
                {isChecked === false && <Check color='#fff' size={14} />}
              </div>
              <label htmlFor='yes'>No</label>
            </div>
          </div>
          {isChecked === false && (
            <div className='w-full flex items-center flex-col lg:flex-row gap-[60px] '>
              <TextInput
                name='manufacturerName'
                register={register}
                errors={errors}
                text='Manufacturer’s name'
              />
              <TextInput
                register={register}
                errors={errors}
                text='Manufacturer’s contact'
              />
            </div>
          )}
        </div>

        <div className='w-full lg:w-1/2'>
          <GlobalButton
            isDirty={isDirty}
            isValid={isValid}
            isLoading={isLoading}
            title='Create new product'
          />
        </div>
      </form>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </section>
  )
}

export default AddNewProduct
