import React from 'react'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import DynamicTextArea from '../../../SharedComponents/TextAreaInput/DynamicTextArea'
import Accordion from '../../../SharedComponents/Accordion/Accordion'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'

function Sales ({ handlePrev, register, errors, isValid, isLoading, isDirty }) {
  return (
    <div className=' flex flex-col gap-5 lg:gap-10 max-w-fit  '>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='storageSolutions'
          register={register}
          errors={errors}
          text='Storage Solutions'
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='packagingMethods'
          register={register}
          errors={errors}
          text='Packaging Methods'
        />
      </div>

      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='logisticsProviders'
          register={register}
          errors={errors}
          text='Logistics Providers'
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='salesChannels'
          register={register}
          errors={errors}
          text='Sales Channels'
        />
      </div>
      <div className=' w-full lg:w-[876px] border border-[#f2f2f2] '>
        <div className=' p-4 lg:p-8 border-b border-[#f2f2f2] '>
          <Accordion
            text='Market Prices'
            subtext='Enter a minimum of 5 market prices'
          />
        </div>
        <div className=' p-4 lg:p-8 '>
          <DynamicTextArea
            name='marketPrices'
            register={register}
            errors={errors}
            text='Market Prices'
            isMarketPrice
            nextbtn='Add Another Market Price'
          />
        </div>
      </div>
      <div className=' flex flex-row items-center gap-[15px] lg:gap-[30px] '>
        <div
          className={`w-full flex items-center justify-center  lg:w-[210px] h-[50px] ${
            isValid
              ? 'proceed-btn cursor-pointer bg-darkash text-primary border border-darkGreen'
              : 'rounded-[8px] bg-darkash text-primary border border-darkGreen '
          }`}
          onClick={handlePrev}
        >
          Previous
        </div>

        <div className='w-full lg:w-[210px]'>
          <GlobalButton
            title='Submit'
            isLoading={isLoading}
            isDirty={isDirty}
            isValid={isValid}
          />
        </div>

      </div>
    </div>
  )
}

export default Sales
