import { ArrowLeft, Timer } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import ImageInput from '../../../SharedComponents/ImageInput/ImageInput'
import Modal from '../../../SharedComponents/Modal/Modal'
import GlobalLoader from '../../../SharedComponents/GlobalLoader/GlobalLoader'
import NoteComponent from '../../../SharedComponents/NoteComponent/NoteComponent'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'

const formatDateValue = (date) => {
  if (!date) return ''
  const formattedDate = new Date(date).toISOString().split('T')[0]
  return formattedDate
}

function InvestmentDetails () {
  const { user } = useAuth()
  const { id } = useParams()
  const [preview, setPreview] = useState(null)
  const [imageUploaded, setImageUploaded] = useState(false)
  console.log('image', imageUploaded)
  const [data, setData] = useState({})
  const imageInput = useRef(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })

  const { mutate: fetchInvestment, isLoading: investLoder } = useBackendService(
    `/investments/${id}`,
    'get',
    {
      onSuccess: (response) => {
        setData(response.data)
        setValue('title', response.data.title)
        setValue('about', response.data.about)
        setValue('price', response.data.price)
        setValue('availableSlots', response.data.available_slot)
        setValue('startDate', formatDateValue(response.data.start_date))
        setValue('endDate', formatDateValue(response.data.end_date))
        setValue('investmentType', response.data.investment_type)
        setValue('payoutDate', formatDateValue(response.data.payout_date))
        setValue('repaymentPlan', response.data.repayment_plan)
        setValue('investmentRisk', response.data.investment_risk)
        setPreview(response.data.image_url)
      },
      onError: (error) => {
        console.error('Failed to fetch investment data:', error)
        toast.error('Failed to fetch investment data')
      }
    }
  )

  useEffect(() => {
    fetchInvestment()
  }, [fetchInvestment])

  const { mutate: updateInvestment, isLoading } = useBackendService(
    `/investments/${id}`,
    'put',
    {
      onSuccess: () => {
        setModal({
          title: 'Investment added',
          desc: 'Well done , you have successfully updated an investment',
          type: 'success',
          link: '/admin-investments',
          visible: true
        })
        setModalOpen(true)
      },
      onError: (error) => {
        setModal({
          type: 'error',
          title: 'Submission failed',
          desc: 'You are unable to update an investment',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )

  const handleImageChange = (e) => {
    const file = e.target.files?.[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setPreview(url)
      setValue('newsimage', url)
      setImageUploaded(true)
    }
  }

  const handlePencilClick = () => {
    imageInput.current?.click()
  }

  const onSubmit = (data) => {
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('about', data.about)
    formData.append('price', data.price)
    formData.append('availableSlots', data.availableSlots)
    formData.append('startDate', data.startDate)
    formData.append('endDate', data.endDate)
    formData.append('investmentType', data.investmentType)
    formData.append('payoutDate', data.payoutDate)
    formData.append('repaymentPlan', data.repaymentPlan)
    formData.append('investmentRisk', data.investmentRisk)
    if (data.newsimage) formData.append('img', data.newsimage)

    updateInvestment(formData)
  }
  const handleStatusChange = (status) => {
    const formData = new FormData()
    formData.append('status', status)

    updateInvestment(formData)
  }

  const textToShow = user.role === 'approver' ? 'Image' : 'Upload cover image'
  const isFilled = isValid && isDirty && !imageUploaded
  // Loading state
  if (investLoder) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <div className='flex lg:flex-row flex-col lg:gap-0 gap-3 px-3 lg:px-5 bg-white'>
      <div className='flex flex-col flex-[0.7] lg:mr-3 lg:py-5 py-3 lg:gap-10 gap-5'>
        <div className='flex flex-col'>
          <a href='/admin-investments'>
            <ArrowLeft size={24} />
          </a>
        </div>
        <div className='space-y-1 lg:space-y-2'>
          <h1 className='lg:text-xl text-[12px] text-black'>Investment</h1>
          <div className='flex items-center gap-1.5'>
            <div className='hidden lg:flex'>
              <Timer size={18} />
            </div>
            <div className='flex lg:hidden'>
              <Timer size={12} />
            </div>
            <p className='text-grey font-[400] text-[12px] lg:text-[16px]'>
              Status <span className='text-[#F29339]'>{data.status}</span>
            </p>
          </div>
        </div>
        <form
          action=''
          className='flex flex-col gap-5 lg:gap-10 w-full'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='lg:w-[406px] w-full'>
            <TextInput
              name='title'
              register={register}
              errors={errors}
              text='Title'
              disabled={user.role === 'approver'}
            />
          </div>
          <div className='grid w-full gap-1.5 ts'>
            <h1>{textToShow}</h1>
            <ImageInput
              preview={preview}
              imageInput={imageInput}
              handleImageChange={handleImageChange}
              handlePencilClick={handlePencilClick}
              disabled
            />
            {errors.newsimage && (
              <p className='text-red-500'>{errors.newsimage.message}</p>
            )}
          </div>

          <TextArea
            name='about'
            register={register}
            errors={errors}
            text='About'
            disabled={user.role === 'approver'}
          />
          <div className='flex flex-1 gap-5 lg:gap-[60px] items-center'>
            <TextInput
              name='price'
              register={register}
              errors={errors}
              text='Price'
              disabled={user.role === 'approver'}
            />
            <TextInput
              name='availableSlots'
              register={register}
              errors={errors}
              text='Available Slots'
              disabled={user.role === 'approver'}
            />
          </div>
          <div className='flex flex-1 gap-5 lg:gap-[60px] items-center'>
            <div className='flex-[0.5]'>
              <TextInput
                name='startDate'
                type='date'
                register={register}
                errors={errors}
                text='Start Date'
                disabled={user.role === 'approver'}
              />
            </div>
            <div className='flex-[0.5]'>
              <TextInput
                name='endDate'
                type='date'
                register={register}
                errors={errors}
                text='End Date'
                disabled={user.role === 'approver'}
              />
            </div>
          </div>
          <div className='flex flex-1 gap-5 lg:gap-[60px] items-center'>
            <div className='flex-[0.5]'>
              <TextInput
                name='investmentType'
                register={register}
                errors={errors}
                text='Investment Type'
                disabled={user.role === 'approver'}
              />
            </div>
            <div className='flex-[0.5]'>
              <TextInput
                name='payoutDate'
                type='date'
                register={register}
                errors={errors}
                text='Payout Date'
                disabled={user.role === 'approver'}
              />
            </div>
          </div>
          <TextArea
            name='repaymentPlan'
            register={register}
            errors={errors}
            text='Repayment Plan'
            disabled={user.role === 'approver'}
          />
          <TextArea
            name='investmentRisk'
            register={register}
            errors={errors}
            text='Investment Risk'
            disabled={user.role === 'approver'}
          />
          {data.status !== 'approved' &&
            (user.role === 'approver'
              ? (
                <div className='flex gap-5'>
                  <button
                    type='button'
                    className='w-full lg:w-[200px] h-[50px] rounded-[8px] bg-green-500 text-white'
                    onClick={() => handleStatusChange('approved')}
                  >
                    Approve
                  </button>
                  <button
                    type='button'
                    className='w-full lg:w-[200px] h-[50px] rounded-[8px] bg-red-500 text-white'
                    onClick={() => handleStatusChange('rejected')}
                  >
                    Reject
                  </button>
                </div>
                )
              : (

                <div className='w-full lg:w-[408px]'>
                  <GlobalButton
                    title='Submit'
                    isFilled={isFilled}
                    isLoading={isLoading}
                  />
                </div>

                ))}
        </form>
      </div>
      {(user.role === 'approver' || user.id === data.created_by) && (
        <div className='flex-[0.3]'>
          <NoteComponent data={data.id} table='investment' />
        </div>
      )}
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default InvestmentDetails
