import React from 'react'

const UserInvestments = ({ item }) => {
  const isLowPercent = item.percent < 2

  // Format price with commas
  const formattedPrice = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 0
  }).format(item.price)

  return (
    <div className='basic-investment-content-ctn w-full' key={item.id}>
      {/* Investment Image */}
      <div
        className='w-full h-[130px] lg:h-[200px] p-5 flex justify-end rounded-lg overflow-hidden bg-cover bg-center'
        style={{ backgroundImage: `url(${item.image_url})` }}
      >
        <div className='investment'>
          <p>Investment: {item.user_investment_count}</p>
        </div>
      </div>

      {/* Title and Percent */}
      <div className='flex w-full justify-between items-center mt-3'>
        <p className='text-black font-[600] ts capitalize'>{item.title}</p>
        <div
          className={`font-[500] ts flex items-center gap-1 ${
            isLowPercent ? 'text-red-500' : 'text-green-500'
          }`}
        >
          {isLowPercent
            ? (
              <img
                alt='img'
                src={require('../../../../resources/redarrow.png')}
                className='w-3 h-3'
              />
              )
            : (
              <img
                alt='img'
                src={require('../../../../resources/greenarrow.png')}
                className='w-3 h-3'
              />
              )}
          <p>{item.percent}%</p>
        </div>
      </div>

      {/* Additional Section */}
      <div className='flex w-full justify-between items-center mt-1'>
        <p className='text-black font-[400] text-xs lg:text-sm capitalize'>{item.subtitle}</p>
        <div className='font-[600] text-black ts'>
          <p>{formattedPrice}</p>
        </div>
      </div>
    </div>
  )
}

export default UserInvestments
