import React from 'react'

const EmptyDataContent = ({ image, text, desc }) => {
  return (
    <div className='w-full h-[400px] center flex-col gap-1 text-black'>
      <img src={image} className='w-[150px] h-[150px]' alt='emptydataimage' />
      <h1 className='font-semibold'>{text}</h1>
      <p className='w-[400px] mx-auto text-center text-[#464665]'>{desc}</p>
    </div>
  )
}

export default EmptyDataContent
