import { Loader2 } from 'lucide-react'
import React from 'react'

const GlobalButton = ({ isDirty, isValid, isLoading, title, isFilled, onClick }) => {
  const isEnabled = isFilled !== undefined
    ? isFilled
    : isDirty && isValid

  return (
    <button
      type='submit'
      onClick={onClick || undefined}
      disabled={!isEnabled || isLoading}
      className={`ts center ${
isEnabled
  ? 'activebutton'
  : 'h-[50px] w-full rounded-[8px] bg-darkash text-black'
}`}
    >
      {isLoading
        ? (
          <Loader2 className='w-6 h-6 lg:h-8 lg:w-8 animate-spin text-white' />
          )
        : (
            title
          )}

    </button>
  )
}

export default GlobalButton
