import { ArrowLeft, ChevronDown } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import { updateUserWithFarmId } from '../../../../../config/store/reducers/authReducer'
import { statesInNigeria } from '../../../../data/static'
import Modal from '../../../SharedComponents/Modal/Modal'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import EnterpriseBusinessTypeSelector from '../../../SignUp/EnterprisePlanSignUp/EnterpriseBusinessTypeSelector'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'

const AddFarm = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })
  const [farmId, setFarmId] = useState(null)
  const [adminData, setAdminData] = useState(null)
  const { user } = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      businesstype: []
    }
  })

  const handleSave = () => {
    if (selectedValue) {
      const selectedSubtypes = Object.keys(selectedValue)
      setValue('businesstype', selectedSubtypes, { shouldValidate: true })
    }
  }
  // Track the values of the required fields
  const name = watch('name')
  const state = watch('state')
  const cacNumber = watch('cacnumber')
  const address = watch('address')

  const isFormFilled = name && state && cacNumber && address && selectedValue
  const { mutate: createFarm, isLoading } = useBackendService('/farms', 'post', {
    onSuccess: (data) => {
      if (user.farmId) {
        setFarmId(data.data.id)
      }
      dispatch(updateUserWithFarmId({ farmId: data.data.id }))
      setModal({
        type: 'success',
        title: 'Business successfully created',
        desc: 'Well done , you have successfully created a new business.',
        link: '/businesses',
        visible: true
      })
      setModalOpen(true)
    },
    onError: () => {
      setModal({
        type: 'error',
        title: 'Unable to Create a business',
        desc: 'You are unable to create a business at the moment.',
        visible: true
      })
      setModalOpen(true)
    }
  })

  const { mutate: createUser } = useBackendService('/users', 'post', {
    onSuccess: () => {
      navigate('/businesses')
    },
    onError: (error) => {
      console.log(error)
    }
  })

  const onSubmit = async (data) => {
    setAdminData({
      firstName: data.firstName,
      lastName: data.lastName,
      isAdmin: false,
      location: data.location,
      plan: 'Enterprise',
      phoneNumber: data.phoneNumber,
      role: 'administrator',
      email: data.email,
      password: data.password
    })

    createFarm({ ...data, parentFarmId: user.farmId, email: user.email })
  }

  useEffect(() => {
    if (farmId && adminData && user.farmId) {
      createUser({ ...adminData, farmId })
    }
  }, [farmId, adminData, createUser, user.farmId])

  return (
    <div className='farmwrapper'>
      <Link to='/businesses' className='w-full'>
        <ArrowLeft size={20} />
      </Link>
      <div className='space-y-0 md:space-y-1'>
        <h1 className='lg:text-3xl text-xl text-black'>Create business</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Fill in the details of your business
        </p>
      </div>
      <form
        className='mt-[8%] w-full lg:w-[90%] h-full space-y-4 md:space-y-6'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='w-full flex flex-col md:flex-row gap-2 '>
          <TextInput
            register={register}
            text='Business name'
            name='name'
            errors={errors}
          />
          <SelectInput
            register={register}
            control={control}
            Controller={Controller}
            title='State'
            data={statesInNigeria}
            name='state'
          />
        </div>
        <div className='w-full flex flex-col md:flex-row gap-2  '>
          <div className='w-full md:w-[50%] '>
            <TextInput
              register={register}
              name='address'
              text='Address'
              errors={errors}
            />
          </div>
          <div className='w-full md:w-[50%] '>
            <TextInput
              register={register}
              name='cacnumber'
              text='CAC Number'
              errors={errors}
            />
          </div>
        </div>

        <div
          className='w-full md:w-[50%]'
          onClick={() => document.getElementById('business').showModal()}
        >
          <TextInput
            name='businesstype'
            register={register}
            errors={errors}
            text='Type of Business'
            dvalue={selectedValue || 'Select business type'}
            placeholder='Select business type'
            calendaricon={<ChevronDown size={20} />}
            validation={{ required: 'Business type is required' }}
          />
        </div>

        <div className='w-full md:w-[50%]'>
          <GlobalButton
            title='Create Business'
            isFilled={isFormFilled}
            isLoading={isLoading}
          />
        </div>
        <EnterpriseBusinessTypeSelector
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          save={handleSave}
        />
      </form>
      {/* Conditionally render the modal */}
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default AddFarm
