import { ArrowUpDown, ChevronDown } from 'lucide-react'
import React, { useState } from 'react'

const ProductFilter = ({ data, table }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentSort, setCurrentSort] = useState('')

  const handleSortingChange = (direction, column) => {
    setIsOpen(false)

    if (column === 'alphabetical') {
      table.setSorting([{ id: 'name', desc: false }])
      setCurrentSort('Alphabetical')
    } else if (column === 'clear') {
      table.setSorting([])
      setCurrentSort('')
    } else if (column === 'stock') {
      table.setSorting([{ id: 'unit_value', desc: false }])
      setCurrentSort('Stock')
    } else if (column === 'manufacturer') {
      table.setSorting([{ id: 'manufacturer', desc: direction === 'desc' }])
      setCurrentSort('Manufacturer')
    }
  }

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <div className='separator px-0 lg:px-3'>
      <h1 className='text-sm lg:text-lg w-[500px] font-semibold'>Product</h1>
      {data.length === 0
        ? (
            ''
          )
        : (
          <div className='dropdown dropdown-hover p-0 relative'>
            <div
              tabIndex={0}
              role='button'
              className='w-[150px] text-sm p-3 center gap-1'
              onClick={toggleDropdown}
            >
              <ArrowUpDown size={20} />
              {currentSort || 'Sort by'}
              <ChevronDown size={20} />
            </div>
            {isOpen && (
              <div tabIndex={0} className='menuz rounded-box sort'>
                <h1
                  className='sort-hover'
                  onClick={() => handleSortingChange('desc', 'stock')}
                >
                  Stock
                </h1>
                <h1
                  className='sort-hover'
                  onClick={() => handleSortingChange('asc', 'manufacturer')}
                >
                  Manufacturer
                </h1>
                <h1
                  className='sort-hover'
                  onClick={() => handleSortingChange('asc', 'alphabetical')}
                >
                  Alphabetical
                </h1>
                <h1
                  className='sort-hover'
                  onClick={() => handleSortingChange('asc', 'clear')}
                >
                  Clear Filter
                </h1>
              </div>
            )}
          </div>
          )}
    </div>
  )
}

export default ProductFilter
