import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import useBackendService from '../../../../../config/services/backend-service'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
import GlobalLoader from '../../../SharedComponents/GlobalLoader/GlobalLoader'

const AdminConsultationDescription = () => {
  const location = useLocation()
  const consultation = location.state?.consultation
  const { id } = useParams()

  const [data, setData] = useState([])

  const { mutate: fetchreport, isLoading } = useBackendService(
    `/admin/admin_report/${id}`,
    'get',
    {
      onSuccess: (response) => {
        setData(response.data)
      },
      onError: (error) => {
        console.error('Error fetching consultations:', error)
      }
    }
  )

  useEffect(() => {
    fetchreport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const truncateFileName = (fileName, charLimit) => {
    if (fileName.length > charLimit) {
      return fileName.slice(0, charLimit) + '...'
    }
    return fileName
  }

  if (!consultation) {
    return <div>No consultation data available</div>
  }

  const getFileTypeIcon = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return require('../../../../resources/pdf.png')
      case 'png':
      case 'jpg':
      case 'jpeg':
        return require('../../../../resources/png.png')
      case 'svg':
        return require('../../../../resources/svg.png')
      default:
        return require('../../../../resources/pdf.png')
    }
  }
  // Loading state
  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  // Find the single consultation data
  return (
    <div className='w-full p-[2%] bg-white'>
      <BackButton />
      <div className='w-full pt-2'>
        <h1 className='text-[18px] font-[500] text-black'>Business Overview</h1>
        <h1 className='text-[14px] font-[400] text-[#666666]'>
          Here is an overview details of the singlebusiness
        </h1>
      </div>
      <div className='w-full flex justify-center items-start gap-3 pt-4'>
        {/* left */}
        <div className='w-[40%] bg-darkGreen text-white rounded-lg p-6 h-full space-y-5'>
          <div className='w-full ts flex justify-between items-center'>
            <h1>Business:</h1>
            <p>{consultation.name}</p>
          </div>
          <div className='w-full ts flex justify-between items-center'>
            <h1>Owner:</h1>
            <p>{consultation.owner}</p>
          </div>
          <div className='w-full ts flex justify-between items-center'>
            <h1>Location:</h1>
            <p>{consultation.address}</p>
          </div>
          <Link
            to={`/admin-create-report/${consultation.id}`}
            className='w-full rounded-lg text-darkGreen bg-white center ts h-[50px] mt-[5%]'
          >
            Create report
          </Link>
        </div>
        {/* right */}
        <div className='w-[60%] border border-[#EEEEEE] rounded-lg h-screen overflow-y-auto '>
          <div className='w-full p-3 border-b-[#dddddd] border'>
            <h1 className='text-[16px] font-[500] text-black'>Reports</h1>
            <h1 className='text-[14px] font-[400] text-[#666666]'>
              An overview of all the reports sent to the business
            </h1>
          </div>

          {data.length > 0 ? (
            data.map((report, index) => (
              <Link to={`/report-description/${report.id}`}>
                <div
                  key={index}
                  className='w-full p-3 text-black ts space-y-1 border border-t-transparent border-b-[#dddddd]'
                >
                  <h1 className='text-[#888888]'>
                    Report #{index + 1} -{' '}
                    {new Date(report.created_at).toLocaleString('en-US', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                    a
                  </h1>
                  <p>{report.description}</p>
                  {/* PDF file */}
                  <div className='rounded-full border border-[#DDDDDD] p-2 w-[35%] flex justify-start gap-2  items-center'>
                    <img
                      alt='file-icon'
                      src={getFileTypeIcon(report.attachments[0].file_path)}
                      className='w-[25px] h-[25px]'
                    />
                    {truncateFileName(report.attachments[0].file_name, 15)}
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div className='flex flex-col items-center justify-center w-full p-8'>
              <EmptyDataContent
                image={require('../../../../resources/farmemptyimage.png')}
                text='No reports available yet'
                desc='Your reports will appear here once submitted.'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AdminConsultationDescription
