import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Sidebar.css'
import { ChevronDown, ChevronUp } from 'lucide-react'

const SideBar = ({
  name,
  icon,
  orange,
  link,
  isSubMenu,
  isManagementOpen,
  isMarketOpen,
  isAgricOpen,
  submenu
}) => {
  const location = useLocation()
  const path = location.pathname
  const isActive =
  (path === '/' && link === '/dashboard') || path.startsWith(link)
  const isExpanded =
    (name === 'Management' && isManagementOpen) ||
    (name === 'Operations' && isMarketOpen) ||
    (name === 'Agribusiness' && isAgricOpen)

  const isSubMenuActive = Array.isArray(submenu) && submenu.some((item) => path.startsWith(item.link))

  const shouldHaveWhiteBg = isSubMenuActive && submenu.some((item) => path.startsWith(item.link)) && name

  const renderIcon = () => {
    const isDarkGreen = isActive || shouldHaveWhiteBg
    if (icon || orange) {
      return (
        <img
          src={isDarkGreen ? orange : icon}
          alt={`${name} icon`}
          className={`w-[20px] h-[20px] ${isDarkGreen ? 'text-darkgreen' : ''}`}
        />
      )
    } else {
      return null
    }
  }

  const renderDropdownArrow = () => {
    if (!link && (name === 'Management' || name === 'Operations' || name === 'Agribusiness')) {
      return isExpanded
        ? (
          <ChevronUp size={20} className={`visible ${shouldHaveWhiteBg ? 'text-darkGreen' : 'text-white'}`} />
          )
        : (
          <ChevronDown size={20} className={`visible ${shouldHaveWhiteBg ? 'text-darkGreen' : 'text-white'}`} />
          )
    } else {
      return null
    }
  }

  return (
    <div
      className={`enterprise_link-wrapper ${
      shouldHaveWhiteBg
        ? 'bg-white rounded-lg '
        : isExpanded
        ? 'text-white rounded-lg'
        : ''
    }`}
    >
      <Link
        to={link}
        className={`w-full nav-link ${
          isActive && !isSubMenu ? 'rounded-lg bg-white text-white' : ''
        }`}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end'
          }}
        >
          <div className='enterprise-link-container'>
            {renderIcon()}

            <h1
              className={`font-medium ${
                  shouldHaveWhiteBg
                    ? 'text-darkgreen font-semibold'
                    : isExpanded
                    ? 'text-white font-semibold'
                    : isActive && !isSubMenu
                    ? 'text-darkgreen font-semibold'
                    : isActive && isSubMenu
                    ? 'text-secondary w-full h-[45px] flex justify-start items-center rounded-lg text-left font-semibold'
                    : isSubMenu
                    ? 'text-[#AAAAAA] font-semibold '
                    : 'text-white'
                }`}
            >
              {name}
            </h1>
          </div>
          <div className='m-auto'>{renderDropdownArrow()}</div>
        </div>
      </Link>
    </div>
  )
}

export default SideBar
