import React, { useEffect, useState } from 'react'
import { ChevronDown, Menu, X } from 'lucide-react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import useWindowSize from '../../../farmapp/components/EnterpriseApp/UseWindowSize/UseWindowSize'

const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const location = useLocation()
  const { width } = useWindowSize()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1))
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location])

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }
  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)
  const toggleDropdownClick = () => setIsClicked(!isClicked)

  return (
    <div className='lg:py-[28px] px-[5%] py-3 w-full justify-between flex flex-row items-center '>
      <div className='flex flex-row gap-16 items-center z-[50]'>
        <div className='w-[200px]'>
          {location.pathname === '/products' || location.pathname === '/getintouch'
            ? (
              <img
                src={require('../../assets/blacklogo.png')}
                alt='Product Logo'
                className='h-[18px] lg:h-[25px]'
              />
              )
            : <img
                src={require('../../assets/logo-white.png')}
                alt='Product Logo'
                className='h-[18px] lg:h-[25px]'
              />}
        </div>
        <div className='hidden lg:flex items-center gap-7 '>
          <div className='cursor-pointer'>
            <NavLink
              to='/'
              className={({ isActive }) =>
                isActive
                  ? 'text-[#ADEE68] font-[600] text-[16px] hover:text-[16px] underline'
                  : location.pathname === '/products' || location.pathname === '/getintouch'
                    ? 'font-[400] text-[16px] text-black'
                    : 'font-[400] text-[16px] text-[#DDDDDD]'}
            >
              <p>Home</p>
            </NavLink>
          </div>
          {/* plans drop */}
          <div
            className='cursor-pointer relative'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={toggleDropdownClick}
          >
            <div
              className={`text-[#DDDDDD] font-[400] text-[16px] gap-1 center ${
    location.pathname === '/products'
      ? 'text-darkGreen underline font-[600]'
      : location.pathname === '/getintouch'
      ? 'text-black'
      : 'text-[#dddddd]'
  }`}
            >
              <p>Plans</p>
              <ChevronDown size={20} />
            </div>

            {(isHovered || isClicked) && (
              <div className='w-[224px] absolute top-full left-0 pt-3'>
                <div
                  className='  bg-[#F9F9F9] overflow-hidden rounded-lg shadow-lg w-full text-black z-[100]'
                  style={{ pointerEvents: 'auto' }}
                >
                  <h1
                    onClick={() => navigate('/products#basic-plan')}
                    className='block px-4 py-4 h-[50px] center text-[#444444] font-[500] text-[15px] hover:bg-[#EEEEEE]'
                  >
                    Basic Plan
                  </h1>
                  <h1
                    onClick={() => navigate('/products#pro-plan')}
                    className='block px-4 py-4 center h-[50px] text-[#444444] font-[500] text-[15px] hover:bg-[#EEEEEE]'
                  >
                    Pro Plan
                  </h1>
                  <h1
                    onClick={() => navigate('/products#enterprise-plan')}
                    className='block px-4 py-4 center h-[50px] text-[#444444] font-[500] text-[15px] hover:bg-[#EEEEEE]'
                  >
                    Enterprise Plan
                  </h1>
                </div>
              </div>
            )}

          </div>
          <div className='cursor-pointer'>
            <NavLink
              to='/about'
              className={({ isActive }) =>
                isActive && location.pathname.includes('/about')
                  ? 'text-[#ADEE68] font-[600] text-[16px]  underline'
                  : location.pathname.includes('/products') || location.pathname === '/getintouch'
                    ? 'font-[400] text-[16px] text-darkGreen'
                    : 'font-[400] text-[16px] text-[#DDDDDD]'}
            >
              <p>Our Story</p>
            </NavLink>

          </div>
          <div className='cursor-pointer'>
            <NavLink
              to='/getintouch'
              className={({ isActive }) =>
                isActive && location.pathname.includes('/getintouch')
                  ? 'text-darkGreen font-[600] text-[16px]  underline'
                  : location.pathname.includes('/products')
                    ? 'font-[400] text-[16px] text-darkGreen'
                    : 'font-[400] text-[16px] text-[#DDDDDD]'}
            >
              <p>Get in Touch</p>
            </NavLink>

          </div>
        </div>
      </div>
      <div className='hidden lg:flex flex-row gap-5 items-center z-[100]'>
        <Link to='/login'>
          <div className='border border-[#DDDDDD] py-2 px-6 rounded-[8px] cursor-pointer'>
            <p
              className={`font-[500] text-[14px] ${
            location.pathname === '/products' || location.pathname === '/getintouch' ? 'text-black' : 'text-white'
          }`}
            >
              Log in
            </p>
          </div>
        </Link>
        <Link to='/signup'>
          <div className='py-[10px] px-6 bg-primary rounded-md cursor-pointer'>
            <p className='font-[500] text-[#232221] text-[14px]'>
              Create an Account
            </p>
          </div>
        </Link>
      </div>

      {width < 1024 && (
        <div className='z-[100]' onClick={toggleDropdown}>
          <Menu
            className={
        location.pathname === '/products' || location.pathname === '/getintouch'
          ? 'text-black w-[24px] h-[24px]'
          : 'text-white w-[24px] h-[24px]'
      }
          />

          {/* Dropdown with animation */}
          <div
            className={`absolute inset-0 w-full h-screen bg-white px-[3%] py-[5%] transform transition-transform duration-300 ease-in ${
        dropdownVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
      }`}
          >
            <div className='w-full flex justify-between items-center'>
              <img
                src={require('../../assets/blacklogo.png')}
                alt='Product Logo'
                className='h-[16px] lg:h-[25px]'
              />
              <X className='text-black w-[24px] h-[24px]' onClick={toggleDropdown} />
            </div>
            <div className='w-full flex flex-col justify-center items-center gap-6 pt-[25%] text-[15px] text-darkGreen'>
              {/* NavLink for Home */}
              <NavLink
                to='/'
                className={({ isActive }) =>
                  isActive ? 'text-darkGreen font-[600]' : 'text-[#444444]'}
              >
                <h1>Home</h1>
              </NavLink>

              {/* NavLink for About */}
              <NavLink
                to='/about'
                className={({ isActive }) =>
                  isActive ? 'text-darkGreen font-[600]' : 'text-[#444444]'}
              >
                <h1>About</h1>
              </NavLink>

              {/* NavLink for Products */}
              <NavLink
                to='/products'
                className={({ isActive }) =>
                  isActive ? 'text-darkGreen font-[600]' : 'text-[#444444]'}
              >
                <h1>Products</h1>
              </NavLink>

              <NavLink to='/login'>
                <h1>Login</h1>
              </NavLink>

              <NavLink to='/signup' className='w-full'>
                <h1 className='w-full bg-[#ADEE68] text-darkGreen text-[14px] rounded-lg h-[48px] center font-[500]'>
                  Create Account
                </h1>
              </NavLink>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

export default Header
