import React from 'react'
import { useNavigate } from 'react-router-dom'
import { capitalizeWords } from '../../../../../config/services/capitalize'

const ActionCell = ({ cellId }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/admin-tips/${cellId}`)
  }

  return (
    <p className='font-[500] ts cursor-pointer' onClick={handleClick}>
      View
    </p>
  )
}

export const columns = [
  {
    accessorKey: 'title',
    header: 'Title',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'created_by_name',
    header: 'Created by',
    cell: context => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'created_at',
    header: 'Created at',
    cell: context => {
      const date = new Date(context.getValue())
      return <span className='ts'>{date.toLocaleDateString()}</span>
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      let color
      let text

      switch (value) {
        case 'approved':
          color = 'green'
          text = 'Approved'
          break
        case 'rejected':
          color = 'red'
          text = 'Rejected'
          break
        case 'pending':
          color = 'orange'
          text = 'Pending'
          break
        default: // fallback color
          color = 'gray'
          text = 'Unknown' // fallback text
      }

      return (
        <span style={{ color }} className='capitalize ts'>
          {capitalizeWords(text)}
        </span>
      )
    }
  },
  {
    accessorKey: 'action',
    header: 'Action',
    enableHiding: false,
    cell: context => <ActionCell cellId={context.row.original.id} />
  }
]
