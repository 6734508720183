import React, { useEffect, useState } from 'react'
import NewsDataTable from '../News/NewsDataTable/NewsDataTable'
import { Search } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import AddNewButton from '../../SharedComponents/AddNewButton/AddNewButton'
import useBackendService from '../../../../config/services/backend-service'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'

const AdminNews = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [data, setData] = useState([])

  const navigate = useNavigate()

  const { mutate: fetchArticle, isLoading } = useBackendService('/articles', 'get', {
    onSuccess: response => {
      setData(response.data)
    },
    onError: error => {
      console.error('Failed to fetch articles:', error)
    }
  })
  useEffect(() => {
    fetchArticle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    navigate('/admin-news/add-news')
  }

  const filteredData = data.filter(user => {
    const title = `${user.title}`.toLowerCase()
    return title.includes(searchTerm.toLowerCase())
  })
  // Loading state
  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <main className=' bg-white '>
      <div className=' flex flex-row items-center px-5 lg:px-10 lg:py-[30px] justify-between '>
        {data.length === 0
          ? (
            <div />
            )
          : (
            <div className='flex w-[300px] items-center border rounded-lg gap-1 px-3 py-[4px]'>
              <Search size={25} color='#DDDDDD' />
              <input
                className=' bg-transparent appearance-none border-none p-2 placeholder:text-[12px] placeholder:lg:text-[16px] text-[12px] lg:text-[16px]  text-gray-900 placeholder-[#888888] focus:outline-none focus:ring-0'
                type='search'
                placeholder='Search by article title'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
            )}
        <AddNewButton title='Add new article' onClick={handleClick} />
      </div>
      <div className='px-5 lg:px-10'>
        <NewsDataTable data={filteredData} />
      </div>
    </main>
  )
}

export default AdminNews
