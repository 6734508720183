import React from 'react'
import CreatePassword from '../../SharedComponents/CreatePaasword/CreatePassword'
import { useForm } from 'react-hook-form'
import EmailInput from '../../SharedComponents/EmailInput/EmailInput'
import { useAuth } from '../../../../config/contexts/authProvider'
import { useDispatch, useSelector } from 'react-redux'
import { setProUser } from '../../../../config/store/reducers/signUpReducer'

const ProPlanAccountSignUp = ({ next }) => {
  const { proUser } = useSelector(state => state.signUp || {})
  const userEmail = proUser ? proUser.email : ''
  const userPassword = proUser ? proUser.password : ''
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
    trigger
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: userEmail,
      password: userPassword
    }
  })
  const { setSignupField } = useAuth()
  const dispatch = useDispatch()
  const password = watch('password')
  const confirmPassword = watch('confirmpassword')

  const handlePasswordChange = async e => {
    await trigger('password')
  }

  const onSubmit = async data => {
    const emailToSet = data.email || userEmail
    const passwordToSet = data.password || userPassword

    dispatch(setProUser({ email: emailToSet, password: passwordToSet }))
    setSignupField('password', passwordToSet)
    setSignupField('email', emailToSet)

    if (next) next()
  }

  return (
    <>
      <CreatePassword
        password={password}
        confirmPassword={confirmPassword}
        watch={watch}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}
        errors={errors}
        isDirty={isDirty}
        isValid={isValid}
        register={register}
        onSubmit={onSubmit}
        title='Pro Account'
        desc='Kindly fill in your details to setup your account'
        EmailInput={EmailInput}
      />
    </>
  )
}

export default ProPlanAccountSignUp
