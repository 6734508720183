import { ArrowLeft } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import { agriBusinessRoles } from '../../../../data/static'
import EmailInput from '../../../SharedComponents/EmailInput/EmailInput'
import Modal from '../../../SharedComponents/Modal/Modal'
import PasswordInput from '../../../SharedComponents/PasswordInput/PasswordInput'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'

const AddNewStaffPage = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [notAllowed, setNotAllowed] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  const { user } = useAuth()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty }
  } = useForm()

  const { mutate: createStaff, isLoading } = useBackendService('/users', 'post', {
    onSuccess: () => {
      setModal({
        type: 'success',
        title: 'Staff added successfully',
        desc: 'Well done you have successfully submitted a new staff.',
        link: '/staffs',
        visible: true
      })
      setModalOpen(true)
    },
    onError: (error) => {
      const errorMessage =
        error?.response?.data?.message ||
        'You are unable to submit a new staff at the moment.'

      setModal({
        type: 'error',
        title: 'Submission failed',
        desc: errorMessage,
        visible: true
      })
      setModalOpen(true)
    }
  })

  const onSubmit = async (data) => {
    data.plan = 'Enterprise'
    data.farmId = user.farmId
    data.directSupervisor = user.id
    createStaff(data)
  }
  useEffect(() => {
    if (user.role !== 'administrator') {
      setNotAllowed(true)
    }
  }, [user.role])

  if (notAllowed) {
    return (
      <div className='text-center mt-20'>
        <h1 className='text-2xl font-bold text-red-500'>
          You're not allowed to access this page
        </h1>
      </div>
    )
  }

  return (
    <section className='newProductwrapper'>
      <div className='mb-[30px]'>
        <a href='/staffs'>
          <ArrowLeft size={24} />
        </a>
      </div>
      <div className='space-y-0 lg:space-y-2'>
        <h1 className='lg:text-3xl text-xl text-black'>Add new staff</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Fill in the details of your new staff
        </p>
      </div>
      <form className='productform' onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full flex items-center flex-col lg:flex-row gap-[20px]'>
          <TextInput
            name='firstName'
            register={register}
            errors={errors}
            text='First name'
          />
          <TextInput
            name='lastName'
            register={register}
            errors={errors}
            text='Last name'
          />
        </div>
        <div className='w-full flex items-center flex-col lg:flex-row gap-[20px]'>
          <div className='grid w-full items-center gap-1.5'>
            <h1 className='ts'>Phone Number</h1>
            <input
              type='text'
              {...register('phoneNumber', {
                required: 'Phone number is required',
                pattern: {
                  value: /^[0-9]{10,}$/,
                  message: 'Invalid phone number'
                }
              })}
              className='email-input'
            />
            {errors.phoneNumber && (
              <p className='text-red-500 text-xs'>
                {errors.phoneNumber.message}
              </p>
            )}
          </div>
          <EmailInput register={register} errors={errors} />
        </div>
        <div className='w-full lg:w-[50%]'>
          <PasswordInput
            errors={errors}
            register={register}
            setShowPassword={setShowPassword}
            showPassword={showPassword}
            nolink='true'
            disableCheck
            // eslint-disable-next-line react/style-prop-object
            style='ts'
          />
        </div>
        <div className='w-full lg:w-[50%]'>
          <SelectInput
            control={control}
            Controller={Controller}
            data={agriBusinessRoles}
            title='Assign Role'
            name='role'
            placeholder='Select role'
            // eslint-disable-next-line react/style-prop-object
            style='ts'
          />
        </div>

        <div className='w-full lg:w-1/2'>
          <GlobalButton
            isDirty={isDirty}
            isValid={isValid}
            isLoading={isLoading}
            title=' Create new staff'
          />
        </div>
      </form>
      {/* Single Modal with dynamic content */}
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </section>
  )
}

export default AddNewStaffPage
