import {
  ArrowLeft,
  BadgePlus,
  CalendarClock,
  ChevronLeft,
  ChevronRight,
  Search,
  Timer
} from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAuth } from '../../../../config/contexts/authProvider'
import useBackendService from '../../../../config/services/backend-service'
import Modal from '../../SharedComponents/Modal/Modal'
import '../AddNewStockPage/tables.css'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'
import GlobalButton from '../../SharedComponents/GlobalButton/GlobalButton'

const StockDetailsPage = () => {
  const location = useLocation()
  const { stockData } = location.state || {}

  const { id } = useParams()
  const { user } = useAuth()
  const [products, setProducts] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredProducts, setFilteredProducts] = useState([])
  const [quantities, setQuantities] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false
  })
  const [hasChanges, setHasChanges] = useState(false)

  const pageSize = 10
  const [isMostRecent, setIsMostRecent] = useState(false)

  const { mutate: fetchStockDetails, isLoading } = useBackendService(
    `/stock_records/single/${id}/${user.farmId}`,
    'get',
    {
      onSuccess: (response) => {
        const stockRecord = response.data[0] || {}
        setQuantities(stockRecord.stock || {})
        setIsMostRecent(response.data.canEdit || false)
        fetchProducts({ farm_id: user.farmId })
      },
      onError: (error) => {
        console.error('Failed to fetch stock details:', error)
      }
    }
  )

  const { mutate: fetchProducts, isLoading: productLoader } = useBackendService(
    `/products/list_for_farm/${user.farmId}`,
    'get',
    {
      onSuccess: (response) => {
        setProducts(response.data)
        setFilteredProducts(response.data)
      },
      onError: (error) => {
        console.error('Failed to fetch products:', error)
      }
    }
  )

  const { mutate: updateStocks, isLoading: updateLoader } = useBackendService(
    `/stock_records/${id}`,
    'put',
    {
      onSuccess: () => {
        setModal({
          type: 'success',
          title: 'Stock updated successfully',
          desc: 'Well done, you have successfully updated stock.',
          visible: true
        })
        setModalOpen(true)
      },
      onError: (error) => {
        setModal({
          type: 'error',
          title: 'Unable to update stock',
          desc: `${error}`,
          visible: true
        })
        setModalOpen(true)
      }
    }
  )

  useEffect(() => {
    fetchStockDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (id && quantities) {
      const filtered = products.filter(
        (item) => quantities[item.id] !== undefined
      )
      setFilteredProducts(filtered)
    } else {
      const filtered = products.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setFilteredProducts(filtered)
    }
  }, [searchTerm, products, quantities, id])

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return
    setCurrentPage(page)
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    const stockData = filteredProducts
      .filter((item) => quantities[item.id] !== undefined)
      .map((item) => ({
        sku: item.id,
        stock: quantities[item.id]
      }))

    const submittedData = {
      farm: user.farmId,
      stock: stockData
    }
    updateStocks(submittedData)
  }

  const handleQuantityChange = (productId, value) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: value !== '' ? Number(value) : 0
    }))
    setHasChanges(true)
  }

  const renderTables = () => {
    const tables = []
    const startIndex = (currentPage - 1) * 2 * pageSize
    const endIndex = startIndex + 2 * pageSize
    const currentPageData = filteredProducts.slice(startIndex, endIndex)

    const leftSideData = currentPageData.slice(0, pageSize)
    const rightSideData = currentPageData.slice(pageSize)

    tables.push(
      <div className='flex w-full' key={currentPage}>
        <div className='w-1/2 pr-2'>
          {leftSideData.length > 0 && (
            <table className='stock-table'>
              <thead>
                <tr>
                  <td>No</td>
                  <td>Product name</td>
                  <td>Quantity</td>
                </tr>
              </thead>
              <tbody>
                {leftSideData.map((item, index) => (
                  <tr key={item.id}>
                    <td>{startIndex + index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <input
                        type='number'
                        value={quantities[item.id] || 0}
                        onChange={(e) =>
                          handleQuantityChange(item.id, e.target.value)}
                        onFocus={(e) => {
                          if (e.target.value === '0') {
                            e.target.value = ''
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value === '') {
                            handleQuantityChange(item.id, 0)
                          }
                        }}
                        min='0'
                        className='inputborder w-full border-gray-300 p-1'
                        onWheel={(e) => e.target.blur()}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className='w-1/2 pl-2'>
          {rightSideData.length > 0 && (
            <table className='stock-table'>
              <thead>
                <tr>
                  <td>No</td>
                  <td>Product name</td>
                  <td>Quantity</td>
                </tr>
              </thead>
              <tbody>
                {rightSideData.map((item, index) => (
                  <tr key={item.id}>
                    <td>{startIndex + pageSize + index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <input
                        type='number'
                        value={quantities[item.id] || 0}
                        onChange={(e) =>
                          handleQuantityChange(item.id, e.target.value)}
                        onFocus={(e) => {
                          if (e.target.value === '0') {
                            e.target.value = ''
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value === '') {
                            handleQuantityChange(item.id, 0)
                          }
                        }}
                        min='0'
                        className='inputborder w-full border-gray-300 p-1'
                        onWheel={(e) => e.target.blur()}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    )

    return tables
  }

  const totalPages = Math.ceil(filteredProducts.length / (2 * pageSize))

  const { farmName, createdBy, createdAt } = stockData
  const dateObject = new Date(createdAt)

  const date = dateObject.toLocaleDateString()
  const time = dateObject.toLocaleTimeString()
  if (isLoading || productLoader) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <div className='farmwrapper'>
      <a href='/stocks' className='w-full'>
        <ArrowLeft size={20} />
      </a>
      <div className='header-cover'>
        <div className=''>
          <div className='space-y-0 lg:space-y-1'>
            <h1 className='lg:text-3xl text-xl text-black'>
              {farmName || 'Loading...'}
            </h1>
            <div className='text-[#666666] text-xs lg:text-sm center gap-3 '>
              <h1 className='center gap-1'>
                <BadgePlus size={20} /> Created by: {createdBy || 'Loading...'}
              </h1>
              <h1 className='center gap-1'>
                <CalendarClock size={20} />
                Date: {date || 'Loading...'}
              </h1>
              <h1 className='center gap-1'>
                <Timer size={20} />
                Time: {time || 'Loading...'}
              </h1>
            </div>
          </div>
        </div>
        <div className='w-full lg:w-[400px] h-[48px] relative flex items-center'>
          <input
            type='text'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='w-full h-full border-black pl-[10%]'
            placeholder='Search product'
          />
          <Search
            size={20}
            className='absolute left-3 transform -translate-y-1/2'
            style={{ top: '50%' }}
          />
        </div>
      </div>
      <form
        onSubmit={onSubmit}
        className='w-full flex justify-start items-center gap-12 flex-wrap'
      >
        <div className='tables-container w-full'>{renderTables()}</div>
        <div className='w-full flex center gap-3'>
          <div className='w-full items-center flex gap-2'>
            <div className='flex justify-start items-center  gap-1'>
              <div
                className='inline-flex size-8 items-center justify-center h-7 w-7 rounded-full bg-gray-200 border border-darkGreen text-darkGreen  rtl:rotate-180'
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <ChevronLeft size={20} />
              </div>
              <div
                className='inline-flex size-8 ml-3 items-center  h-7 w-7 rounded-full bg-gray-200 justify-center border border-darkGreen text-darkGreen rtl:rotate-180'
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <ChevronRight size={20} />
              </div>
            </div>
            <div className='center text-sm text-black gap-1 hidden lg:flex'>
              <h1>Page</h1>
              <h1 className='number-green'>{currentPage}</h1>
              <h1>of</h1>
              <h1>{totalPages}</h1>
            </div>
          </div>
          <div className='w-full flex justify-end items-center'>
            {isMostRecent && (

              <GlobalButton
                title='Update stock record'
                isFilled={hasChanges}
                isLoading={updateLoader}
              />
            )}

            {modal.visible && (
              <Modal
                type={modal.type}
                title={modal.title}
                desc={modal.desc}
                link={modal.link}
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default StockDetailsPage
