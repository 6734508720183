import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BackButton from '../../SharedComponents/BackButton/BackButton'
import TextInput from '../../SharedComponents/TextInput/TextInput'
import { useForm } from 'react-hook-form'
import useBackendService from '../../../../config/services/backend-service'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'

const BusinessDetailsPage = () => {
  const { id } = useParams()
  const [businessDetail, setBusinessDetail] = useState(null)

  const { mutate: fetchBusinessDetail, isLoading } = useBackendService(
    '/admin/non-activated-enterprise-farms',
    'get',
    {
      onSuccess: (response) => {
        setBusinessDetail(response.data)
      },
      onError: (error) => {
        console.error('Failed to fetch business details:', error)
      }
    }
  )
  const businessWithHasBusinessTrue = businessDetail?.find((item) => item.id === id && item.hasBusiness === true)
  console.log(businessWithHasBusinessTrue)

  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    defaultValues: businessWithHasBusinessTrue || {}
  })

  useEffect(() => {
    fetchBusinessDetail()
  }, [fetchBusinessDetail])

  useEffect(() => {
    if (businessDetail) {
      reset(businessDetail)
    }
  }, [businessDetail, reset])

  const onSubmit = async (data) => {
    console.log(data)
  }
  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <div className='w-full  p-[2%] bg-white'>
      <BackButton />
      <div className='w-full pt-2'>
        <h1 className='text-[18px] font-[500] text-black'>Business Application</h1>
        <h1 className='text-[14px] font-[400] text-[#666666]'>Here is an overview of an applying business with an existing agribusiness</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='w-[80%] space-y-6 mt-5'>
        <div className='w-full flex justify-start items-center gap-16'>
          <div className='w-[50%]'>
            <TextInput
              text='Business name'
              register={register}
              name='businessname'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessTrue?.name}
              disabled
            />
          </div>
          <div className='w-[50%]'>
            {/* yearfounded doesnt exist in the api, would be changed ones its added  */}
            <TextInput
              text='Year Founded'
              register={register}
              name='yearfounded'
              errors={errors}
              type='text'
              dvalue=''
              disabled
            />
          </div>
        </div>
        <div className='w-full flex justify-start items-center gap-16'>
          <div className='w-[50%]'>
            <TextInput
              text='Location'
              register={register}
              name='location'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessTrue?.state}
              disabled
            />
          </div>
          <div className='w-[50%]'>
            <TextInput
              text='Staffs'
              register={register}
              name='staffs'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessTrue?.numberOfStaffs}
              disabled
            />
          </div>
        </div>
        <div className='w-full flex justify-start items-center gap-16'>
          <div className='w-[50%]'>
            <TextInput
              text='Branch'
              register={register}
              name='branch'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessTrue?.numberOfBranches}
              disabled
            />
          </div>
          <div className='w-[50%]'>
            <TextInput
              text='CEO’s Name '
              register={register}
              name='ceoname'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessTrue?.ceoName}
              disabled
            />
          </div>
        </div>
        <div className='w-full flex justify-start items-center gap-16'>
          <div className='w-[50%]'>
            <TextInput
              text='Business’s Email'
              register={register}
              name='email'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessTrue?.ceoEmail}
              disabled
            />
          </div>
          <div className='w-[50%]'>
            <TextInput
              text='CAC Number '
              register={register}
              name='cacnumber'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessTrue?.cacRegistrationNumber}
              disabled
            />
          </div>
        </div>
        <div className='w-[100%]'>
          <TextInput
            text='Type of Business'
            register={register}
            name='businesstype'
            errors={errors}
            type='text'
            dvalue={businessWithHasBusinessTrue?.businessType?.join(', ')}
            disabled
          />
        </div>

        <div className='pt-[3%]'>
          <button
            type='submit'
            className='w-[50%] h-[50px]
              proceed-btn bg-darkGreen
            rounded-[8px] '
          >
            Approve Business
          </button>
        </div>
      </form>
    </div>
  )
}

export default BusinessDetailsPage
