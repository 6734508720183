import React, { useState } from 'react'
import { useAuth } from '../../../../config/contexts/authProvider'
import linkBank from '../../../resources/linkBank.png'
import BackButton from '../../SharedComponents/BackButton/BackButton'
import Modal from '../../SharedComponents/Modal/Modal'
import ModalForUserInvestAccount from './ModalForUserInvestAccount/ModalForUserInvestAccount'

function formatDate (dateString) {
  const date = new Date(dateString)
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  return date.toLocaleDateString('en-GB', options)
}

function InvestInformationContainer ({ item, unlock }) {
  const [isLinkModalOpened, setLinkModalOpened] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { hasAccount } = useAuth()
  const handleInvestNowClick = () => {
    if (hasAccount) {
      setIsModalOpen(true)
    } else {
      setLinkModalOpened(true)
    }
  }
  const length = item.userInvestmentData?.length || 0
  const totalSlots = item.available_slot || 1
  const percentageCompleted = (length / totalSlots) * 100

  return (
    <main className='flex flex-col gap-[15px] lg:gap-[30px] '>
      <BackButton />
      <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 flex-1'>
        <div className='flex flex-col gap-[30px] flex-[0.5]'>
          <div className='lg:w-full rounded-lg overflow-hidden'>
            <img alt='img' src={item.image_url} className='w-full h-full' />
          </div>
          <div className='flex justify-between items-center'>
            <div className='flex flex-col gap-[15px]'>
              <p className='text-[14px] lg:text-[18px] font-[500] text-black'>
                {item.title}
              </p>
              <p className='text-[12px] lg:text-[14px] text-grey font-[600]'>
                {/* By Investor */}
              </p>
            </div>
            <div className='flex flex-col gap-[15px]'>
              <p className='text-[16px] lg:text-[15px] font-[500] text-primary'>
                ₦{item.price}
              </p>
              <p className='text-[12px] lg:text-[14px] text-grey font-[600]'>
                {item.available_slot}units
              </p>
            </div>
          </div>
          <div
            onClick={handleInvestNowClick}
            className='cursor-pointer w-full bg-primary p-3 lg:py-4 rounded-lg lg:px-5 flex items-center justify-center'
          >
            <p className='font-[600] ts'>Invest now</p>
          </div>
          <div className='flex flex-col gap-5 w-full'>
            <p className='text-center font-[600] ts text-grey'>
              There are still some units left to purchase
            </p>
            <div className='w-full rounded-lg bg-[#DDDDDD] h-[10px]'>
              <div
                className='bg-darkGreen h-full'
                style={{ width: `${percentageCompleted}%` }}
              />
            </div>
            <div className='flex self-center justify-center items-center py-3 px-5 rounded-[30px] bg-[#E7ECE8]'>
              <p className='ts font-[400] text-primary'>
                {percentageCompleted.toFixed(2)}% of units available
              </p>
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <p className='font-[500] text-[14px] lg:text-[16px] text-black'>
              About
            </p>
            <p className='text-[#444444] text-[14px] lg:text-[16px] font-[500]'>
              {item.about}
            </p>
          </div>
          <div className='rounded-lg px-5 hidden lg:flex py-[30px] flex-col gap-5 bg-[#fdf4f5]'>
            <p className='text-[#D92534] font-[600] ts'>Notice</p>
            <p className='text-[#D92534] font-[500] ts'>
              T-Gaims does not provide investment advise and individual
              investors should make their own decisions or seek independent
              advice
            </p>
          </div>
        </div>
        <div className='flex flex-col gap-[20px] lg:gap-[50px] flex-[0.5]'>
          <p className='font-[600] text-[14px] lg:text-[18px] text-black'>
            Investment Information
          </p>
          <div className='gap-5 grid grid-cols-2'>
            <div className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'>
              <p className='text-black text-[12px] lg:text-[14px]'>
                Expected returns
              </p>
              <p className='text-primary ts'>
                {/* this is a new feature that needs to be updated from the api */}
                {/* {item.return_type} */}
              </p>
            </div>
            <div className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'>
              <p className='text-black text-[12px] lg:text-[14px]'>
                Investment Type
              </p>
              <p className='text-primary ts'>{item.investment_type}</p>
            </div>
            <div className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'>
              <p className='text-black ts'>Start date</p>
              <p className='text-primary ts'>{formatDate(item.start_date)}</p>
            </div>
            <div className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'>
              <p className='text-black ts'>Offer closing Date</p>
              <p className='text-primary ts'>{formatDate(item.end_date)}</p>
            </div>
            <div className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'>
              <p className='text-black ts'>Maturity date</p>
              <p className='text-primary ts'>{formatDate(item.payout_date)}</p>
            </div>

            <div className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'>
              <p className='text-black ts '>Payout type</p>
              <p className='text-primary ts'>Capital + Profit</p>
            </div>
          </div>
          <div className='flex flex-col flex-1 py-5 bg-darkGreen rounded-lg ts'>
            <div className='pb-[15px] border-b border-b-[#AAAAAA40] px-5'>
              <p className='text-white font-[600] ts'>More information</p>
            </div>
            <div className='flex py-5 flex-col gap-4 pb-[15px] border-b border-b-[#AAAAAA40] px-5'>
              <p className='text-white font-[600] ts'>Repayment plan</p>
              <p className='text-white font-[500] leading-5 ts'>
                {item.repayment_plan}
              </p>
            </div>
            <div className='flex py-5 flex-col gap-4 pb-[15px] leading-5 px-5'>
              <p className='text-white font-[600] ts'>Investment risk</p>
              <p className='text-white font-[500] text-[16px] leading-[36px] lg:text-[18px]'>
                {item.investment_risk}
              </p>
            </div>
          </div>
        </div>
        <div className='rounded-lg px-5 lg:hidden py-[30px] flex border border-[#D92534] flex-col gap-5 bg-[#fdf4f5]'>
          <p className='text-[#D92534] font-[600] ts'>Notice</p>
          <p className='text-[#D92534] font-[500] ts'>
            T-Gaims does not provide investment advise and individual investors
            should make their own decisions or seek independent advice
          </p>
        </div>
      </div>
      {/* modal for user with no account linked */}
      <Modal
        title='Link withdrawal bank'
        desc='This is where the funds from your investments would be sent to once it’s due.'
        welcomeicon={linkBank}
        isOpen={isLinkModalOpened}
        linkOverride='true'
        onClose={() => setLinkModalOpened(false)}
        unlock={unlock}
      />
      {/* modal for user with  account linked */}
      <ModalForUserInvestAccount
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        units={item.available_slot}
        price={item.price}
        id={item.id}
      />
    </main>
  )
}

export default InvestInformationContainer
