import { Plus } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../config/contexts/authProvider'
import useBackendService from '../../../../config/services/backend-service'
import Hurry from '../../../resources/hurry.png'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'
import Modal from '../../SharedComponents/Modal/Modal'
import DashBoardHeader from '../DashBoard/DashBoardHeader/DashBoardHeader'
import CalendarDatatable from './OperationalCalendarDatatable/OperationalCalendarDatatable'

const OperationalCalendar = () => {
  const { firstTime, user } = useAuth()
  const [isModalOpened, setModalOpened] = useState(false)
  const showModal = () => {
    setModalOpened(true) // Open the modal by setting isModalOpen to true
  }

  useEffect(() => {
    if (firstTime) {
      showModal()
    }
  }, [firstTime])

  const [data, setData] = useState([])

  const { mutate: fetchCalendar, isLoading } = useBackendService(
    `/calendar?created_by=${user.id}`,
    'get',
    {
      onSuccess: (response) => {
        setData(response.data)
      },
      onError: (error) => {
        console.log(error)
      }
    }
  )

  useEffect(() => {
    fetchCalendar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCalendar])
  // Loading state
  if (isLoading) {
    return <GlobalLoader />
  }
  return (
    <div className='farmwrapper'>
      <DashBoardHeader
        btntext='Create Calendar'
        link='/operational-calendar/create-operational-calendar'
        icon={<Plus size={20} />}
        parent
        role='administrator'
      />
      <h1 className='w-full ts'>All Calendar</h1>
      <CalendarDatatable data={data} />

      {/* modal for the first user login */}
      <Modal
        title='Welcome to T-Gaims'
        desc='Create your first calendar now and get personalized guidance tailored to your success in your field.'
        welcomeicon={Hurry}
        isOpen={isModalOpened}
        onClose={() => setModalOpened(false)}
      />
    </div>
  )
}

export default OperationalCalendar
