import React from 'react'
import { useForm } from 'react-hook-form'
import EmailInput from '../../SharedComponents/EmailInput/EmailInput'
import CreatePassword from '../../SharedComponents/CreatePaasword/CreatePassword'
import { useAuth } from '../../../../config/contexts/authProvider'
import { useDispatch, useSelector } from 'react-redux'
import { setBasicUser } from '../../../../config/store/reducers/signUpReducer'

const BasicPlanAccountSignUp = ({ next }) => {
  const { basicUser } = useSelector(state => state.signUp || {})
  const userEmail = basicUser ? basicUser.email : ''
  const userPassword = basicUser ? basicUser.password : ''
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
    trigger
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: userEmail,
      password: userPassword
    }
  })

  const { setSignupField } = useAuth()
  const dispatch = useDispatch()

  const password = watch('password')
  const confirmPassword = watch('confirmpassword')

  const handlePasswordChange = async e => {
    await trigger('password')
  }

  // function to submitting the form data
  const onSubmit = async data => {
    const isValid = await trigger(['password', 'confirmpassword', 'email'])
    if (!isValid) return

    const emailToSet = data.email || userEmail
    const passwordToSet = data.password || userPassword

    dispatch(setBasicUser({ email: emailToSet, password: passwordToSet }))

    setSignupField('email', emailToSet)
    setSignupField('password', passwordToSet)

    if (next) next()
  }
  return (
    <>
      <CreatePassword
        password={password}
        confirmPassword={confirmPassword}
        watch={watch}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}
        errors={errors}
        isDirty={isDirty}
        isValid={isValid}
        register={register}
        onSubmit={onSubmit}
        title='Basic Account'
        desc='Kindly fill in your details to setup your account'
        EmailInput={EmailInput}
      />
    </>
  )
}

export default BasicPlanAccountSignUp
