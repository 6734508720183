import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../config/contexts/authProvider'
import useBackendService from '../../../../config/services/backend-service'
import BusinessApplicationsTable from './BusinessApplicationsTable'
import NoneBusinessApplicationsTable from './NoneBusinessApplicationsTable'
import './index.css'
import EmptyDataContent from '../../SharedComponents/EmptyDataContent/EmptyDataContent'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'

function BusinessApplications () {
  const { user } = useAuth()
  const navigate = useNavigate()

  const [data, setData] = useState([])
  const [activeTab, setActiveTab] = useState('hasBusiness')
  console.log('data', data)

  const { mutate: fetchFarms, isLoading } = useBackendService(
    '/admin/non-activated-enterprise-farms',
    'get',
    {
      onSuccess: (response) => {
        setData(response.data)
      },
      onError: (error) => {
        console.error('Failed to fetch applications:', error)
      }
    }
  )

  const { mutate: approveBusinessApplication } = useBackendService(
    '/farms/approve_application',
    'post',
    {
      onSuccess: (response) => {
        toast.success('Application approved')
      },
      onError: (error) => {
        console.error('Failed to approve applications:', error)
      }
    }
  )

  const { mutate: sendContinueMail } = useBackendService(
    '/admin/send-signup-email',
    'post',
    {
      onSuccess: (response) => {
        toast.success('Email sent to applicant')
        navigate(0)
      },
      onError: (error) => {
        console.error('Failed to send continuation mail:', error)
      }
    }
  )

  const approveApplication = (rowData) => {
    approveBusinessApplication({ userId: user.id, applicationId: rowData.id })
    sendContinueMail({ email: rowData.email })
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  useEffect(() => {
    fetchFarms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredData =
    activeTab === 'hasBusiness'
      ? data.filter((item) => item.hasBusiness)
      : data.filter((item) => !item.hasBusiness)

  const counter = filteredData.length
  // Loading state
  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <div className='farmwrapper'>
      <div className='w-full flex justify-start items-center gap-2 text-center text-gray-500 '>
        <div className=' cursor-pointer'>
          <div
            className={`flex justify-center p-3 items-center gap-2 ts ${
              activeTab === 'hasBusiness'
                ? 'bg-darkGreen rounded-lg shadow text-white'
                : ''
            }`}
            onClick={() => handleTabChange('hasBusiness')}
          >
            With Business <div className={`rounded-full ${activeTab === 'hasBusiness' ? 'bg-white text-darkGreen w-6 h-6 center' : 'bg-darkGreen text-white w-6 h-6 center'}`}>{data.filter((item) => item.hasBusiness).length}</div>
          </div>
        </div>
        <div className=' cursor-pointer'>
          <div
            className={`flex justify-center items-center gap-2 p-3 ts ${
              activeTab === 'noBusiness'
              ? 'bg-darkGreen rounded-lg shadow text-white'
                : ''
            }`}
            onClick={() => handleTabChange('noBusiness')}
          >
            Without Business <div className={`rounded-full ${activeTab === 'noBusiness' ? 'bg-white text-darkGreen w-6 h-6 center' : 'bg-darkGreen text-white w-6 h-6 center'}`}>{data.filter((item) => !item.hasBusiness).length}</div>
          </div>
        </div>
      </div>

      <div className='tab-content mt-4'>
        {activeTab === 'hasBusiness'
          ? (
              counter > 0
                ? (
                  <BusinessApplicationsTable
                    data={filteredData}
                    approveApplication={approveApplication}
                  />
                  )
                : (
                  <div className='flex flex-col items-center justify-center w-full '>
                    <EmptyDataContent
                      image={require('../../../resources/farmemptyimage.png')}
                      text='No application yet'
                      desc='Once an enterprise application has been sent, you will see it here'
                    />
                  </div>
                  )
            )
          : counter > 0
            ? (
              <NoneBusinessApplicationsTable
                data={filteredData}
                approveApplication={approveApplication}
              />
              )
            : (
              <div className='flex flex-col items-center justify-center w-full '>
                <EmptyDataContent
                  image={require('../../../resources/farmemptyimage.png')}
                  text='No application yet'
                  desc='Once an enterprise application has been sent, you will see it here'
                />
              </div>
              )}
      </div>
    </div>
  )
}

export default BusinessApplications
