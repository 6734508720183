import React from 'react'
import DataTable from '../../SharedComponents/DataTable/DataTable'
import EmptyDataContent from '../../SharedComponents/EmptyDataContent/EmptyDataContent'
import Pagination from '../../SharedComponents/Pagination/Pagination'
import { useNavigate } from 'react-router-dom'

function NoneBusinessApplicationsTable ({ data }) {
  const navigate = useNavigate()

  const handleViewDetails = (id) => {
    navigate(`/admin-business-applications/admin-nobusiness-details/${id}`)
  }

  const columns = [
    {
      accessorKey: 'email',
      header: 'Email',
      cell: (context) => {
        const value = context.getValue()
        return <div className='ts'>{value.toLowerCase()}</div>
      }
    },

    {
      accessorKey: 'enterpriseBusinessType',
      header: 'Business Type',
      cell: (context) => {
        const value = context.getValue()
        return <div className='ts capitalize'>{value}</div>
      }
    },
    {
      accessorKey: 'action',
      header: 'Action',
      enableHiding: false,
      cell: ({ row }) => (
        <div className='text-darkGreen underline font-[400]' onClick={() => handleViewDetails(row.original.id)}>
          View details
        </div>
      )
    }
  ]

  return (
    <div>
      <DataTable
        data={data}
        columns={columns}
        filter={false}
        pagination={data.length !== 0 && <Pagination />}
        emptydatacontent={
          <EmptyDataContent
            image={require('../../../resources/farmemptyimage.png')}
            text='No applications yet'
            desc=''
          />
        }
      />
    </div>
  )
}

export default NoneBusinessApplicationsTable
