import React, { useEffect, useState } from 'react'
import AddNewButton from '../../SharedComponents/AddNewButton/AddNewButton'
import { Search } from 'lucide-react'
import UsersDataTable from './UsersDataTable/UsersDataTable'
import { useNavigate } from 'react-router-dom'
import useBackendService from '../../../../config/services/backend-service'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'

function AdminUsers () {
  const [searchTerm, setSearchTerm] = useState('')
  const [data, setData] = useState([])

  const navigate = useNavigate()

  const { mutate: fetchAdmins, isLoading } = useBackendService(
    '/users?farm_id=NULL',
    'get',
    {
      onSuccess: response => {
        setData(response.data)
      },
      onError: error => {
        console.error('Failed to fetch users:', error)
      }
    }
  )

  useEffect(() => {
    fetchAdmins({ is_admin: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddNewClick = () => {
    navigate('/admin-users/add-user')
  }

  const filteredData = data.filter(user => {
    const fullName = `${user.first_name} ${user.last_name}`.toLowerCase()
    return fullName.includes(searchTerm.toLowerCase())
  })
  // Loading state
  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <main className=' bg-white '>
      <div className=' flex flex-row items-center p-4 lg:px-10 lg:py-[30px] justify-between '>
        <div className='flex w-[200px] lg:w-[300px] items-center border rounded-lg lg:gap-1 p-1.5 lg:px-3'>
          <Search size={20} color='#DDDDDD' />
          <input
            className=' bg-transparent appearance-none border-none lg:p-2 placeholder:text-[10px] placeholder:lg:text-[16px] text-[12px] lg:text-[16px]  text-gray-900 placeholder-[#888888] focus:outline-none focus:ring-0'
            type='search'
            placeholder='Search users by name'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <AddNewButton title='Add new users' onClick={handleAddNewClick} />
      </div>
      <div className=' p-4 lg:px-10  '>
        <UsersDataTable data={filteredData} />
      </div>
    </main>
  )
}

export default AdminUsers
