import { X } from 'lucide-react'
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameMonth,
  isToday,
  parse
} from 'date-fns'
import { useState } from 'react'

const colStartClasses = [
  '', // Sunday
  'col-start-2', // Monday
  'col-start-3', // Tuesday
  'col-start-4', // Wednesday
  'col-start-5', // Thursday
  'col-start-6', // Friday
  'col-start-7' // Saturday
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

// Months array
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
]

// Generate an array of years (e.g., 10 years before and after the current year)
const years = Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - 10 + i)

export default function OperationalCalendarSelector ({ selectedDay, setSelectedDay, today, onSave, id = 'calendar', nofuture }) {
  const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
  const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())
  const days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth)
  })

  const selectedYear = format(firstDayCurrentMonth, 'yyyy')
  const selectedMonthIndex = format(firstDayCurrentMonth, 'M') - 1

  // Handle month change from dropdown
  function handleMonthChange (e) {
    const newMonth = e.target.value
    setCurrentMonth(format(new Date(selectedYear, newMonth, 1), 'MMM-yyyy'))
  }

  // Handle year change from dropdown
  function handleYearChange (e) {
    const newYear = e.target.value
    setCurrentMonth(format(new Date(newYear, selectedMonthIndex, 1), 'MMM-yyyy'))
  }

  return (
    <dialog id={id} className='absolute top-[20%] w-[90%] md:w-[60%] lg:w-[40%] rounded-lg m-auto'>
      <form method='dialog' className='w-full bg-white p-6 rounded-lg'>
        {/* Dropdown section for month and year */}
        <div className='w-full flex justify-between items-center px-6'>
          <div className='w-full gap-2 flex justify-start items-start'>
            <select
              value={selectedMonthIndex}
              onChange={handleMonthChange}
              className='border-none ts outline-none'
            >
              {months.map((month, index) => (
                <option key={index} value={index}>
                  {month}
                </option>
              ))}
            </select>
            <select
              value={selectedYear}
              onChange={handleYearChange}
              className='border-none ts outline-none'
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <X
            size={20}
            className='cursor-pointer'
            onClick={() => document.getElementById(`${id}`).close()}
          />
        </div>

        {/* Calendar Grid */}
        <div className='grid grid-cols-7 mt-6 text-xs leading-6 text-center text-black font-semibold'>
          <div>SUN</div>
          <div>MON</div>
          <div>TUE</div>
          <div>WED</div>
          <div>THU</div>
          <div>FRI</div>
          <div>SAT</div>
        </div>
        <div className='grid grid-cols-7 mt-2 text-sm'>
          {days.map((day, dayIdx) => (
            <div
              key={day.toString()}
              className={classNames(
                dayIdx === 0 && colStartClasses[getDay(day)],
                'py-1.5'
              )}
            >
              <button
                type='button'
                onClick={() => setSelectedDay(day)}
                disabled={nofuture === 'true' && day > today} // Disable future dates if nofuture is true
                className={classNames(
                  isEqual(day, selectedDay) && 'text-white',
                  !isEqual(day, selectedDay) &&
                  isToday(day) &&
                  'text-red-500',
                  !isEqual(day, selectedDay) &&
                  !isToday(day) &&
                  isSameMonth(day, firstDayCurrentMonth) &&
                  'text-gray-900',
                  !isEqual(day, selectedDay) &&
                  !isToday(day) &&
                  !isSameMonth(day, firstDayCurrentMonth) &&
                  'text-gray-400',
                  isEqual(day, selectedDay) && isToday(day) && 'bg-red-500',
                  isEqual(day, selectedDay) && !isToday(day) && 'bg-darkGreen',
                  !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                  (isEqual(day, selectedDay) || isToday(day)) && 'font-semibold',
                  'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
                  nofuture === 'true' && day > today && 'opacity-50 cursor-not-allowed' // Style disabled future dates
                )}
              >
                <time dateTime={format(day, 'yyyy-MM-dd')}>
                  {format(day, 'd')}
                </time>
              </button>
            </div>
          ))}
        </div>

        {/* Buttons */}
        <div className='w-full flex gap-2 mt-3'>
          <button
            type='button'
            className='w-[50%] h-[50px] bg-[#DDDDDD] rounded-md text-black ts'
            onClick={() => {
              setSelectedDay(undefined)
              document.getElementById(`${id}`).close()
            }}
          >
            Cancel
          </button>

          <button
            type='button'
            className='w-[50%] h-[50px] bg-darkGreen rounded-md text-white ts'
            onClick={() => {
              onSave()
              document.getElementById(`${id}`).close()
            }}
          >
            Save
          </button>
        </div>
      </form>
    </dialog>
  )
}
