import { Plus } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import useBackendService from '../../../../config/services/backend-service'
import Lock from '../../../resources/greenlock.png'
import { useAuth } from '../.././../../config/contexts/authProvider'
import Modal from '../../SharedComponents/Modal/Modal'
import DashBoardHeader from '../DashBoard/DashBoardHeader/DashBoardHeader'
import './index.css'
import InventoryCount from './InventoryCount/InventoryCount'
import ProductDataTable from './ProductDataTable/ProductDataTable'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'
const ProductsPage = () => {
  const [data, setData] = useState([])
  const [isModalOpened, setModalOpened] = useState(false)
  const { user, isParentFarm } = useAuth()

  const { mutate: fetchProducts, isLoading } = useBackendService(
    `/products/list_for_farm/${user.farmId}`,
    'get',
    {
      onSuccess: (response) => {
        setData(response.data)
      },
      onError: (error) => {
        console.error('Failed to fetch products:', error)
      }
    }
  )

  useEffect(() => {
    if (!user.farmId) {
      setModalOpened(true)
    } else {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  // Loading state
  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <section className='w-full p-[3%] bg-white space-y-5'>
      <div className='w-full flex flex-col-reverse lg:flex-col justify-center items-center gap-2'>
        <DashBoardHeader
          btntext='Add new product'
          link='/farmproducts/new-Product'
          icon={<Plus size={20} />}
          parent={isParentFarm}
          role={user.role}
        />
        <InventoryCount data={data} />
      </div>
      <ProductDataTable data={data} />
      <Modal
        title='Create a business to unlock management tools'
        desc='Set up your agri-business to access all the tools you need to manage, track and grow with ease.'
        welcomeicon={Lock}
        isOpen={isModalOpened}
        linkOverride='true'
        onClose={() => setModalOpened(false)}
      />
    </section>
  )
}

export default ProductsPage
