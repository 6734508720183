import { ArrowLeft, ChevronLeft, ChevronRight, Search } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../../../config/contexts/authProvider'
import useBackendService from '../../../../config/services/backend-service'
import Modal from '../../SharedComponents/Modal/Modal'
import './tables.css'
import GlobalButton from '../../SharedComponents/GlobalButton/GlobalButton'

const AddNewStockPage = () => {
  const { user } = useAuth()
  const [data, setData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState(data)
  const [quantities, setQuantities] = useState({})
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false
  })
  const [currentPage, setCurrentPage] = useState(1)

  const pageSize = 10

  const { handleSubmit } = useForm()

  const { mutate: fetchStockRecords } = useBackendService(
    `/stock_records/stock_balance/${user.farmId}`,
    'get',
    {
      onSuccess: (response) => {
        setData(response.data)
        setQuantities(
          response.data.reduce(
            (acc, item) => ({ ...acc, [item.product_id]: item.quantity }),
            {}
          )
        )
      },
      onError: (error) => {}
    }
  )

  const { mutate: createStocks, isLoading } = useBackendService('/stock_records', 'post', {
    onSuccess: (response) => {
      setModal({
        type: 'success',
        title: 'Stock report submitted',
        desc: 'Well done, you have successfully submitted a new stock report.',
        link: '/stocks',
        visible: true
      })
      setModalOpen(true)
      setQuantities(
        filteredData.reduce(
          (acc, item) => ({ ...acc, [item.product_id]: 0 }),
          {}
        )
      )
    },
    onError: (error) => {
      setModal({
        type: 'error',
        title: 'Stock report failed',
        desc: 'You are unable to submit a stock report at the moment.',
        visible: true
      })
      setModalOpen(true)
    }
  })

  useEffect(() => {
    fetchStockRecords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filtered = data.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredData(filtered)
  }, [searchTerm, data])

  const handleQuantityChange = (productId, value) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: value !== '' ? Number(value) : 0
    }))
  }

  const handlePageChange = (page) => {
    if (page < 1) {
      setCurrentPage(1)
    } else if (page > totalPages) {
      setCurrentPage(totalPages)
    } else {
      setCurrentPage(page)
    }
  }

  const onSubmit = async () => {
    const stockData = data
      .filter((item) => quantities[item.product_id] > 0)
      .map((item) => ({
        sku: item.product_id,
        stock: quantities[item.product_id]
      }))

    const submittedData = {
      farm: user.farmId,
      stock: stockData
    }
    createStocks(submittedData)
  }

  const renderTables = () => {
    const tables = []
    const startIndex = (currentPage - 1) * 2 * pageSize
    const endIndex = startIndex + 2 * pageSize
    const currentPageData = filteredData.slice(startIndex, endIndex)

    const leftSideData = currentPageData.slice(0, pageSize)
    const rightSideData = currentPageData.slice(pageSize)

    tables.push(
      <div
        className='flex w-full flex-col lg:flex-row justify-center items-center'
        key={currentPage}
      >
        <div className='lg:w-1/2 w-full lg:pr-2'>
          {leftSideData.length > 0 && (
            <table className='stock-table w-full'>
              <thead>
                <tr>
                  <td>No</td>
                  <td>Product name</td>
                  <td>Quantity</td>
                </tr>
              </thead>
              <tbody>
                {leftSideData.map((item, index) => (
                  <tr key={item.product_id}>
                    <td>{startIndex + index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <input
                        type='number'
                        value={quantities[item.product_id] || 0}
                        onChange={(e) =>
                          handleQuantityChange(item.product_id, e.target.value)}
                        onFocus={(e) => {
                          if (e.target.value === '0') {
                            e.target.value = ''
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value === '') {
                            handleQuantityChange(item.product_id, 0)
                          }
                        }}
                        min='0'
                        className='inputborder w-full border-gray-300 p-1'
                        onWheel={(e) => e.target.blur()}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className='lg:w-1/2 w-full lg:pl-2'>
          {rightSideData.length > 0 && (
            <table className='stock-table w-full'>
              <thead>
                <tr>
                  <td>No</td>
                  <td>Product name</td>
                  <td>Quantity</td>
                </tr>
              </thead>
              <tbody>
                {rightSideData.map((item, index) => (
                  <tr key={item.product_id}>
                    <td>{startIndex + pageSize + index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <input
                        type='number'
                        value={quantities[item.product_id] || 0}
                        onChange={(e) =>
                          handleQuantityChange(item.product_id, e.target.value)}
                        onFocus={(e) => {
                          if (e.target.value === '0') {
                            e.target.value = ''
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value === '') {
                            handleQuantityChange(item.product_id, 0)
                          }
                        }}
                        min='0'
                        className='inputborder w-full border-gray-300 p-1'
                        onWheel={(e) => e.target.blur()}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    )

    return tables
  }

  const hasNonZeroQuantity = Object.values(quantities).some(
    (quantity) => quantity > 0
  )

  const totalPages = Math.ceil(filteredData.length / (2 * pageSize))

  return (
    <div className='farmwrapper'>
      <a href='/stocks' className='w-full'>
        <ArrowLeft size={20} />
      </a>
      <div className='header-cover'>
        <div className='space-y-0 lg:space-y-1'>
          <h1 className='lg:text-3xl text-xl text-black'>
            Add new stock report
          </h1>
          <p className='text-[#666666] text-sm lg:text-sm'>
            Fill in the details below to add a new stock report
          </p>
        </div>
        <div className='w-full lg:w-[400px] h-[48px] relative flex items-center'>
          <input
            type='text'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='w-full h-full border-black pl-[10%]'
            placeholder='Search product'
          />
          <Search
            size={20}
            className='absolute left-3 transform -translate-y-1/2'
            style={{ top: '50%' }}
          />
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='w-full flex justify-start items-center gap-12 flex-wrap'
      >
        <div className='tables-container w-full'>{renderTables()}</div>
        {/* desktop pagination */}
        <div className='w-full gap-3 hidden lg:flex'>
          {/* pagination for desktop devices */}
          <div className='w-full items-center flex gap-2'>
            <div className='flex justify-start items-center  gap-1'>
              <div
                className='inline-flex size-8 items-center justify-center h-7 w-7 rounded-full bg-gray-200 border border-darkGreen text-darkGreen  rtl:rotate-180'
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <ChevronLeft size={20} />
              </div>
              <div
                className='inline-flex size-8 ml-3 items-center  h-7 w-7 rounded-full bg-gray-200 justify-center border border-darkGreen text-darkGreen rtl:rotate-180'
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <ChevronRight size={20} />
              </div>
            </div>
            <div className='center text-sm text-black gap-1 hidden lg:flex'>
              <h1>Page</h1>
              <h1 className='number-green'>{currentPage}</h1>
              <h1>of</h1>
              <h1>{totalPages}</h1>
            </div>
          </div>
        </div>
        <div className='w-full flex justify-end items-center'>
          {hasNonZeroQuantity
            ? (

              <div className='w-full lg:w-[35%]'>
                <GlobalButton
                  title='Save stock record'
                  isLoading={isLoading}
                  isFilled
                />
              </div>

              )
            : (
              <div
                className='h-[50px] rounded-lg center border-darkGreen text-black w-full lg:w-[35%]'
                style={{ backgroundColor: '#dfdddd' }}
              >
                Save stock record
              </div>
              )}
        </div>
      </form>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default AddNewStockPage
