import { capitalizeWords } from '../../../../../config/services/capitalize'

export const columns = [
  {
    accessorKey: 'name',
    header: 'Farm',
    cell: (context) => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'owner',
    header: 'Owner',
    cell: (context) => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  },
  {
    accessorKey: 'address',
    header: 'Location',
    cell: (context) => (
      <span className='ts'>{capitalizeWords(context.getValue())}</span>
    )
  }
]
