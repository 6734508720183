import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BackButton from '../../SharedComponents/BackButton/BackButton'
import { useForm } from 'react-hook-form'
import TextInput from '../../SharedComponents/TextInput/TextInput'
import useBackendService from '../../../../config/services/backend-service'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'

const NoBusinessDetailsPage = () => {
  const { id } = useParams()
  const [noBusinessDetail, setNoBusinessDetail] = useState(null)

  const { mutate: fetchNoBusinessDetails, isLoading } = useBackendService(
    '/admin/non-activated-enterprise-farms',
    'get',
    {
      onSuccess: (response) => {
        setNoBusinessDetail(response.data)
      },
      onError: (error) => {
        console.error('Failed to fetch no-business details:', error)
      }
    }
  )
  const businessWithHasBusinessFalse = noBusinessDetail?.find((item) => item.id === id && item.hasBusiness === false)
  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    defaultValues: businessWithHasBusinessFalse || {}
  })

  useEffect(() => {
    fetchNoBusinessDetails()
  }, [fetchNoBusinessDetails])

  useEffect(() => {
    if (noBusinessDetail) {
      reset(noBusinessDetail)
    }
  }, [noBusinessDetail, reset])
  const formatCurrency = (value) => {
    if (!value) return ''
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 0
    }).format(value)
  }

  const onSubmit = async (data) => {
    console.log(data)
  }
  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <div className='w-full p-[2%] bg-white'>
      <BackButton />
      <div className='w-full pt-2'>
        <h1 className='text-[18px] font-[500] text-black'>Business Application</h1>
        <h1 className='text-[14px] font-[400] text-[#666666]'>
          Here is an overview of an applying business without an existing agribusiness
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='w-[80%] space-y-6 mt-5'>
        <div className='w-full flex justify-start items-center gap-16'>
          <div className='w-[50%]'>
            <TextInput
              text='Agribusiness type they would you like to venture into'
              register={register}
              name='agribusinesstype'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessFalse?.enterpriseBusinessType}
              disabled
            />
          </div>
          <div className='w-[50%]'>
            <TextInput
              text='What they are currently involved in'
              register={register}
              name='currentlyinvolved'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessFalse?.enterpriseVenture}
              disabled
            />
          </div>
        </div>
        <div className='w-full flex justify-start items-center gap-16'>
          <div className='w-[50%]'>
            <TextInput
              text='Preferred Location'
              register={register}
              name='location'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessFalse?.enterpriseLocationChoice}
              disabled
            />
          </div>
          <div className='w-[50%]'>
            <TextInput
              text='Financial Budget?'
              register={register}
              name='budget'
              errors={errors}
              type='text'
              dvalue={formatCurrency(businessWithHasBusinessFalse?.enterpriseBudget)}
              disabled
            />
          </div>
        </div>
        <div className='w-full flex justify-start items-center gap-16'>
          <div className='w-[50%]'>
            <TextInput
              text='Existing land for agribusiness?'
              register={register}
              name='existingland'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessFalse?.existingLand}
              disabled
            />
          </div>
          <div className='w-[50%]'>
            <TextInput
              text='What is the size of the land?'
              register={register}
              name='landsize'
              errors={errors}
              type='number'
              dvalue={businessWithHasBusinessFalse?.landSize}
              disabled
            />
          </div>
        </div>
        <div className='w-full flex justify-start items-center gap-16'>
          <div className='w-[50%]'>
            <TextInput
              text='Available agricultural resources or equipment?'
              register={register}
              name='availabeequipment'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessFalse?.agriculturalResources}
              disabled
            />
          </div>
          <div className='w-[50%]'>
            <TextInput
              text='List of the available resources or equipment'
              register={register}
              name='listofavailableresources'
              errors={errors}
              type='text'
              dvalue={businessWithHasBusinessFalse?.resource_list}
              disabled
            />
          </div>
        </div>
        <div className='w-[100%]'>
          <TextInput
            text='Additional information'
            register={register}
            name='additionalInformation'
            errors={errors}
            type='text'
            dvalue={businessWithHasBusinessFalse?.additionalInformation}
            disabled
          />
        </div>

        <div className='pt-[3%]'>
          <button
            type='submit'
            className='w-[50%] h-[50px] proceed-btn bg-darkGreen rounded-[8px]'
          >
            Approve Business
          </button>
        </div>
      </form>
    </div>
  )
}

export default NoBusinessDetailsPage
