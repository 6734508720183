import React, { useEffect, useState } from 'react'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import { Controller, useForm } from 'react-hook-form'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import useBackendService from '../../../../../config/services/backend-service'
import { useAuth } from '../../../../../config/contexts/authProvider'
import Modal from '../../../SharedComponents/Modal/Modal'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'

function AddReport () {
  const [assignees, setAssignees] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  const { user } = useAuth()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty }
  } = useForm()

  const { mutate: fetchUsersBy } = useBackendService(
    `/farms/list_related_users/${user.farmId}`,
    'get',
    {
      onSuccess: response => {
        setAssignees(response.data)
      },
      onError: error => {
        console.log('Error fetching users by parent farm:', error)
      }
    }
  )

  useEffect(() => {
    fetchUsersBy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { mutate: submitRequest, isLoading } = useBackendService('/operations', 'post', {
    onSuccess: () => {
      setModal({
        type: 'success',
        title: 'Report added successfully',
        desc: 'Well done you have successfully added a new report.',
        link: '/operations',
        visible: true
      })
      setModalOpen(true)
    },
    onError: () => {
      setModal({
        title: 'Unable to Add a report',
        desc: 'You are unable to add a new report at the moment.',
        type: 'error',
        visible: true
      })
      setModalOpen(true)
    }
  })

  const onSubmit = data => {
    const transformedData = {
      ...data,
      type: 'Report',
      title: data.subject,
      status: 'Pending',
      createdBy: user.id
    }
    submitRequest(transformedData)
  }

  return (
    <section className=' flex flex-col bg-white lg:p-5 p-3 gap-4 lg:gap-8 '>
      <BackButton />
      <div className=' flex flex-col gap-2 '>
        <p className=' text-black text-[16px] lg:text-[24px] font-[600] '>
          Add a report
        </p>
        <p className=' text-[12px] lg:text-[14px] text-grey '>
          Fiil in the details for a new report
        </p>
      </div>
      <form
        className=' flex flex-col gap-4 lg:gap-8 '
        action=''
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className=' w-full md:w-[50%] '>
          <TextInput
            name='subject'
            register={register}
            errors={errors}
            text='Subject'
          />
        </div>
        <div className=' w-full '>
          <TextArea
            name='description'
            register={register}
            errors={errors}
            text='Description'
            placeholder='Type report description'
          />
        </div>
        <div className=' w-full md:w-[50%] '>
          <SelectInput
            control={control}
            Controller={Controller}
            data={assignees}
            title='Assigned To'
            name='assignedTo'
            placeholder='Select who to send report to'
          />
        </div>

        <div className='w-full mt-10 lg:mt-0 lg:w-[50%]'>
          <GlobalButton
            title='Submit Report'
            isDirty={isDirty}
            isValid={isValid}
            isLoading={isLoading}
          />
        </div>

      </form>

      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </section>
  )
}

export default AddReport
