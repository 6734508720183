import React, { useEffect, useState } from 'react'
import useBackendService from '../../../../config/services/backend-service'
import MobileWeather from '../MobileWeather/MobileWeather'
import CatalogSection from './CatalogSection/CatalogSection'
import InvestmentSection from './InvestmentSection/InvestmentSection'
import MapWeatherSection from './MapWeather/MapWeatherSection'
import NewsSection from './NewsSection/NewsSection'
import SalesSection from './SalesSection/SalesSection'
import TipsSection from './TipsSection/TipsSection'

function RegularHome () {
  const [data, setData] = useState([])
  const [productlocation, setProductlocation] = useState([])

  const { mutate } = useBackendService('/basic_dashboard', 'get', {
    onSuccess: (data) => {
      setData(data.data)
    },
    onError: (error) => {
      console.log(error)
    }
  })
  const { mutate: product } = useBackendService('/product_catalogues', 'get', {
    onSuccess: (data) => {
      setProductlocation(data.data)
    },
    onError: (error) => {
      console.log(error)
    }
  })

  useEffect(() => {
    mutate()
    product()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main className='flex flex-col gap-[30px] bg-white lg:gap-[50px] py-5 lg:py-[40px] '>
      <MobileWeather />
      <MapWeatherSection data={productlocation} />
      <CatalogSection data={data} />
      <SalesSection
        title='Sales & Supply'
        titletwo='Chain Information'
        desc='Stay ahead of the market with our sales & supply chain information.'
        data={data}
      />
      <InvestmentSection data={data} />
      <NewsSection data={data} />
      <TipsSection data={data} />
    </main>
  )
}

export default RegularHome
