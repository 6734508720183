import React, { useState, useEffect } from 'react'
import Image from '../../../resources/wateryplant.png'
import Logo from '../../../resources/logo-white.png'
import { useForm } from 'react-hook-form'
import EmailInput from '../../SharedComponents/EmailInput/EmailInput'
import PasswordInput from '../../SharedComponents/PasswordInput/PasswordInput'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../SharedComponents/BackButton/BackButton'
import useBackendService from '../../../../config/services/backend-service'
import { login } from '../../../../config/store/reducers/authReducer'
import { useDispatch } from 'react-redux'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { fetchToken } from '../../../../config/services/firebase/firebase'
import useWindowSize from '../../EnterpriseApp/UseWindowSize/UseWindowSize'
import GlobalButton from '../../SharedComponents/GlobalButton/GlobalButton'

const UserLoginPage = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [fcmToken, setFcmToken] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const isTabletView = width >= 768 && width <= 1024
  useEffect(() => {
    const getTokenAsync = async () => {
      const token = await fetchToken()
      if (token) {
        setFcmToken(token)
      }
    }

    getTokenAsync()
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm()

  const { mutate, isLoading } = useBackendService('/auth/login', 'post', {
    onSuccess: data => {
      dispatch(login(data.data))
      if (fcmToken) {
        sendFcmToken({ fcmToken })
      }

      toast.success('You have successfully logged in.')

      const userPlan = get(data, 'data.user.plan')
      const userRole = get(data, 'data.user.role')
      switch (userPlan) {
        case 'Enterprise':
          if (userRole === 'administrator') {
            navigate('/dashboard')
          } else {
            navigate('/stocks')
          }
          break

        case 'Basic':
        case 'Pro':
          navigate('/home')
          break

        case 'Admin':
          navigate('/admin-home')
          break

        default:
          navigate('/home')
          break
      }
    },
    onError: error => {
      const status = error.response.status
      if ([403, 401].includes(status)) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Something went wrong. Please try again.')
      }
    }
  })

  const { mutate: sendFcmToken } = useBackendService('/fcmtoken', 'post', {
    onSuccess: () => {},
    onError: error => {}
  })

  const onSubmit = async data => {
    const payload = {
      email: data.email,
      password: data.password
    }
    mutate(payload)
  }

  return (
    <>
      {isTabletView ? (
        <div className='w-full min-h-screen  bg-waterplant bg-cover p-[5%]'>
          <div className='center w-full'>
            <img alt='' src={Logo} className='w-[100px]' />
          </div>
          <div className='w-full center flex-col text-white text-center space-y-2 mt-[5%]'>
            <h1 className='text-2xl  w-full'>
              Manage your agribusiness efficiently
            </h1>
            <p className='w-[80%] leading-[16px] font-[400] text-[16px]'>
              Building the best and more efficient platform for agribusiness
              management.
            </p>
          </div>
          <div className='w-[90%] p-5 bg-white mt-[5%] mx-auto rounded-md'>
            <div className='w-full'>
              <div className='right-content'>
                <div className='content-cover'>
                  <div className='w-full space-y-1'>
                    <h1 className='header-text'>Login</h1>
                    <p className='text-black'>
                      Kindly fill in your details to continue
                    </p>
                  </div>

                  <form
                    className='form-wrapper'
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <EmailInput
                      register={register}
                      text='/Phone number'
                      errors={errors}
                    />

                    <PasswordInput
                      register={register}
                      errors={errors}
                      showPassword={showPassword}
                      setShowPassword={setShowPassword}
                      disableCheck
                    />
                    <GlobalButton
                      isDirty={isDirty}
                      isValid={isValid}
                      isLoading={isLoading}
                      title='Continue'
                    />

                  </form>

                  <p className='login-text'>
                    Don't have an account?
                    <a href='/signup' className='login-link'>
                      Sign up
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='wrapper'>
          {/* left content */}
          <div className='left-content'>
            <div className='w-full absolute top-[5%] left-[5%]'>
              <img alt='' src={Logo} className='w-[80px] lg:w-[100px]' />
            </div>
            <img alt='' src={Image} className='object-contain' />
            <div className='image-text-wrapper'>
              <h1 className='text-2xl lg:text-5xl w-full'>
                Manage your <br /> agribusiness efficiently
              </h1>
              <p className='lg:w-[70%] w-full text-sm lg:text-lg leading-5'>
                Building the best and more efficient platform for agribusiness
                management.
              </p>
            </div>
          </div>

          {/* right content */}
          <div className='right-content'>
            <BackButton />
            <div className='content-cover'>
              <div className='w-full space-y-1'>
                <h1 className='header-text'>Login</h1>
                <p className='text-black'>
                  Kindly fill in your details to continue
                </p>
              </div>

              <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
                <EmailInput
                  register={register}
                  text='/Phone number'
                  errors={errors}
                />

                <PasswordInput
                  register={register}
                  errors={errors}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  disableCheck
                />

                <GlobalButton
                  isDirty={isDirty}
                  isValid={isValid}
                  isLoading={isLoading}
                  title='Continue'
                />

              </form>

              <p className='login-text'>
                Don't have an account?
                <a href='/signup' className='login-link'>
                  Sign up
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UserLoginPage
