import React from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import useBackendService from '../../../../config/services/backend-service'
import Logo from '../../../resources/logo-white.png'
import Image from '../../../resources/wateryplant.png'
import useWindowSize from '../../EnterpriseApp/UseWindowSize/UseWindowSize'
import CreatePassword from '../../SharedComponents/CreatePaasword/CreatePassword'
const UserCreatePasswordPage = () => {
  const { width } = useWindowSize()
  const isTabletView = width >= 768 && width <= 1024
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
    trigger
  } = useForm()
  const navigate = useNavigate()
  const location = useLocation()
  const email = location.state?.email || ''

  const password = watch('password')
  const confirmPassword = watch('confirmpassword')

  const handlePasswordChange = async (e) => {
    await trigger('password')
  }

  const { mutate: createPassword } = useBackendService(
    '/create_password',
    'post',
    {
      onSuccess: () => {
        navigate('/success')
      },
      onError: (error) => {
        console.error(error.response?.data?.message || 'Error occurred')
      }
    }
  )

  // function to submitting the form data
  const onSubmit = async (data) => {
    // const isValid = await trigger(['password', 'confirmpassword', 'email'])
    // if (!isValid) return
    // navigate('/success')

    // // Handle the form data here
    if (isValid) {
      createPassword({ email, newPassword: data.password })
    }
  }
  return (
    <>
      {isTabletView ? (
        <div className='w-full h-screen  bg-waterplant bg-cover p-[5%]'>
          <div className='center w-full'>
            <img alt='' src={Logo} className='w-[100px]' />
          </div>
          <div className='w-full center flex-col text-white text-center space-y-2 mt-[2%]'>
            <h1 className='text-2xl  w-full'>
              Manage your agribusiness efficiently
            </h1>
            <p className='w-[80%] leading-[16px] font-[400] text-[16px]'>
              Building the best and more efficient platform for agribusiness
              management.
            </p>
          </div>
          <div className='w-[90%] p-5 bg-white mt-[4%] mx-auto rounded-md'>
            <div className='right-content'>
              <CreatePassword
                password={password}
                confirmPassword={confirmPassword}
                watch={watch}
                handlePasswordChange={handlePasswordChange}
                handleSubmit={handleSubmit}
                errors={errors}
                isDirty={isDirty}
                isValid={isValid}
                register={register}
                onSubmit={onSubmit}
                title='Create Password'
                desc='Kindly fill in your details to setup your account'
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='wrapper'>
          {/* left content */}
          <div className='left-content '>
            <div className='absolute top-[5%] left-1/2 lg:left-[10%] transform -translate-x-1/2 -translate-y-1/2 center mx-auto'>
              <img alt='' src={Logo} className='w-[80px] lg:w-[100px]' />
            </div>
            <img alt='' src={Image} className='object-contain' />
            <div className='image-text-wrapper'>
              <h1 className='text-2xl lg:text-5xl w-full'>
                Manage your <br /> agribusiness efficiently
              </h1>
              <p className='w-[100%] lg:w-[70%] lg:text-lg leading-5 text-sm'>
                Building the best and more efficient platform for agribusiness
                management.
              </p>
            </div>
          </div>
          {/* right content */}
          <div className='right-content'>
            <CreatePassword
              password={password}
              confirmPassword={confirmPassword}
              watch={watch}
              handlePasswordChange={handlePasswordChange}
              handleSubmit={handleSubmit}
              errors={errors}
              isDirty={isDirty}
              isValid={isValid}
              register={register}
              onSubmit={onSubmit}
              title='Create Password'
              desc='Kindly fill in your details to setup your account'
            />
          </div>
        </div>
      )}
    </>
  )
}

export default UserCreatePasswordPage
