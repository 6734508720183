import React, { useEffect, useState } from 'react'
import AdminRequestDataTable from './AdminRequestDataTable/AdminRequestDataTable'
import useBackendService from '../../../../config/services/backend-service'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'

const AdminRequests = () => {
  const [data, setData] = useState([])
  const [selectedTab, setSelectedTab] = useState('pending') // Track selected tab

  const { mutate: fetchCalendar, isLoading } = useBackendService('/calendar', 'get', {
    onSuccess: response => {
      setData(response.data)
    },
    onError: error => {
      console.log(error)
    }
  })

  useEffect(() => {
    fetchCalendar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCalendar])

  const pendingCount = data.filter(item => item.status === 'pending').length
  const completedCount = data.filter(item => item.status === 'completed').length

  const filteredData = data.filter(item => item.status === selectedTab)
  // Loading state
  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <div className='w-full bg-white p-[2%]'>
      <div className='w-full flex gap-3 justify-start items-center'>
        <div
          className={`${
            selectedTab === 'pending'
              ? 'bg-darkGreen text-white'
              : 'bg-white text-[#444444] border border-gray-200'
          } rounded-[4px] gap-1 center p-2 cursor-pointer`}
          onClick={() => setSelectedTab('pending')}
        >
          <h1 className='text-[14px]'>Incoming request</h1>
          <div className='w-[24px] h-[24px] rounded-full bg-white text-darkGreen text-[12px] center'>
            {pendingCount}
          </div>
        </div>

        <div
          className={`${
            selectedTab === 'completed'
              ? 'bg-darkGreen text-white'
              : 'bg-white text-[#444444] border border-gray-200'
          } rounded-[4px] gap-1 center p-2 cursor-pointer`}
          onClick={() => setSelectedTab('completed')}
        >
          <h1 className='text-[14px]'>Completed request</h1>
          <div className='w-[24px] h-[24px] rounded-full bg-[#EEEEEE] text-darkGreen text-[12px] center'>
            {completedCount}
          </div>
        </div>
      </div>

      <div className='p-4'>
        <AdminRequestDataTable data={filteredData} />
      </div>
    </div>
  )
}

export default AdminRequests
