import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../../../config/contexts/authProvider'
import useBackendService from '../../../../config/services/backend-service'
import { setProUser } from '../../../../config/store/reducers/signUpReducer'
import { toast } from 'react-toastify'
import GlobalButton from '../../SharedComponents/GlobalButton/GlobalButton'

const countryOptions = [{ label: 'Nigeria', value: 'NG' }]
const ProPlanAppPurposeRegistration = ({ next }) => {
  const { signupState } = useAuth()
  const dispatch = useDispatch()
  const { proUser } = useSelector((state) => state.signUp || {})
  const userFirstName = proUser ? proUser.firstName : ''
  const userLastName = proUser ? proUser.lastName : ''
  const userNumber = proUser ? proUser.phoneNumber : ''
  const userLocation = proUser ? proUser.location : ''

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: userFirstName,
      lastName: userLastName,
      number: userNumber,
      location: userLocation
    }
  })
  // Watch field values
  const watchedValues = watch(['firstName', 'lastName', 'number', 'location'])
  useEffect(() => {
    if (userFirstName) setValue('firstName', userFirstName)
    if (userLastName) setValue('lastName', userLastName)
    if (userNumber) setValue('number', userNumber)
    if (userLocation) setValue('location', userLocation)
    trigger()
  }, [userFirstName, userLastName, userNumber, userLocation, setValue, trigger])

  const { mutate, isLoading } = useBackendService('/auth/sign_up', 'post', {
    onSuccess: (data) => {
      next()
    },
    onError: (error) => {
      if (error?.response?.data?.message === 'Email already exists') {
        toast.error('This email is already registered. Please use another one.')
      } else if (error?.response?.data?.message === 'Error sending mail:') {
        next()
      } else {
        toast.error('Something went wrong. Please try again.')
      }
    }
  })

  const onSubmit = async (data) => {
    const firstNameToSet = data.firstName || userFirstName
    const lastNameToSet = data.lastName || userLastName
    const phoneNumberToSet = data.number || userNumber
    const locationToSet = data.location || userLocation

    dispatch(
      setProUser({
        firstName: firstNameToSet,
        lastName: lastNameToSet,
        phoneNumber: phoneNumberToSet,
        plan: signupState.plan || 'Pro',
        location: locationToSet
      })
    )

    const payload = {
      ...signupState,
      firstName: firstNameToSet,
      lastName: lastNameToSet,
      phoneNumber: phoneNumberToSet,
      location: locationToSet,
      role: 'administrator',
      plan: signupState.plan || 'Pro'
    }

    mutate(payload)
  }
  // Disable button if any field is empty
  const isButtonDisabled = watchedValues.some((value) => !value || isLoading)
  return (
    <div className='content-cover'>
      <div className='w-full  space-y-1'>
        <h1 className='header-text'>Pro Account</h1>
        <p className='text-black '>
          Kindly fill in your details to setup your account
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className='form-wrapper'>
        <div className='grid w-full  items-center gap-1.5 ts'>
          <label htmlFor='firstName'>First Name</label>
          <input
            type='text'
            name='firstName'
            {...register('firstName', { required: 'First name is required' })}
            placeholder='John'
            className='email-input input-style ts'
          />
          {errors.firstName && (
            <p className='error-text'>{errors.firstName.message}</p>
          )}
        </div>
        <div className='grid w-full items-center gap-1.5 ts'>
          <label htmlFor='lastName'>Last Name</label>
          <input
            type='text'
            name='lastName'
            {...register('lastName', { required: 'Last name is required' })}
            placeholder='Doe'
            className='email-input input-style ts'
          />
          {errors.lastName && (
            <p className='error-text'>{errors.lastName.message}</p>
          )}
        </div>
        <div className='grid w-full  items-center gap-1.5 ts'>
          <label htmlFor='text'>Phone number</label>
          <input
            type='number'
            name='number'
            {...register('number', { required: 'Phone number is required' })}
            placeholder='+234'
            className='input-style email-input ts'
            onWheel={(e) => e.target.blur()}
          />
          {errors.number && (
            <p className='error-text'>{errors.number.message}</p>
          )}
        </div>

        <div className='grid w-full items-center gap-1.5 ts'>
          <label htmlFor='location'>Location(s)</label>
          <select
            name='location'
            {...register('location', { required: 'Location is required' })}
            className='select h-[50px] p-2 rounded-[8px] w-full border-none bg-lightash ts'
            // onChange={handleCountryChange}
            defaultValue='NG'
          >
            <option value='' disabled selected>
              Select your country
            </option>
            {countryOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className='grid w-full  items-center gap-1.5 ts'>
          <label htmlFor='text'>Purpose for using app</label>
          <select
            name='Select a purpose'
            className='select h-[50px] p-2 rounded-[8px] w-full border-none bg-lightash ts'
            onChange={(e) => console.log(e.target.value)}
            {...register('purpose', { required: 'Purpose is required' })}
          >
            <option disabled selected>
              Select a purpose
            </option>
            <option>
              I am new to farming and would like to start a small farm
            </option>
            <option>I would like to receive relevant tips on farming</option>
            <option>I would like to receive meteorological data</option>
            <option>Others</option>
          </select>
        </div>

        <GlobalButton
          title='Proceed'
          isLoading={isLoading}
          isFilled={isButtonDisabled}
        />
      </form>

      <p className='login-text'>
        Have an account?{' '}
        <a href='/login' className='login-link'>
          Log in
        </a>
      </p>
    </div>
  )
}

export default ProPlanAppPurposeRegistration
