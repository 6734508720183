import { CloudRain, Droplets, Wind, X } from 'lucide-react'
import React, { useCallback, useEffect, useState } from 'react'
import Map, { Marker } from 'react-map-gl'
import { useAuth } from '../../../../../config/contexts/authProvider'
import PayWall from '../../PayWall/PayWall'
import './Index.css'

import { FaMapMarkerAlt } from 'react-icons/fa'

const bgImg = require('../../../../resources/basicweather.png')

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

function MapWeatherSection ({ data }) {
  const [viewPort, setViewPort] = useState({
    latitude: 9,
    longitude: 8,
    zoom: 5
  })
  const [farmProducts, setFarmProducts] = useState(data || [])
  const [userLocation, setUserLocation] = useState(null)

  const { user } = useAuth()

  const assignColorsToProducts = useCallback((products) => {
    return products.map((product) => ({
      ...product,
      bg: getRandomColor()
    }))
  }, [])

  useEffect(() => {
    if (data && data.length > 0) {
      const productsWithColors = assignColorsToProducts(data)
      setFarmProducts(productsWithColors)
    }
  }, [data, assignColorsToProducts])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          setUserLocation({ latitude, longitude })
          setViewPort((prev) => ({
            ...prev,
            latitude,
            longitude
          }))
        },
        (error) => {
          console.error('Error getting user location:', error)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Filter products to only include those with valid locations
  const productsWithLocations = farmProducts.filter(
    (product) =>
      product.latitude !== undefined &&
      product.longitude !== undefined &&
      !isNaN(product.latitude) &&
      !isNaN(product.longitude)
  )

  return (
    <section className=' basic-map-weather '>
      <div className=' basic-map-section '>
        <div className=' flex flex-row justify-between items-center  '>
          <div className=' flex flex-col gap-1 lg:gap-[14px] '>
            <p className=' text-black font-[700] text-[14px] lg:text-[18px] '>
              Map
            </p>
            <p className=' text-[12px] hidden lg:flex lg:text-[16px] text-grey font-[400] '>
              Check out what & where to plant
            </p>
          </div>
          <div className=' lg:hidden flex  '>
            <p className=' text-primary text-[14px] font-[700px] '>
              Upgrade to view
            </p>
          </div>
        </div>
        {user.plan !== 'Pro' && (
          <div className='basic-map'>
            <div className='lg:px-0 px-4 bg-darkGreen py-5 rounded-lg'>
              <PayWall text='Unlock precise insights on where and what to plant by upgrading to the Pro Plan' />
            </div>
          </div>
        )}
        {user.plan === 'Pro' && (
          <div className=' flex flex-col gap-[15px] lg:[30px] '>
            <div className=' basic-map '>
              <Map
                style={{ width: '100%', height: '100%' }}
                initialViewState={viewPort}
                onMove={(viewPort) => {
                  setViewPort(viewPort)
                }}
                mapStyle='mapbox://styles/mapbox/streets-v9'
                mapboxAccessToken='pk.eyJ1Ijoic29nZWtpbmcxMTIiLCJhIjoiY2x5d2FnMDU3MHM3ejJpc2J5Z3JxYThtNiJ9.mzkl-69I5ejrvyQLPMJwDw'
              >
                {productsWithLocations.map((product, index) => (
                  <Marker
                    key={index}
                    longitude={product.longitude}
                    latitude={product.latitude}
                  >
                    <div
                      className=' w-[14px] h-[14px] rounded-full'
                      style={{ backgroundColor: product.bg }}
                    />
                  </Marker>
                ))}
                {userLocation && (
                  <Marker
                    longitude={userLocation.longitude}
                    latitude={userLocation.latitude}
                  >
                    <FaMapMarkerAlt
                      style={{
                        fontSize: '25px',
                        color: '#5ce4fd'
                      }}
                    />
                  </Marker>
                )}
              </Map>
            </div>
            <div className='gap-5 grid grid-cols-4 lg:grid-cols-7'>
              {farmProducts.map((product, index) => (
                <div className=' flex gap-1 items-center ' key={index}>
                  <div
                    className=' w-[10px] h-[10px] rounded-full '
                    style={{ backgroundColor: product.bg }}
                  />
                  <p className=' text-black capitalize font-[500] text-[12px] lg:text-[16px] '>
                    {product.name}
                  </p>
                  <X size={14} className=' text-black font-[500]' />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className=' basic-weather-section '>
        <div className=' flex flex-col gap-[14px] '>
          <p className=' text-black font-[700] text-[18px] '>
            Weather forecast
          </p>
          <p className=' text-[16px] text-grey font-[400] '>
            Get precise weather update
          </p>
        </div>
        <div className=' weather ' style={{ backgroundImage: `url(${bgImg})` }}>
          <div className=' flex flex-row items-center gap-2 '>
            <div className=' flex flex-col gap-6 mt-[50px] '>
              <p className='font-[500] text-[44px] text-white'>
                31°<span className=' text-[24px] '>c</span>
              </p>
              <p className='font-[500] text-[18px] text-white'> Sunny</p>
            </div>
            <img
              src={require('../../../../resources/sun.png')}
              alt=''
              className='w-[60px] h-[42px] '
            />
          </div>
          <div className=' flex flex-col gap-[15px] text-white  '>
            <div className=' weather-icons '>
              <CloudRain size={24} />
              <p className=' font-[400] text-[18px] '>15%</p>
            </div>
            <div className=' weather-icons '>
              <Droplets size={24} />
              <p className=' font-[400] text-[18px] '>0 mm</p>
            </div>
            <div className=' weather-icons '>
              <Wind size={24} />
              <p className=' font-[400] text-[18px] '>15 km/h</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MapWeatherSection
