import React from 'react'
import { useNavigate } from 'react-router-dom'
import DataTable from '../../../SharedComponents/DataTable/DataTable'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
import Pagination from '../../../SharedComponents/Pagination/Pagination'
import { columns } from '../AdminConsultationColumn/AdminConsultationColumn'

const AdminConsultationDataTable = ({ data }) => {
  const navigate = useNavigate()

  const handleRowClick = (rowData) => {
    navigate(`/admin-consultations/${rowData.id}`, {
      state: { consultation: rowData }
    })
  }
  return (
    <div>
      <DataTable
        data={data}
        columns={columns}
        filter={false}
        pagination={data.length !== 0 && <Pagination />}
        rowClick={handleRowClick}
        emptydatacontent={
          <div className='flex flex-col items-center justify-center w-full '>
            <EmptyDataContent
              image={require('../../../../resources/farmemptyimage.png')}
              text='No business yet'
              desc='Once an business has been added, you will see a business here.'
            />
          </div>
        }
      />
    </div>
  )
}

export default AdminConsultationDataTable
