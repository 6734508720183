import React from 'react'
import { Link } from 'react-router-dom'
import useWindowSize from '../../../../farmapp/components/EnterpriseApp/UseWindowSize/UseWindowSize'

const TgaimsWorkSection = () => {
  const { width } = useWindowSize()
  return (
    <section className=' lg:px-[100px] px-[5%] py-10 lg:py-[64px] gap-10 flex flex-col '>
      <div className=' flex flex-col text-center gap-3 '>
        <p className=' font-[600] lg:font-[600] text-[18px] lg:text-[30px] text-start lg:text-center text-primary '>
          How T-Gaims Works
        </p>
        <p className=' text-grey lg:text-[16px] text-[13px]  text-start lg:text-center '>
          Whether you're overseeing agriculture investment portfolios, businesses
          (large, medium or small scale entreprise),
          <br className=' hidden lg:flex ' />
          our features ensure you have everything you need to succeed.
        </p>
      </div>

      <div className=' flex flex-col lg:items-center lg:justify-center lg:flex-row lg:gap-40 gap-10 lg:mt-6'>
        <div className=' w-full lg:w-1/2'>
          {width > 1024
            ? <img
                src={require('../../../assets/Rectangle 216.png')}
                alt=''
                className=' w-full h-full object-contain'
              />
            : <img
                src={require('../../../assets/plantmobile.png')}
                alt=''
                className=' w-full h-full object-contain'
              />}
        </div>
        <div className=' flex flex-col justify-start items-end w-full lg:w-1/2 text-start gap-[50px] lg:gap-[90px]'>
          <div className=' flex flex-col gap-5 '>
            <div className=' flex flex-row gap-5 w-full'>
              <img
                src={require('../../../assets/icon.png')}
                alt=''
                className=' lg:w-[40px] w-[30px] h-[30px] lg:h-[40px] '
              />
              <div className=' w-full gap-1 lg:gap-3 flex flex-col '>
                <p className='font-[500] text-[14px] lg:text-[18px] text-black   '>
                  Sign Up
                </p>
                <p className=' font-[400] lg:text-[16px] text-[13px] text-grey '>
                  Create an account and set up your personal and business
                  profile. Enter details about operation.
                </p>
              </div>
            </div>
            <div className=' flex flex-row gap-5 '>
              <img
                src={require('../../../assets/icon-1.png')}
                alt=''
                className=' lg:w-[40px] w-[30px] h-[30px] lg:h-[40px] '
              />
              <div className='w-full gap-1 lg:gap-3 flex flex-col '>
                <p className='font-[500] text-[14px] lg:text-[18px] text-black  '>
                  Monitor
                </p>
                <p className=' font-[400] lg:text-[16px] text-[13px] text-grey '>
                  Track real-time data from your farm. Use our intuitive
                  dashboard to visualize key metrics and receive alerts.
                </p>
              </div>
            </div>
            <div className=' flex flex-row gap-5 '>
              <img
                src={require('../../../assets/icon-2.png')}
                alt=''
                className=' lg:w-[40px] w-[30px] h-[30px] lg:h-[40px] '
              />
              <div className='w-full gap-1 lg:gap-3 flex flex-col '>
                <p className='font-[500] lg:text-[18px] text-[14px] text-black '>Grow</p>
                <p className=' font-[400] lg:text-[16px] text-[13px] text-grey '>
                  Watch your agri investments and business thrive with improved efficiency.
                  For lower cost and apply sustainable practices.
                </p>
              </div>
            </div>
            <div className=' flex flex-row gap-5 '>
              <img
                src={require('../../../assets/icon-3.png')}
                alt=''
                className=' lg:w-[40px] w-[30px] h-[30px] lg:h-[40px] '
              />
              <div className='w-full gap-1 lg:gap-3 flex flex-col '>
                <p className='font-[500] lg:text-[18px] text-[14px] text-black '>
                  Integrate
                </p>
                <p className=' font-[400] lg:text-[16px] text-[13px] text-grey '>
                  Connect your existing tools and systems to our platform. Our
                  seamless integration process makes it easy.
                </p>
              </div>
            </div>
            <div className=' flex flex-row gap-5 '>
              <img
                src={require('../../../assets/icon-4.png')}
                alt=''
                className=' lg:w-[40px] w-[30px] h-[30px] lg:h-[40px] '
              />
              <div className='w-full gap-1 lg:gap-3 flex flex-col '>
                <p className='font-[500] lg:text-[18px] text-black text-[14px]'>
                  Optimize
                </p>
                <p className=' font-[400] lg:text-[16px] text-[13px] text-grey '>
                  Use our advanced tools to optimize resource usage. Implement
                  sustainable practice and boost investment returns.
                </p>
              </div>
            </div>
          </div>
          <Link to='/signup' className='w-full mt-8'>
            <div className='lg:py-[10px]  center  h-[53px] text-center lg:mb-0 mb-20 p-2 lg:px-6 bg-primary rounded-lg cursor-pointer'>
              <p className='font-[500] text-darkGreen text-[14px] lg:text-[18px]'>
                Get started now
              </p>
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default TgaimsWorkSection
