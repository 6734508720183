import React from 'react'
import DataTable from '../../../SharedComponents/DataTable/DataTable'
import { columns } from '../AdminRequestColumn/AdminRequestColumn'
import Pagination from '../../../SharedComponents/Pagination/Pagination'
import { useNavigate } from 'react-router-dom'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
const AdminRequestDataTable = ({ data }) => {
  const navigate = useNavigate()

  const handleRowClick = data => {
    navigate(`/admin-requests/${data.id}`)
  }
  return (
    <div>
      <DataTable
        data={data}
        columns={columns}
        filter={false}
        pagination={data.length !== 0 && <Pagination />}
        rowClick={handleRowClick}
        emptydatacontent={
          <div className='flex flex-col items-center justify-center w-full '>
            <EmptyDataContent
              image={require('../../../../resources/farmemptyimage.png')}
              text='No calendar yet'
              desc='Once an calendar has been added, you will see a calendar here.'
            />
          </div>
        }

      />
    </div>
  )
}

export default AdminRequestDataTable
