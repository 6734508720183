import { ArrowUpDown, ChevronDown } from 'lucide-react'
import React, { useState } from 'react'

const Filter = ({ data, table }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentSort, setCurrentSort] = useState('')

  const handleSortingChange = (direction, column) => {
    setIsOpen(false)

    if (column === 'alphabetical') {
      table.setSorting([{ id: 'farm_name', desc: false }])
      setCurrentSort('Alphabetical')
    } else if (column === 'clear') {
      table.setSorting([])
      setCurrentSort('')
    } else {
      table.setSorting([{ id: 'created_at', desc: direction === 'desc' }])
      setCurrentSort(direction === 'desc' ? 'New - Old' : 'Old - New')
    }
  }

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <div className='separator px-0 lg:px-3'>
      <h1 className='text-sm lg:text-lg w-[500px] font-semibold'>Businesses</h1>
      {data.length === 0
        ? (
            ''
          )
        : (
          <div className='dropdown dropdown-hover p-0 relative'>
            <div
              tabIndex={0}
              role='button'
              className='w-[150px] text-sm p-3 center gap-1 ts'
              onClick={toggleDropdown}
            >
              <ArrowUpDown size={20} />
              {currentSort || 'Sort by'}
              <ChevronDown size={20} />
            </div>
            {isOpen && (
              <div tabIndex={0} className='menuz rounded-box sort ts'>
                <h1
                  className='sort-hover'
                  onClick={() => handleSortingChange('desc', 'created_at')}
                >
                  New - Old
                </h1>
                <h1
                  className='sort-hover'
                  onClick={() => handleSortingChange('asc', 'created_at')}
                >
                  Old - New
                </h1>
                <h1
                  className='sort-hover'
                  onClick={() => handleSortingChange('asc', 'alphabetical')}
                >
                  Alphabetical
                </h1>
                <h1
                  className='sort-hover'
                  onClick={() => handleSortingChange('asc', 'clear')}
                >
                  Clear Filter
                </h1>
              </div>
            )}
          </div>
          )}
    </div>
  )
}

export default Filter
