import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../../../config/store/reducers/signUpReducer'

const EnterpriseAccountSignUp = ({ next }) => {
  const dispatch = useDispatch()

  // Safely accessing the user object from the Redux store
  const { enterpriseUser } = useSelector((state) => state.signUp || {})
  const userEmail = enterpriseUser ? enterpriseUser.email : ''
  const userPhoneNumber = enterpriseUser ? enterpriseUser.phoneNumber : ''

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      // Setting default values for the form
      email: userEmail,
      number: userPhoneNumber
    }
  })

  // Function to handle form submission
  const onSubmit = async (data) => {
    dispatch(setUser({ email: data.email, phoneNumber: data.number }))
    next()
  }
  // Watch the email and number fields
  const email = watch('email', userEmail)
  const number = watch('number', userPhoneNumber)
  const isButtonDisabled = !email || !number
  return (
    <div className='content-cover'>
      <div className='w-full space-y-1'>
        <h1 className='header-text'>Enterprise Account</h1>
        <p className='text-black ts'>
          Kindly fill in your details to setup your account
        </p>
      </div>

      <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
        <div className='grid w-full items-center gap-1.5 ts'>
          <label htmlFor='email'>Email</label>
          <input
            type='email'
            name='email'
            {...register('email', { required: 'Email is required' })}
            placeholder='Email'
            className='input-style email-input ts'
          />
          {errors.email && <p className='error-text'>{errors.email.message}</p>}
        </div>

        <div className='grid w-full items-center gap-1.5 ts'>
          <label htmlFor='text'>Phone number</label>
          <input
            type='number'
            name='number'
            {...register('number', { required: 'Phone number is required' })}
            placeholder='+234'
            className='input-style email-input ts'
            onWheel={(e) => e.target.blur()}
          />
          {errors.number && (
            <p className='error-text'>{errors.number.message}</p>
          )}
        </div>
        <button
          type='submit'
          className={`ts ${!isButtonDisabled ? 'activebutton' : 'notactive'}`}
          disabled={isButtonDisabled}
        >
          Proceed
        </button>
      </form>

      <p className='login-text'>
        Have an account?{' '}
        <a href='/login' className='login-link'>
          Log in
        </a>
      </p>
    </div>
  )
}

export default EnterpriseAccountSignUp
