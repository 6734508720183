import { ChevronLeft, CreditCard, Landmark, Loader2 } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../config/contexts/authProvider'
import useBackendService from '../../../../config/services/backend-service'
import { setProUser } from '../../../../config/store/reducers/signUpReducer'
import Logo from '../../../resources/logo-white.png'
import Image from '../../../resources/wateryplant.png'
import useWindowSize from '../../EnterpriseApp/UseWindowSize/UseWindowSize'
import GlobalButton from '../../SharedComponents/GlobalButton/GlobalButton'

const ProPlanPayment = () => {
  const { proUser } = useSelector((state) => state.signUp || {})
  const userEmail = proUser ? proUser.email : ''
  const { width } = useWindowSize()
  const dispatch = useDispatch()

  const isTabletView = width >= 768 && width <= 1024
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue
  } = useForm()
  const { setSignupField } = useAuth()
  const [planType, setPlanType] = useState('monthly')
  const amount = planType === 'monthly' ? '5,000' : '60,000'
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('card')

  const handlePaymentMethodClick = (method) => {
    setSelectedPaymentMethod(method)
  }

  const paystackPublicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY

  const { mutate, isLoading } = useBackendService(
    '/ipn/paystack/save_recurring',
    'post',
    {
      onSuccess: (data) => {
        navigate('/signup/proplansignup')
      },
      onError: (error) => {
        console.error('Error saving payment to backend:', error.message)
      }
    }
  )

  useEffect(() => {
    if (userEmail) {
      setValue('email', userEmail)
    }
  }, [userEmail, setValue])

  const onSubmit = async (data) => {
    const { email, cardnumber, expirydate, cvv } = data
    const emailToSet = email || userEmail

    setSignupField('email', emailToSet)

    dispatch(
      setProUser({
        email: emailToSet
      })
    )
    setSignupField('email', emailToSet)

    const amount = planType === 'monthly' ? 5000 * 100 : 60000 * 100

    const paystack = window.PaystackPop.setup({
      key: paystackPublicKey,

      email: emailToSet,
      amount,

      card: {
        number: cardnumber,
        cvv,
        expiry_month: expirydate.split('/')[0],
        expiry_year: expirydate.split('/')[1]
      },
      channels: selectedPaymentMethod === 'card' ? ['card'] : ['bank_transfer'],
      callback: (response) => {
        toast.success(`Payment successful! Reference: ${response.reference}`)
        mutate({ reference: response.reference, plan: 'Pro', type: planType })
      },
      onClose: () => {
        toast.error('Payment window was closed.')
      }
    })

    paystack.openIframe()
  }
  return (
    <>
      {isLoading ? (
        <div className='w-full h-[70vh] lg:h-screen flex items-center justify-center flex-col gap-4 text-center '>
          <Loader2 size={40} className='animate-spin' />
          <h1 className='font-semibold text-xl'>Payment Processing</h1>
          <p className='text-[#666666] ts'>
            Please be patient and bear with us while we process your plan
            payment.
          </p>
        </div>
      ) : (
        <>
          {isTabletView ? (
            <div className='w-full h-screen  bg-waterplant bg-cover p-[5%]'>
              <div className='center w-full'>
                <img alt='' src={Logo} className='w-[100px]' />
              </div>
              <div className='w-full center flex-col text-white text-center space-y-2 mt-[2%]'>
                <h1 className='text-2xl  w-full'>
                  Manage your agribusiness efficiently
                </h1>
                <p className='w-[80%] leading-[16px] font-[400] text-[16px]'>
                  Building the best and more efficient platform for agribusiness
                  management.
                </p>
              </div>
              <div className='w-[90%] p-5 bg-white h-[80vh] mt-[4%]  mx-auto rounded-md '>
                <div className='w-full lg:w-[50%] h-full  p-[3%] z-40 bg-white rounded-t-[50px] space-y-4'>
                  <div className='cheveron-wrapper'>
                    <a href='/signup'>
                      <ChevronLeft className='text-darkgreen cursor-pointer' />
                    </a>
                  </div>
                  <div className='w-full text-start mt-3 space-y-2'>
                    <h1>Pro plan payment</h1>
                    <p className='w-[100%] mx-auto mt-2  text-xs lg:text-sm text-black'>
                      You are about to make payment for the pro plan with a sum
                      of ₦{amount}. Kindly input your card details to initiate
                      the transaction.
                    </p>
                  </div>

                  <div className='flex gap-2 '>
                    <div className='w-full flex gap-2 justify-start items-center'>
                      <input
                        type='checkbox'
                        className='jj'
                        value='monthly'
                        checked={planType === 'monthly'}
                        onChange={() => setPlanType('monthly')}
                      />
                      <h1 className='text-[#666666] ts'>Monthly: ₦5,000 </h1>
                    </div>

                    <div className='w-full flex gap-2 justify-start items-center'>
                      <input
                        type='checkbox'
                        className='jj'
                        value='yearly'
                        checked={planType === 'yearly'}
                        onChange={() => setPlanType('yearly')}
                      />
                      <h1 className='text-[#666666] ts'> Yearly: ₦60,000 </h1>
                    </div>
                  </div>
                  <div className='w-full flex gap-3'>
                    {/* Card */}
                    <div
                      onClick={() => handlePaymentMethodClick('card')}
                      className={`cursor-pointer p-3 rounded-lg gap-2 font-[400] ts center ${
                        selectedPaymentMethod === 'card'
                          ? 'bg-darkGreen text-white'
                          : 'bg-[#EEEEEE] text-black'
                      }`}
                    >
                      <CreditCard size={20} />
                      <h1>Card</h1>
                    </div>

                    {/* Bank Transfer */}
                    <div
                      onClick={() => handlePaymentMethodClick('bank')}
                      className={`cursor-pointer p-3 rounded-lg gap-2 font-[400] ts center ${
                        selectedPaymentMethod === 'bank'
                          ? 'bg-darkGreen text-white'
                          : 'bg-[#EEEEEE] text-black'
                      }`}
                    >
                      <Landmark size={20} />
                      <h1>Bank Transfer</h1>
                    </div>
                  </div>

                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className='form-payment'
                  >
                    {/* Email Input */}
                    <div className='grid w-full items-center gap-1.5'>
                      <label htmlFor='email'>Email</label>
                      <input
                        type='email'
                        name='email'
                        placeholder='youremail@example.com'
                        {...register('email', {
                          required: 'Email is required',
                          pattern: {
                            value: /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: 'Invalid email format'
                          }
                        })}
                        className='email-input'
                      />
                      {errors.email && (
                        <p className='text-xs text-red-500'>
                          {errors.email.message}
                        </p>
                      )}
                    </div>

                    {selectedPaymentMethod === 'card' && (
                      <>
                        <div className='grid w-full items-center gap-1.5'>
                          <label htmlFor='cardnumber'>Card number</label>
                          <input
                            type='number'
                            name='cardnumber'
                            placeholder='0000 0000 0000 0000'
                            {...register('cardnumber', {
                              required: 'Card number is required',
                              minLength: {
                                value: 16,
                                message: 'Card number must be 16 digits'
                              },
                              pattern: {
                                value: /^[0-9]+$/,
                                message: 'Card number must be numeric'
                              }
                            })}
                            className='email-input'
                            onWheel={(e) => e.target.blur()}
                          />
                          {errors.cardnumber && (
                            <p className='text-red-500 text-xs'>
                              {errors.cardnumber.message}
                            </p>
                          )}
                        </div>

                        <div className='w-full center gap-4'>
                          <div className='grid w-[50%] items-center gap-1.5'>
                            <label htmlFor='expirydate'>Expiry Date</label>
                            <input
                              type='text'
                              name='expirydate'
                              placeholder='MM/YY'
                              {...register('expirydate', {
                                required: 'Expiry date is required',
                                pattern: {
                                  value: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                                  message: 'Invalid expiry date format (MM/YY)'
                                }
                              })}
                              className='email-input'
                            />
                            {errors.expirydate && (
                              <p className='text-red-500 text-xs'>
                                {errors.expirydate.message}
                              </p>
                            )}
                          </div>
                          <div className='grid w-[50%] items-center gap-1.5'>
                            <label htmlFor='cvv'>CVV</label>
                            <input
                              type='text'
                              name='cvv'
                              placeholder='123'
                              maxLength={3}
                              {...register('cvv', {
                                required: 'CVV is required',
                                pattern: {
                                  value: /^[0-9]{3}$/,
                                  message: 'Invalid CVV format (3 digits)'
                                }
                              })}
                              className='email-input'
                            />
                            {errors.cvv && (
                              <p className='text-red-500 text-xs'>
                                {errors.cvv.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    <div className='w-full pt-[5%]'>
                      <GlobalButton
                        isDirty={isDirty}
                        isValid={isValid}
                        isLoading={isLoading}
                        title='Make Payment'
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className='wrapper'>
              <div className='left-content'>
                <div className='absolute top-[5%] left-1/2 lg:left-[10%] transform -translate-x-1/2 -translate-y-1/2 center mx-auto'>
                  <img alt='' src={Logo} className='w-[80px] lg:w-[100px]' />
                </div>
                <img alt='' src={Image} className='object-contain' />
                <div className='image-text-wrapper'>
                  <h1 className='text-2xl lg:text-5xl w-full'>
                    Manage your <br /> agribusiness efficiently
                  </h1>
                  <p className='w-[100%] lg:w-[70%] lg:text-lg leading-5 text-sm'>
                    Building the best and more efficient platform for
                    agribusiness management.
                  </p>
                </div>
              </div>

              <div className='w-full lg:w-[50%] h-[80vh] overflow-y-scroll lg:overflow-y-visible  p-[3%] z-40 bg-white rounded-t-[50px]'>
                <div className='cheveron-wrapper'>
                  <a href='/signup'>
                    <ChevronLeft className='text-darkgreen cursor-pointer' />
                  </a>
                  <h1>Pro plan payment</h1>
                </div>
                <p className='w-[90%] mx-auto mt-2 text-center text-xs lg:text-sm text-black'>
                  You are about to make payment for the pro plan with a sum of ₦
                  {amount}. Kindly input your card details to initiate the
                  transaction.
                </p>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className='form-payment mb-[15%] lg:mb-0'
                >
                  <div className='flex gap-2'>
                    <div className='w-full flex gap-2 justify-start items-center'>
                      <input
                        type='checkbox'
                        className='jj'
                        value='monthly'
                        checked={planType === 'monthly'}
                        onChange={() => setPlanType('monthly')}
                      />
                      <h1 className='text-[#666666] ts'>Monthly: ₦5,000 </h1>
                    </div>

                    <div className='w-full flex gap-2 justify-start items-center'>
                      <input
                        type='checkbox'
                        className='jj'
                        value='yearly'
                        checked={planType === 'yearly'}
                        onChange={() => setPlanType('yearly')}
                      />
                      <h1 className='text-[#666666] ts'> Yearly: ₦60,000 </h1>
                    </div>
                  </div>
                  <div className='w-full flex gap-3'>
                    {/* Card */}
                    <div
                      onClick={() => handlePaymentMethodClick('card')}
                      className={`cursor-pointer p-3 rounded-lg gap-2 font-[400] ts center ${
                        selectedPaymentMethod === 'card'
                          ? 'bg-darkGreen text-white'
                          : 'bg-[#EEEEEE] text-black'
                      }`}
                    >
                      <CreditCard size={20} />
                      <h1>Card</h1>
                    </div>

                    {/* Bank Transfer */}
                    <div
                      onClick={() => handlePaymentMethodClick('bank')}
                      className={`cursor-pointer p-3 rounded-lg gap-2 font-[400] ts center ${
                        selectedPaymentMethod === 'bank'
                          ? 'bg-darkGreen text-white'
                          : 'bg-[#EEEEEE] text-black'
                      }`}
                    >
                      <Landmark size={20} />
                      <h1>Bank Transfer</h1>
                    </div>
                  </div>
                  {/* Email Input */}
                  <div className='grid w-full items-center gap-1.5'>
                    <label htmlFor='email'>Email</label>
                    <input
                      type='email'
                      name='email'
                      placeholder='youremail@example.com'
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: 'Invalid email format'
                        }
                      })}
                      className='email-input'
                    />
                    {errors.email && (
                      <p className='text-xs text-red-500'>
                        {errors.email.message}
                      </p>
                    )}
                  </div>

                  {selectedPaymentMethod === 'card' && (
                    <>
                      <div className='grid w-full items-center gap-1.5'>
                        <label htmlFor='cardnumber'>Card number</label>
                        <input
                          type='number'
                          name='cardnumber'
                          placeholder='0000 0000 0000 0000'
                          {...register('cardnumber', {
                            required: 'Card number is required',
                            minLength: {
                              value: 16,
                              message: 'Card number must be 16 digits'
                            },
                            pattern: {
                              value: /^[0-9]+$/,
                              message: 'Card number must be numeric'
                            }
                          })}
                          className='email-input'
                          onWheel={(e) => e.target.blur()}
                        />
                        {errors.cardnumber && (
                          <p className='text-red-500 text-xs'>
                            {errors.cardnumber.message}
                          </p>
                        )}
                      </div>

                      <div className='w-full center gap-4'>
                        <div className='grid w-[50%] items-center gap-1.5'>
                          <label htmlFor='expirydate'>Expiry Date</label>
                          <input
                            type='text'
                            name='expirydate'
                            placeholder='MM/YY'
                            {...register('expirydate', {
                              required: 'Expiry date is required',
                              pattern: {
                                value: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                                message: 'Invalid expiry date format (MM/YY)'
                              }
                            })}
                            className='email-input'
                          />
                          {errors.expirydate && (
                            <p className='text-red-500 text-xs'>
                              {errors.expirydate.message}
                            </p>
                          )}
                        </div>
                        <div className='grid w-[50%] items-center gap-1.5'>
                          <label htmlFor='cvv'>CVV</label>
                          <input
                            type='text'
                            name='cvv'
                            placeholder='123'
                            maxLength={3}
                            {...register('cvv', {
                              required: 'CVV is required',
                              pattern: {
                                value: /^[0-9]{3}$/,
                                message: 'Invalid CVV format (3 digits)'
                              }
                            })}
                            className='email-input'
                          />
                          {errors.cvv && (
                            <p className='text-red-500 text-xs'>
                              {errors.cvv.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className='w-full pt-[5%]'>
                    <GlobalButton
                      isDirty={isDirty}
                      isValid={isValid}
                      isLoading={isLoading}
                      title='Make Payment'
                    />
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default ProPlanPayment
