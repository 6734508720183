import React from 'react'

const GlobalLoader = () => {
  return (
    <div className='w-full center h-screen'>
      <div className='relative'>
        <div class='w-14 h-14 lg:w-20 lg:h-20 border-[#adee68] border-2 rounded-full' />
        <div className='w-14 h-14 lg:w-20 lg:h-20 border-[#247e2b] border-t-2 animate-spin rounded-full absolute left-0 top-0' />
      </div>
    </div>
  )
}

export default GlobalLoader
