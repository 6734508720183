import React, { useState } from 'react'
import { Eye, EyeOff } from 'lucide-react'

const CreatePassword = ({
  onSubmit,
  title,
  watch,
  desc,
  EmailInput,
  password,
  handlePasswordChange,
  handleSubmit,
  errors,
  isDirty,
  isValid,
  register,
  confirmPassword
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  // checkbox data
  const passwordRequirements = [
    {
      name: 'At least 8 characters',
      check: password ? password.length >= 8 : false,
      icon: password ? (password.length >= 8 ? '' : null) : null
    },
    {
      name: 'Uppercase',
      check: password ? /[A-Z]/.test(password) : false,
      icon: password ? (/^[A-Z]/.test(password) ? '' : null) : null
    },
    {
      name: 'Lowercase',
      check: password ? /[a-z]/.test(password) : false,
      icon: password ? (/^[a-z]/.test(password) ? '' : null) : null
    },
    {
      name: 'Special character',
      check: password ? /[!@#$%^&*_=+-]/.test(password) : false,
      icon: password ? (/^[!@#$%^&*_=+-]/.test(password) ? '' : null) : null
    }

  ]

  const confirmPasswordRequirements = [
    {
      name: 'Password should match what was put above',
      check: confirmPassword && password === confirmPassword,
      icon: confirmPassword ? (confirmPassword === password ? '' : null) : null
    }
  ]

  return (
    <div className='content-cover'>

      <div className='w-full  space-y-1'>
        <h1 className='header-text'>{title}</h1>
        <p className='text-black '>{desc}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='form-wrapper ts'>

        {EmailInput ? <EmailInput register={register} errors={errors} /> : null}

        <div className='input-container  ts'>
          <label htmlFor='password'>Create Password</label>
          <div className='input-cover mt-1'>

            <input
              onChange={handlePasswordChange} type={showPassword ? 'text' : 'password'} {...register('password', {
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                },
                validate: (value) => {
                  return (
                    [/[a-z]/, /[A-Z]/, /[^a-zA-Z0-9]/].every((pattern) =>
                      pattern.test(value)
                    ) || 'must include lower, upper, and special character'
                  )
                }

              })} name='password' className='email-input'
            />
            <div onClick={() => setShowPassword(!showPassword)} className='show-btn'>{showPassword ? <Eye /> : <EyeOff />}</div>
          </div>
          {errors.password && (
            <p className='error-text'>{errors.password.message}</p>
          )}

        </div>

        <div className='password-wrapper'>
          <h1 className='password-text ts'>Password requirements :</h1>
          {passwordRequirements.map((requirement, index) => (
            <div key={index} className='checkbox-wrapper ts mt-3'>

              {requirement.icon}
              <input type='checkbox' className='' checked={requirement.check} /><p>{requirement.name}</p>
            </div>
          ))}

        </div>
        <div className='input-container ts'>
          <label htmlFor='password'>Confirm Password</label>
          <div className='input-cover mt-1'>
            <input
              type={showConfirmPassword ? 'text' : 'password'} {...register('confirmpassword', {
                required: 'Confirm Password is required',
                validate: (value) => value === watch('password') || "Passwords don't match"
              })}
              name='confirmpassword'
              className='email-input'
            />
            <div onClick={() => setShowConfirmPassword(!showConfirmPassword)} className='show-btn'>{showConfirmPassword ? <Eye /> : <EyeOff />}</div>
          </div>
          {confirmPasswordRequirements.map((requirement, index) => (
            <div key={index} className='checkbox-wrapper mt-3 ts'>

              {requirement.icon}
              <input type='checkbox' className='' checked={requirement.check} /><p>{requirement.name}</p>
            </div>
          ))}
          {errors.confirmpassword && (
            <p className='error-text'>{errors.confirmpassword.message}</p>
          )}

        </div>
        <button
          type='submit'
          className={`${isDirty && isValid ? 'activebutton' : 'h-[50px] rounded-[8px] bg-darkash text-black'}`}
          disabled={!(isDirty && isValid)}
        >
          Proceed
        </button>

      </form>

      <p className='login-text'>Have an account?  <a href='/login' className='login-link'>Log in</a></p>
    </div>
  )
}

export default CreatePassword
