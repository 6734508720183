import React, { useEffect, useState } from 'react'
import { ArrowLeft } from 'lucide-react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import './SalesDescription.css'
import SalesDropdown from './SalesDropdown/SalesDropdown'
import { useParams } from 'react-router-dom'
import useBackendService from '../../../../../config/services/backend-service'
import 'react-toastify/dist/ReactToastify.css'
import Modal from '../../../SharedComponents/Modal/Modal'
import GlobalLoader from '../../../SharedComponents/GlobalLoader/GlobalLoader'

// Register the components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

// Function to generate a random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const prepareChartData = marketPrices => {
  return {
    labels: marketPrices.map(price => price.date),
    datasets: [
      {
        label: 'Market Price',
        data: marketPrices.map(price => price.value),
        backgroundColor: getRandomColor(),
        borderColor: getRandomColor(),
        borderWidth: 1,
        borderRadius: 10
      }
    ]
  }
}

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'top'
    },
    title: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      },
      beginAtZero: true
    }
  }
}

const SalesDescription = ({ unlock = false }) => {
  const { id } = useParams()
  const [data, setData] = useState(null)
  const [selectedSeries, setSelectedSeries] = useState(null)
  const [modal, setModal] = useState({})

  const { mutate: fetchCatalogue } = useBackendService(
    `/product_catalogues/${id}`,
    'get',
    {
      onSuccess: response => {
        const fetchedData = response.data
        setData(fetchedData)
        setSelectedSeries(prepareChartData(fetchedData.market_prices))
      },
      onError: error => {
        setModal({
          type: 'error',
          title: 'Unable to fetch catalogue data',
          desc: 'There was an issue retrieving the catalogue data. Please try again.'
        })
        document.getElementById('my_modal_5').showModal()
      }
    }
  )

  useEffect(() => {
    fetchCatalogue()
  }, [fetchCatalogue])

  if (!data) {
    return (
      <GlobalLoader />
    )
  }

  return (
    <div className='w-full h-full p-[2%] bg-white'>
      {unlock && (
        <div className='mb-6'>
          <h1 className='text-3xl text-darkGreen font-bold'>
            Sales & Supply management
          </h1>
          <p className='text-sm text-gray-600'>
            For quick access to specific product information
          </p>
        </div>
      )}
      <div className='w-[5%]'>
        {unlock
          ? (
            <a href='/market/sales'>
              <ArrowLeft />
            </a>
            )
          : (
            <a href='/sales'>
              <ArrowLeft />
            </a>
            )}
      </div>
      <div className='w-full center gap-3 lg:flex-row flex-col mt-2'>
        {/* image */}
        <div className='w-full lg:w-[30%] bg-darkGreen text-white p-[4%] lg:p-[2%] ts rounded-md space-y-2'>
          <h1 className='font-semibold capitalize'>{data.name}</h1>
          <div className='w-full h-[200px]'>
            <img
              src={data.images[0]?.image_url}
              alt='product'
              className='w-full h-full object-cover rounded-lg'
            />
          </div>
          <div className='w-full flex flex-wrap'>
            <h1 className='font-bold'>Best place to plant:</h1>
            <div className='w-[100%] flex flex-wrap'>
              {data.location.map((loc, index) => (
                <p key={index}>
                  {loc.location}
                  {index < data.location.length - 1 ? ', ' : ''}
                </p>
              ))}
            </div>
          </div>
        </div>
        {/* chart */}
        <div className='w-full lg:w-[70%]'>
          <div className='w-full flex justify-between items-center'>
            <h1 className='ts'>Market Price</h1>
          </div>
          {selectedSeries
            ? (
              <Bar data={selectedSeries} options={chartOptions} />
              )
            : (
              <div>No Data</div>
              )}
        </div>
      </div>
      <SalesDropdown salesproductsdetails={data} />
      {modal.type && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
        />
      )}
    </div>
  )
}

export default SalesDescription
