const productUnitInput = [
  { value: 'liters', label: 'Liters' },
  { value: 'kg', label: 'kg' },
  { value: 'g', label: 'g' },
  { value: 'ml', label: 'ML' },
  { value: 'kilo', label: 'Kilo' },
  { value: 'ton', label: 'Ton' },
  { value: 'ounces', label: 'Ounces' }
]

const productInputStatus = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' }
]

const agribusinessProductTypes = [
  { value: 'livestock', label: 'Livestock' },
  { value: 'poultry', label: 'Poultry' },
  { value: 'aquaculture', label: 'Aquaculture' },
  { value: 'horticulture', label: 'Horticulture' },
  { value: 'cash_crops', label: 'Cash Crops' },
  { value: 'arable_crops', label: 'Arable Crops' },
  { value: 'dairy', label: 'Dairy' },
  { value: 'forestry', label: 'Forestry' },
  { value: 'agroprocessing', label: 'Agroprocessing' },
  { value: 'plantation', label: 'Plantation' },
  { value: 'apiary', label: 'Apiary (Beekeeping)' },
  { value: 'greenhouse_farming', label: 'Greenhouse Farming' },
  { value: 'hydroponics', label: 'Hydroponics' },
  { value: 'organic_farming', label: 'Organic Farming' },
  { value: 'agribusiness_services', label: 'Agribusiness Services' }
]

const RegularDashboardContent = [
  {
    name: 'home',
    icon: require('../resources/homeicon.png'),
    orange: require('../resources/homeactive.png'),
    link: '/home',
    text: 'Hello, welcome👋',
    subtext: 'Manage your agribusiness efficiently'
  },
  {
    name: 'catalog',
    icon: require('../resources/catalog.png'),
    orange: require('../resources/catalogactive.png'),
    link: '/catalog',
    text: 'Catalog',
    subtext: 'This section provides a complete list of all the agricultural products managed within TGAIMS. Each product entry includes key details like categories, sourcing information, and other market information.'

  },
  {
    name: 'invest',
    icon: require('../resources/invest.png'),
    orange: require('../resources/investactive.png'),
    link: '/invest',
    text: 'Invest',
    subtext: 'Invest in agribusinesses and earn profitable returns over a fixed term. This feature allows individuals to grow their wealth while supporting sustainable agri businesses. Monitor your investments and returns with a transparent and user-friendly system designed to benefit both investors and agribusinesses.'
  },

  {
    name: 'Agri news',
    icon: require('../resources/agrinews.png'),
    orange: require('../resources/agrinewsactive.png'),
    link: '/agrinews',
    text: 'Agri news',
    subtext: 'Access the latest updates and trends in agriculture. From market reports to policy changes, Agri News keeps you informed to make smarter decisions for your business.'
  },

  {
    name: 'sales',
    icon: require('../resources/sales.png'),
    orange: require('../resources/salesactive.png'),
    link: '/sales',
    text: 'Sales & Supply management',
    subtext: 'Access detailed sales information for all products within the TGAIMS-managed catalog. This feature offers insights into product performance, revenue trends, and sales metrics, empowering you to make informed decisions and optimize your business strategies.'
  },

  {
    name: 'map',
    icon: require('../resources/map.png'),
    orange: require('../resources/mapactive.png'),
    link: '/map',
    text: 'Map',
    subtext: 'Gain a comprehensive view of agricultural locations, distribution networks, and logistics. The Map feature provides an intuitive interface to access and manage all agribusiness-related information, ensuring efficient decision-making.'
  }
]

const RegularAdminDashboardContent = [
  {
    name: 'home',
    icon: require('../resources/homeicon.png'),
    orange: require('../resources/homeactive.png'),
    link: '/admin-home',
    text: 'Home'
  },
  {
    name: 'consultations',
    icon: require('../resources/whiteconsult.png'),
    orange: require('../resources/greenconsult.png'),
    link: '/admin-consultations',
    text: 'Consultations'
  },
  {
    name: 'applications',
    icon: require('../resources/catalog.png'),
    orange: require('../resources/catalogactive.png'),
    link: '/admin-business-applications',
    text: 'Applications'
  },
  {
    name: 'users',
    icon: require('../resources/person.png'),
    orange: require('../resources/personactive.png'),
    link: '/admin-users',
    text: 'Users'
  },
  {
    name: 'investments',
    icon: require('../resources/invest.png'),
    orange: require('../resources/investactive.png'),
    link: '/admin-investments',
    text: 'Investments'
  },
  {
    name: 'products',
    icon: require('../resources/product.png'),
    orange: require('../resources/greenproducticon.png'),
    link: '/admin-products',
    text: 'Product Catalog'
  },
  {
    name: 'news',
    icon: require('../resources/agrinews.png'),
    orange: require('../resources/agrinewsactive.png'),
    link: '/admin-news',
    text: 'News'
  },
  {
    name: 'tips',
    icon: require('../resources/tips.png'),
    orange: require('../resources/tipsactive.png'),
    link: '/admin-tips',
    text: 'Tips'
  },

  {
    name: 'calender',
    orange: require('../resources/calender.png'),
    icon: require('../resources/notactivecalender.png'),
    link: '/admin-requests',
    text: 'Calendar'
  }
]

const agriBusinessRoles = [
  { value: 'administrator', label: 'Administrator' },
  { value: 'stock-keeper', label: 'Stock-keeper' },
  { value: 'approver', label: 'Approver' },
  { value: 'farm_manager', label: 'Farm Manager' },
  { value: 'supervisor', label: 'Supervisor' },
  { value: 'agronomist', label: 'Agronomist' },
  { value: 'veterinarian', label: 'Veterinarian' },
  { value: 'production_manager', label: 'Production Manager' },
  { value: 'quality_control_officer', label: 'Quality Control Officer' },
  { value: 'logistics_coordinator', label: 'Logistics Coordinator' },
  { value: 'marketing_specialist', label: 'Marketing Specialist' },
  { value: 'procurement_officer', label: 'Procurement Officer' },
  { value: 'warehouse_manager', label: 'Warehouse Manager' },
  { value: 'accountant', label: 'Accountant' },
  { value: 'operations_manager', label: 'Operations Manager' },
  { value: 'irrigation_specialist', label: 'Irrigation Specialist' },
  { value: 'animal_health_technician', label: 'Animal Health Technician' },
  { value: 'tractor_operator', label: 'Tractor Operator' },
  { value: 'agricultural_engineer', label: 'Agricultural Engineer' },
  { value: 'farm_worker', label: 'Farm Worker' },
  { value: 'sales_representative', label: 'Sales Representative' },
  { value: 'environmental_officer', label: 'Environmental Officer' }
]

const staffLocationOptions = [
  { value: 'lagos', label: 'Lagos' },
  { value: 'abuja', label: 'Abuja' },
  { value: 'port_harcourt', label: 'Port Harcourt' },
  { value: 'enugu', label: 'Enugu' },
  { value: 'kano', label: 'Kano' },
  { value: 'ikeja', label: 'Ikeja' },
  { value: 'benin_city', label: 'Benin City' },
  { value: 'calabar', label: 'Calabar' },
  { value: 'warri', label: 'Warri' },
  { value: 'asaba', label: 'Asaba' }
]

const statesInNigeria = [
  { value: 'abia', label: 'Abia' },
  { value: 'adamawa', label: 'Adamawa' },
  { value: 'akwa_ibom', label: 'Akwa Ibom' },
  { value: 'anambra', label: 'Anambra' },
  { value: 'bauchi', label: 'Bauchi' },
  { value: 'bayelsa', label: 'Bayelsa' },
  { value: 'benue', label: 'Benue' },
  { value: 'borno', label: 'Borno' },
  { value: 'cross_river', label: 'Cross River' },
  { value: 'delta', label: 'Delta' },
  { value: 'ebonyi', label: 'Ebonyi' },
  { value: 'edo', label: 'Edo' },
  { value: 'ekiti', label: 'Ekiti' },
  { value: 'enugu', label: 'Enugu' },
  { value: 'gombe', label: 'Gombe' },
  { value: 'imo', label: 'Imo' },
  { value: 'jigawa', label: 'Jigawa' },
  { value: 'kaduna', label: 'Kaduna' },
  { value: 'kano', label: 'Kano' },
  { value: 'katsina', label: 'Katsina' },
  { value: 'kebbi', label: 'Kebbi' },
  { value: 'kogi', label: 'Kogi' },
  { value: 'kwara', label: 'Kwara' },
  { value: 'lagos', label: 'Lagos' },
  { value: 'nasarawa', label: 'Nasarawa' },
  { value: 'niger', label: 'Niger' },
  { value: 'ogun', label: 'Ogun' },
  { value: 'ondo', label: 'Ondo' },
  { value: 'osun', label: 'Osun' },
  { value: 'oyo', label: 'Oyo' },
  { value: 'plateau', label: 'Plateau' },
  { value: 'rivers', label: 'Rivers' },
  { value: 'sokoto', label: 'Sokoto' },
  { value: 'taraba', label: 'Taraba' },
  { value: 'yobe', label: 'Yobe' },
  { value: 'zamfara', label: 'Zamfara' },
  { value: 'fct', label: 'FCT' }
]

const businessData = {
  'Crop Farming': [
    'Grain farming',
    'Fruit farming',
    'Vegetable farming',
    'Oilseed farming',
    'Nut farming'
  ],
  'Livestock Farming': [
    'Poultry farming',
    'Dairy farming',
    'Cattle ranching',
    'Sheep farming',
    'Pig farming',
    'Goat farming'
  ],
  Aquaculture: [
    'Fish farming',
    'Shrimp farming',
    'Oyster and shellfish farming'
  ],
  'Agro-processing': [
    'Food processing (canned goods, frozen foods)',
    'Dairy product manufacturing',
    'Meat processing and packing',
    'Beverage production (juices, dairy drinks)',
    'Grain milling and cereal production',
    'Oil refining (soybean, canola, palm oil)'
  ],
  'Agricultural Inputs and Supplies': [
    'Fertilizer production and distribution',
    'Pesticides and herbicides manufacturing',
    'Seed production (hybrid seeds, genetically modified seeds)',
    'Farm equipment and machinery (tractors, harvesters)',
    'Irrigation systems and technology'
  ],
  'Agri-tech and Innovations': [
    'Precision farming technology',
    'Farm management software',
    'Drone technology for agriculture',
    'Biotechnology in farming',
    'Vertical farming',
    'Hydroponics and aeroponics',
    'Robotics in agriculture'
  ],
  'Agricultural Trade and Marketing': [
    'Export and import of agricultural goods',
    'Commodity trading (grains, livestock)',
    'Supply chain management',
    'Agricultural wholesale and distribution'
  ],
  'Forestry and Logging': [
    'Timber production',
    'Pulp and paper manufacturing',
    'Non-timber forest products (resins, latex)'
  ],
  'Biofuels and Renewable Energy': [
    'Ethanol production from crops',
    'Biodiesel production',
    'Biomass energy'
  ],
  'Agri-financing and Insurance': [
    'Agricultural credit and loans',
    'Crop insurance',
    'Risk management services for farmers'
  ],
  'Greenhouse Farming': [
    'Vegetable greenhouse farming',
    'Flower and ornamental plant farming'
  ],
  'Agricultural Services': [
    'Pest control services',
    'Veterinary services for livestock',
    'Soil testing and analysis',
    'Custom planting, harvesting, or spraying'
  ],
  Agritourism: [
    'Farm tours',
    'U-pick farms',
    'Educational farm visits',
    'Winery and brewery tours'
  ],
  'Organic Farming': [
    'Organic fruit and vegetable farming',
    'Organic livestock farming',
    'Organic dairy and egg production'
  ],
  Horticulture: [
    'Floriculture (flower farming)',
    'Landscape plant nurseries',
    'Turf farming'
  ],
  'Beekeeping and Apiculture': ['Honey production', 'Pollination services'],
  Agroforestry: [
    'Combining forestry and farming',
    'Silvopasture (trees and livestock)',
    'Alley cropping'
  ],
  Aquaponics: ['Integrated fish and plant farming'],
  'Agri-waste Management': [
    'Organic compost production',
    'Animal waste management',
    'Biochar production'
  ],
  'Fish Feed Production': [
    'Fish feed manufacturing',
    'Feed formulation and supply'
  ]
}

export {
  productInputStatus,
  productUnitInput,
  agribusinessProductTypes,
  RegularDashboardContent,
  RegularAdminDashboardContent,
  agriBusinessRoles,
  staffLocationOptions,
  statesInNigeria,
  businessData
}
