import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TipsDataTable from './TipsDataTable/TipsDataTable'
import AddNewButton from '../../SharedComponents/AddNewButton/AddNewButton'
import useBackendService from '../../../../config/services/backend-service'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'
const AdminTips = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/admin-tips/add-tips')
  }

  const [data, setData] = useState([])

  const { mutate: fetchTips, isLoading } = useBackendService('/tips', 'get', {
    onSuccess: response => {
      setData(response.data)
    },
    onError: error => {
      console.error('Failed to fetch products:', error)
    }
  })

  useEffect(() => {
    fetchTips()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // Loading state
  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <main className=' bg-white '>
      <div className=' flex flex-row items-center px-5 lg:px-10 lg:py-[30px] justify-end '>
        <AddNewButton title='Add new tips' onClick={handleClick} />
      </div>
      <div className='px-5 lg:px-10'>
        <TipsDataTable data={data} />
      </div>
    </main>
  )
}

export default AdminTips
