import React, { useState } from 'react'
import Image from '../../../resources/wateryplant.png'
import Logo from '../../../resources/logo-black.png'
import LogoWhite from '../../../resources/logo-white.png'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../config/contexts/authProvider'
import useWindowSize from '../../EnterpriseApp/UseWindowSize/UseWindowSize'
import BackButton from '../../SharedComponents/BackButton/BackButton'

// card data plan
const cardsData = [
  {
    id: 'basicplan',
    title: 'Basic Account',
    description:
      'Our Basic account are tailored for small business owners, offering essential tools to efficiently manage smaller-scale businesses.'

  },
  {
    id: 'proplan',
    title: 'Pro Account',
    description:
      'Our Pro account are tailored for small business owners, offering essential tools to efficiently manage smaller-scale businesses.'

  },
  {
    id: 'enterpriseplan',
    title: 'Enterprise Account',
    description:
      'This account is designed for large-scale business owners, providing advanced tools for comprehensive business management.'

  }

]

const Planhomepage = () => {
  const [selectedCard, setSelectedCard] = useState(null)
  const navigate = useNavigate()
  const { setSignupField } = useAuth()
  const { width } = useWindowSize()
  // Define breakpoints for tablet view
  const isTabletView = width >= 768 && width <= 1024

  const handleCardClick = cardId => {
    setSelectedCard(cardId)
  }
  const handleSubmit = () => {
    if (selectedCard) {
      let planType
      switch (selectedCard) {
        case 'basicplan':
          planType = 'Basic'
          break
        case 'proplan':
          planType = 'Pro'
          break
        case 'enterpriseplan':
          planType = 'Enterprise'
          break
        default:
          planType = ''
      }

      setSignupField('plan', planType)
      navigate(`/signup/${selectedCard}`)
    }
  }

  return (
    <>
      {isTabletView ? (
        <div className='w-full min-h-screen  bg-waterplant bg-cover p-[5%]'>
          <div className='center w-full'>
            <img alt='' src={LogoWhite} className='w-[100px]' />
          </div>
          <div className='w-full center flex-col text-white text-center space-y-2 mt-[5%]'>
            <h1 className='text-2xl  w-full'>
              Manage your agribusiness efficiently
            </h1>
            <p className='w-[80%] leading-[16px] font-[400] text-[16px]'>
              Building the best and more efficient platform for agribusiness
              management.
            </p>
          </div>
          <div className='w-[90%] p-5 bg-white mt-[5%] mx-auto rounded-md'>
            <div className='w-full'>

              <div className='w-full mt-[5%] space-y-1 center flex-col'>
                <h1 className='text-[16px text-darkGreen font-[500]'>How are you planning to use Tgaims?</h1>
                <p className='text-[#666666] text-sm'>
                  We’ll streamline your setup experience accordingly
                </p>
              </div>

              {/* plan card container */}

              <div className='plancover'>
                {cardsData.map(card => (
                  <div
                    className={`w-full h-[120px] ${
              selectedCard === card.id ? 'activeplan' : 'unactiveplan'
            }`}
                    key={card.id}
                    onClick={() => handleCardClick(card.id)}
                  >

                    <h1 className='text-xl lg:text-2xl'>{card.title}</h1>
                    <p className='text-xs lg:text-sm '>{card.description}</p>
                  </div>
                ))}
              </div>

              <div className='w-full space-y-3 mt-[10%]'>
                <button onClick={handleSubmit} disabled={!selectedCard} className={`proceed-btn ${!selectedCard ? 'bg-[#dddddd] text-black pointer-events-none cursor-not-allowed' : 'proceed-btn'}`}>
                  Create account
                </button>
                <h1 className='login-text'>
                  Have an account?{' '}
                  <a href='/login' className='login-link'>
                    Log in
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='wrapper'>
          {/* left content */}
          <div className='left-content'>
            <div className='absolute top-[5%] left-1/2 lg:left-[10%] transform -translate-x-1/2 -translate-y-1/2 center mx-auto'>
              <img alt='' src={LogoWhite} className='w-[80px] lg:w-[100px]' />
            </div>
            <img alt='' src={Image} className='object-contain' />
            <div className='image-text-wrapper'>
              <h1 className='text-2xl lg:text-5xl w-full'>
                Manage your <br /> agribusiness efficiently
              </h1>
              <p className='w-[100%] lg:w-[70%] lg:text-lg leading-5 text-sm'>
                Building the best and more efficient platform for agribusiness
                management.
              </p>
            </div>
          </div>
          {/* right content */}
          <div className='right-content'>
            <div className='w-full flex  justify-between items-center'>
              <BackButton />
              <div className='w-[60%]'>

                <img alt='' src={Logo} className=' w-[80px] lg:w-[100px]' />
              </div>
            </div>

            <div className='w-full mt-[5%] space-y-1'>
              <h1 className='header-text'>How are you planning to use Tgaims?</h1>
              <p className='text-black text-sm'>
                We’ll streamline your setup experience accordingly
              </p>
            </div>

            {/* plan card container */}

            <div className='plancover'>
              {cardsData.map(card => (
                <div
                  className={`w-full h-[120px] ${
              selectedCard === card.id ? 'activeplan' : 'unactiveplan'
            }`}
                  key={card.id}
                  onClick={() => handleCardClick(card.id)}
                >

                  <h1 className='text-xl lg:text-[18px]'>{card.title}</h1>
                  <p className='text-xs lg:text-sm '>{card.description}</p>
                </div>
              ))}
            </div>

            <div className='w-full space-y-3 mt-[10%]'>
              <button onClick={handleSubmit} disabled={!selectedCard} className={`btnn ${!selectedCard ? 'disabled-btn text-black' : ''}`}>
                Create account
              </button>
              <h1 className='login-text'>
                Have an account?{' '}
                <a href='/login' className='login-link'>
                  Log in
                </a>
              </h1>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Planhomepage
