import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import NoQuestionComponent from './NoQuestionComponent/NoQuestionComponent'
import YesQuestionComponent from './YesQuestionComponent/YesQuestionComponent'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import GenerateOutput from './GenerateOutput/GenerateOutput'
import useBackendService from '../../../../../config/services/backend-service'
import { toast } from 'react-toastify'
import GlobalLoader from '../../../SharedComponents/GlobalLoader/GlobalLoader'

const AdminRequestDescription = () => {
  const { id } = useParams()
  const [showNewComponent, setShowNewComponent] = useState(false)

  const [data, setData] = useState({})
  const { mutate: fetchCalendar, isLoading } = useBackendService(
    `/calendar/${id}`,
    'get',
    {
      onSuccess: response => {
        setData(response.data.farmActivity)
      },
      onError: error => {
        console.error('Failed to fetch Calendar data:', error)
        toast.error('Failed to fetch Calendar data')
      }
    }
  )

  useEffect(() => {
    fetchCalendar()
  }, [fetchCalendar])

  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <div className='w-full p-[3%] lg:p-[2%] bg-white'>
      <div className='w-full mb-5'>
        <BackButton />
      </div>

      {!showNewComponent &&
        (data.value_chain === true
          ? (
            <NoQuestionComponent
              request={data}
              onFormSubmit={() => setShowNewComponent(true)}
            />
            )
          : (
            <YesQuestionComponent
              request={data}
              onFormSubmit={() => setShowNewComponent(true)}
            />
            ))}

      {showNewComponent && <GenerateOutput id={id} />}
    </div>
  )
}

export default AdminRequestDescription
