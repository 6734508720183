import { toast } from 'react-toastify'
import useBackendService from '../../../../../config/services/backend-service'
import CreatePassword from '../../../SharedComponents/CreatePaasword/CreatePassword'

import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

const EnterpriseAdminCreatePassword = ({ setFormData, formData }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
    trigger
  } = useForm()

  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')
  const navigate = useNavigate()
  const { mutate: createAdminUser } = useBackendService(
    '/auth/create_admin',
    'post',
    {
      onSuccess: data => {
        toast.success(
          'Admin user succesfully created. \n A verification link has been sent to you to confirm your email'
        )
        navigate('/')
      },
      onError: error => {
        toast.error('Failed to create Admin')
      }
    }
  )

  const password = watch('password')
  const confirmPassword = watch('confirmpassword')

  const handlePasswordChange = async e => {
    await trigger('password')
  }

  // function to submitting the form data
  const onSubmit = async data => {
    const isValid = await trigger(['password', 'confirmpassword', 'email'])
    if (!isValid) return
    setFormData(prevData => ({ ...prevData, ...data }))

    createAdminUser({
      ...formData,
      password: data.password,
      isAdmin: true,
      plan: 'Enterprise',
      token,
      role: 'administrator'
    })

    // next()
  }

  return (
    <>
      <CreatePassword
        password={password}
        confirmPassword={confirmPassword}
        watch={watch}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}
        errors={errors}
        isDirty={isDirty}
        isValid={isValid}
        register={register}
        onSubmit={onSubmit}
        title='Create Password'
        desc='Kindly fill in your details to setup your account'
      />
    </>
  )
}

export default EnterpriseAdminCreatePassword
